import { IPaymentManagement } from '../locale';

const paymentManagement: IPaymentManagement = {
  packageConfig: {
    basicTitle: 'BASIC',
    proTitle: 'PRO',
    advancedTitle: 'ADVANCED',
    japan: 'Japan',
    monthPrice: 'Monthly price ',
    yearPrice: 'Yearly price ',
    otherNation: 'US/UK/Others',
    usd: '(USD)',
    yen: '(JPY)',
    toastr: {
      updateSuccessful: 'Update package successfully',
    },
  },
  campaignConfig: {
    title: 'Free campaign setting',
    period: 'Campaign period',
    history: 'View History',
    titleHistory: 'Campaign history',
    dateStart: 'Start date',
    dateEnd: 'End date',
    no: 'No',
  },
  functionConfig: {
    listFunction: 'List Funcitons',
    action: 'Actions',
    anonymous: 'Anonymous',
    userFree: 'User Free',
    paidUser: 'Paid User',
    limitPost: 'Limit on the number of postings per day by each user',
    uploadVideo: 'Upload videos when posting',
    uploadMediaOption: 'Upload media in options of other user`s post',
    addOption: 'Add options in post of other users.',
    comment: 'Comment on post of other users',
    uploadMediaComment: 'Upload image in comment of other user`s posts.',
    uploadCSV: 'Upload CSV file of list email address when posting.',
    limitEmail: 'Limit the number of email address in the CSV file upload',
    toastr: {
      updateSuccessful: 'Update function settings successfully',
    },
  },
};

export default paymentManagement;
