import { CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons';
import { Button, Input, Select, SelectProps, Tag } from 'antd';
import Table, { ColumnsType } from 'antd/es/table';
import * as _ from 'lodash';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';

import useDocumentTitle from '@/hooks/useDocumentTitle';

import TablePagination from '@/components/Pagination';
import i18n from '@/services/i18n/i18n.config';
import { useAppDispatch } from '@/stores';
import { setLoading } from '@/stores/features/auth.slice';

import {
  DEFAULT_LIMIT,
  DEFAULT_SIZE,
  E_LANGUAGE,
  LOCATION_TYPE,
  MAX_TITLE_LENGTH,
} from '@/helpers/constants/common.constant';
import { POLL_STATUS, POLL_TYPE } from '@/helpers/constants/voting.constant';
import { getDateFromDateString } from '@/helpers/functions/datetime.helper';
import {
  translate,
  translateChartLegend,
} from '@/helpers/functions/language.helper';
import { sorterTable } from '@/helpers/functions/table.helper';
import { locale_keys } from '@/helpers/locales/locale.key';
import {
  PaginationDefault,
  PaginationInterface,
} from '@/helpers/types/pagination.types';
import apiService from '@/services/api/api.services';
import endpointConfig from '@/services/api/endpoint.config';

import PollDetailDrawer from './detail.drawer';

const PostList = () => {
  useDocumentTitle(translate(locale_keys.menu.pollList));
  const dispatch = useAppDispatch();
  const location = useLocation();

  const [pollData, setPollData] = useState([]);
  const [categoryData, setCategoryData] = useState([
    {
      key: 'ALL',
      value: '',
      label: i18n.language === E_LANGUAGE._EN ? 'ALL' : 'すべて',
      titleEn: 'ALL',
      titleJp: 'すべて',
    },
  ]);
  const [openDetailDrawer, setOpenDetailDrawer] = useState(false);
  const [selectedId, setselectedId] = useState('');
  const [resetDrawer, setResetDrawer] = useState<boolean>(false);

  const optionsStatusDefault: SelectProps['options'] = POLL_STATUS;

  const [optionsStatus, setOptionsStatus] = useState(optionsStatusDefault);

  const optionsTypeDefault: SelectProps['options'] = POLL_TYPE;
  const [optionsType, setOptionsType] = useState(optionsTypeDefault);

  const optionsLocationDefault: SelectProps['options'] = LOCATION_TYPE;
  const [optionsLocation, setOptionsLocation] = useState(
    optionsLocationDefault
  );

  const defaultValues = {
    keyword: '',
    status: '',
    type: '',
    title: '',
    categoryId: '',
    location: '',
  };

  const { handleSubmit, control, reset } = useForm({
    defaultValues,
  });

  const [searchValues, setSearchValues] = useState({
    keyword: '',
    status: '',
    type: '',
    title: '',
    categoryId: '',
    location: '',
    limit: DEFAULT_LIMIT,
    page: DEFAULT_SIZE,
  });
  const [pagination, setPagination] =
    useState<PaginationInterface>(PaginationDefault);
  const [categoryPagination, setCategoryPagination] =
    useState<PaginationInterface>(PaginationDefault);
  const columns: ColumnsType<any> = [
    {
      title: translate(locale_keys.voteManagement.pollList.title),
      dataIndex: 'title',
      key: 'title',
      sorter: (a, b) => sorterTable(a.title, b.title),
      width: '20%',
      render: (text: string) => {
        if (text.length > MAX_TITLE_LENGTH) {
          return `${text.substring(0, MAX_TITLE_LENGTH)}...`;
        }
        return text;
      },
    },
    {
      title: translate(locale_keys.voteManagement.pollList.author),
      dataIndex: 'owner',
      key: 'owner',
      sorter: (a, b) => sorterTable(a?.owner?.username, b?.owner?.username),
      render: (_, record) => <div>{record?.owner?.username}</div>,
    },
    {
      title: translate(locale_keys.voteManagement.pollList.type),
      dataIndex: 'isPublic',
      key: 'isPublic',
      sorter: (a, b) => sorterTable(a.isPublic, b.isPublic),
      render: (isPublic: boolean) => (
        <div>
          {translate(
            isPublic
              ? locale_keys.voteManagement.pollList.voteType.PUBLIC
              : locale_keys.voteManagement.pollList.voteType.PRIVATE
          )}
        </div>
      ),
    },
    {
      title: translate(locale_keys.voteManagement.pollList.process),
      dataIndex: 'processStatus',
      key: 'processStatus',
      align: 'center',
      sorter: (a, b) => sorterTable(a.processStatus, b.processStatus),
      render: (processStatus: string) => (
        <Tag
          className={
            'fw-600 ' +
            (processStatus ===
            translate(
              locale_keys.voteManagement.pollList.processStatus.FAILED,
              {
                lng: E_LANGUAGE._EN,
              }
            )
              ? 'text-danger'
              : processStatus ===
                translate(
                  locale_keys.voteManagement.pollList.processStatus.COMPLETED,
                  {
                    lng: E_LANGUAGE._EN,
                  }
                )
              ? 'text-active'
              : processStatus ===
                translate(
                  locale_keys.voteManagement.pollList.processStatus.PROCESSING,
                  {
                    lng: E_LANGUAGE._EN,
                  }
                )
              ? 'text-info'
              : 'text-warning')
          }
        >
          {translateChartLegend(
            processStatus,
            locale_keys.voteManagement.pollList.processStatus
          )}
        </Tag>
      ),
    },
    {
      title: translate(locale_keys.voteManagement.pollList.status),
      dataIndex: 'isBlock',
      key: 'isBlock',
      align: 'center',
      render: (isBlock: boolean) => {
        return (
          <Tag className={'fw-600 ' + (isBlock ? 'text-block' : 'text-active')}>
            {isBlock
              ? translate(locale_keys.status.BLOCKED)
              : translate(locale_keys.status.ACTIVE)}
          </Tag>
        );
      },
      sorter: (a, b) => sorterTable(a.status, b.status),
    },
    {
      title: translate(locale_keys.voteManagement.pollList.category),
      dataIndex: 'category',
      key: 'category',
      sorter: (a, b) => sorterTable(a?.category?.title, b?.category?.title),
      render: (_, record) => <div>{record?.category?.title}</div>,
    },
    // {
    //   title: translate(locale_keys.voteManagement.pollList.status),
    //   dataIndex: 'status',
    //   key: 'status',
    //   sortDirections: ['ascend', 'descend', 'ascend'],
    //   align: 'center',
    //
    //   sorter: (a, b) => sorterTable(a.status, b.status),
    //   render: (status) => {
    //     return (
    //       <Tag
    //         className={
    //           'fw-600 ' +
    //           (status === 'ACTIVE'
    //             ? 'text-active'
    //             : status === 'PENDING'
    //             ? 'text-warning'
    //             : status === 'REJECTED'
    //             ? 'text-reject'
    //             : status === 'BLOCKED'
    //             ? 'text-block'
    //             : null)
    //         }
    //       >
    //         {translateChartLegend(status, locale_keys.status)}
    //       </Tag>
    //     );
    //   },
    // },
    // {
    //   title: translate(locale_keys.voteManagement.pollList.recommend),
    //   dataIndex: 'recommendByAdmin',
    //   key: 'recommendByAdmin',
    //   align: 'center',
    //   render: (_, record) =>
    //     record.recommendByAdmin ? (
    //       <CheckCircleTwoTone twoToneColor='#52c41a' />
    //     ) : (
    //       <CloseCircleTwoTone twoToneColor='#EF9B0F' />
    //     ),
    // },
    {
      title: translate(locale_keys.user.createAt),
      dataIndex: 'created_at',
      key: 'created_at',
      render: (date: string) => getDateFromDateString(date, 'dd-mm-yyyy', true),
      sorter: (a, b) => sorterTable(a?.updated_at, b?.updated_at),
    },
    {
      title: translate(locale_keys.user.action),
      key: 'action',
      width: 130,
      render: (_, record) => (
        <div
          className='table__action--default'
          onClick={() => onOpenDetailDrawer(record._id)}
        >
          <span className='mr-8'>
            {translate(locale_keys.voteManagement.pollList.viewDetail)}
          </span>
          <span>
            <i className='bi bi-box-arrow-up-right'></i>
          </span>
        </div>
      ),
    },
  ];

  const onOpenDetailDrawer = (_id: string) => {
    setResetDrawer(!resetDrawer);
    setselectedId(_id);
    setOpenDetailDrawer(true);
  };

  const getListPoll = async () => {
    dispatch(setLoading(true));

    const params = _.pickBy(searchValues, _.identity);

    try {
      const { data } = await apiService.get(endpointConfig.posts, {
        params,
      });

      setPollData(data?.data);
      setPagination(data.paginate);
    } finally {
      setTimeout(() => {
        dispatch(setLoading(false));
      }, 250);
    }
  };

  const getListCategory = async (loadMore?: boolean) => {
    try {
      const { data } = await apiService.get(endpointConfig.categories, {
        params: {
          limit: 20,
          page: categoryPagination.currentPage + (loadMore ? 1 : 0),
        },
      });
      const newData = data?.data?.map((item: any) => ({
        key: item._id,
        value: item._id,
        label: i18n.language === E_LANGUAGE._EN ? item.titleEn : item.titleJp,
        titleEn: item.titleEn,
        titleJp: item.titleJp,
      }));
      const newCategoryData = _.uniqBy(
        [...categoryData, ...newData],
        'key'
      ) as any;
      setCategoryData(newCategoryData);
      setCategoryPagination(data.paginate);
    } catch (error) {
      console.log(error);
    }
  };

  const handlePaginationChange = (page: number, pageSize: number) => {
    if (pageSize !== pagination.pageSize) {
      setPagination({
        ...PaginationDefault,
        pageSize: pageSize,
      });
      page = 1;
    } else {
      setPagination((prev) => ({
        ...prev,
        currentPage: page,
      }));
    }

    setSearchValues((prev) => ({
      ...prev,
      limit: pageSize,
      page,
    }));
  };

  const onSubmit = async (data: any) => {
    setSearchValues((prev) => ({
      ...prev,
      ...data,
      limit: 10,
      page: 1,
    }));
  };

  const onClearFilter = () => {
    reset(defaultValues);
    setSearchValues((prev) => ({
      ...prev,
      ...defaultValues,
      limit: DEFAULT_LIMIT,
      page: DEFAULT_SIZE,
    }));
  };

  const handleOnCloseDetailDrawer = () => {
    setOpenDetailDrawer(false);
  };

  const handleOnRefresh = () => {
    getListPoll();
  };

  useEffect(() => {
    getListPoll();
    setOptionsStatus((preOptions: any) =>
      preOptions.map((option: any) => ({
        ...option,
        label: translateChartLegend(option.key, locale_keys.status),
      }))
    );
    setOptionsType((preOptions: any) =>
      preOptions.map((option: any) => ({
        ...option,
        label: translateChartLegend(
          option.key,
          locale_keys.voteManagement.pollList.voteType
        ),
      }))
    );
    setCategoryData((preOptions: any) =>
      preOptions.map((option: any) => ({
        ...option,
        label:
          i18n.language === E_LANGUAGE._EN ? option.titleEn : option.titleJp,
      }))
    );
    setOptionsLocation((preOptions: any) =>
      preOptions.map((option: any) => ({
        ...option,
        label: translateChartLegend(option.key, locale_keys.location),
      }))
    );
  }, [searchValues, location]);

  useEffect(() => {
    getListCategory();
  }, []);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='filter'>
          <div className='filter__title'>
            {translate(locale_keys.filter.filterBy)}
          </div>
          <div className='filter__form d-flex'>
            <div className='mr-20'>
              <label htmlFor='title' className='form-label filter__form-label'>
                {translate(locale_keys.voteManagement.pollList.title)}
              </label>
              <Controller
                control={control}
                name='title'
                defaultValue=''
                render={({ field }) => (
                  <Input
                    size='large'
                    value={field.value}
                    placeholder={translate(
                      locale_keys.voteManagement.pollList.title
                    )}
                    onChange={(e: any) => field.onChange(e.target.value)}
                  />
                )}
              />
            </div>
            <div className='mr-20'>
              <label
                htmlFor='keyword'
                className='form-label filter__form-label'
              >
                {translate(locale_keys.voteManagement.pollList.author)}
              </label>
              <Controller
                control={control}
                name='keyword'
                defaultValue=''
                render={({ field }) => (
                  <Input
                    size='large'
                    value={field.value}
                    placeholder={translate(locale_keys.filter.userPlaceholder)}
                    onChange={(e: any) => field.onChange(e.target.value)}
                  />
                )}
              />
            </div>
            {/*<div className='mr-20'>*/}
            {/*  <label htmlFor='status' className='form-label filter__form-label'>*/}
            {/*    {translate(locale_keys.filter.status)}*/}
            {/*  </label>*/}
            {/*  <div>*/}
            {/*    <Controller*/}
            {/*      name='status'*/}
            {/*      control={control}*/}
            {/*      defaultValue={POLL_STATUS[0].value}*/}
            {/*      render={({ field }) => (*/}
            {/*        <Select*/}
            {/*          size='large'*/}
            {/*          defaultValue={field.value}*/}
            {/*          value={field.value}*/}
            {/*          onChange={(value) => field.onChange(value)}*/}
            {/*          style={{ width: 200 }}*/}
            {/*          options={optionsStatus}*/}
            {/*        />*/}
            {/*      )}*/}
            {/*    />*/}
            {/*  </div>*/}
            {/*</div>*/}

            <div className='mr-20'>
              <label htmlFor='type' className='form-label filter__form-label'>
                {translate(locale_keys.voteManagement.pollList.type)}
              </label>
              <div>
                <Controller
                  name='type'
                  control={control}
                  defaultValue={POLL_TYPE[0].value}
                  render={({ field }) => (
                    <Select
                      size='large'
                      defaultValue={field.value}
                      value={field.value}
                      onChange={(value) => field.onChange(value)}
                      style={{ width: 200 }}
                      options={optionsType}
                    />
                  )}
                />
              </div>
            </div>
            <div className='mr-20'>
              <label htmlFor='type' className='form-label filter__form-label'>
                {translate(locale_keys.voteManagement.category.name)}
              </label>
              <div>
                <Controller
                  name='categoryId'
                  control={control}
                  defaultValue={''}
                  render={({ field }) => (
                    <Select
                      allowClear={false}
                      onPopupScroll={(e: any) => {
                        const { target } = e;

                        if (
                          Math.ceil(target.scrollTop + target.clientHeight) >=
                          target.scrollHeight
                        ) {
                          if (
                            categoryPagination.currentPage <
                            categoryPagination.totalPage
                          ) {
                            getListCategory(true);
                          }
                        }
                      }}
                      size='large'
                      defaultValue={field.value}
                      value={field.value}
                      onChange={(value) => field.onChange(value)}
                      style={{ width: 200 }}
                      options={categoryData}
                    />
                  )}
                />
              </div>
            </div>

            {/*<div className='mr-20'>*/}
            {/*  <label htmlFor='type' className='form-label filter__form-label'>*/}
            {/*    {translate(locale_keys.advertisement.location)}*/}
            {/*  </label>*/}
            {/*  <div>*/}
            {/*    <Controller*/}
            {/*      name='location'*/}
            {/*      control={control}*/}
            {/*      defaultValue={LOCATION_TYPE[0].value}*/}
            {/*      render={({ field }) => (*/}
            {/*        <Select*/}
            {/*          size='large'*/}
            {/*          defaultValue={field.value}*/}
            {/*          value={field.value}*/}
            {/*          onChange={(value) => field.onChange(value)}*/}
            {/*          style={{ width: 200 }}*/}
            {/*          options={optionsLocation}*/}
            {/*        />*/}
            {/*      )}*/}
            {/*    />*/}
            {/*  </div>*/}
            {/*</div>*/}

            <div className='ms-auto d-flex align-items-end'>
              <Button className='form-btn' onClick={onClearFilter}>
                {translate(locale_keys.filter.clear)}
              </Button>
              <Button
                className='form-btn form-btn--black ml-20'
                htmlType='submit'
              >
                {translate(locale_keys.filter.apply)}
              </Button>
            </div>
          </div>
        </div>
      </form>

      <div className='table mt-24'>
        <div className='table__heading'>
          <div className='table__heading-title'>
            {translate(locale_keys.voteManagement.pollList.listUser)}
          </div>
        </div>
        <div className='table__grid'>
          <Table
            rowKey='_id'
            scroll={{ y: 400 }}
            // loading={true}
            columns={columns}
            dataSource={pollData}
            pagination={false}
          />
          <TablePagination
            currentPage={pagination.currentPage}
            total={pagination.total}
            pageSize={pagination.pageSize}
            onPageChange={handlePaginationChange}
          ></TablePagination>
        </div>
      </div>

      <PollDetailDrawer
        resetDrawer={resetDrawer}
        selectedId={selectedId}
        open={openDetailDrawer}
        onCloseDetailDrawer={handleOnCloseDetailDrawer}
        onRefresh={handleOnRefresh}
      ></PollDetailDrawer>
    </>
  );
};

export default PostList;
