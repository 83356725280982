import { IMenu } from '../locale';

const menu: IMenu = {
  dashboard: 'Dashboard',
  userAccount: 'User management',
  adminAccount: 'Admin management',
  payment: 'Payment Management',
  package: 'Price Setting',
  function: 'Payment List',
  voting: 'Voting management',
  posts: 'Post management',
  pollList: 'Post list',
  pollConfig: 'Poll & Point Management',
  banWords: 'Forbidden word',
  pollCategory: 'Manage category',
  pollImage: 'Default image',
  advertisement: 'Advertisement',
  advertisementList: 'Advertisement List',
  advertisementRequest: 'Advertisement Requests',
  sales: 'Sales management',
  site: 'Site information',
  announcements: 'Announcements',
  inquiry: 'Inquiry management',
  points: 'Points & Gifts',
  pointConfigure: 'Gift configuration',
  pointExchange: 'Exchange management',
  report: 'Report management',
  feedback: 'Feedback Management',
  dashboardHeading: 'Dashboard Management',
  userAccountHeading: 'User Accounts Management',
  adminAccountHeading: 'Admin Accounts Management',
  postListHeading: 'Post Management',
  pollListHeading: 'Poll List Management',
  pollConfigHeading: 'Poll Configuration Management',
  pollCategoryHeading: 'Category Management',
  list: 'List',
  request: 'Request',
};

export default menu;
