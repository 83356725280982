import { Button, Image } from 'antd';
import classNames from 'classnames';
import React from 'react';
import { IoMdRadioButtonOff } from 'react-icons/io';
import '@/styles/components/voting/live-view/ranking.scss';

import VideoPlayer from '@/components/VideoPlayer';

import { E_TYPE_MEDIA } from '@/helpers/constants/voting.constant';
import { generateImgCanvasURLFromText } from '@/helpers/functions/canvas.helper';
import { translate } from '@/helpers/functions/language.helper';
import { locale_keys } from '@/helpers/locales/locale.key';

const Ranking = ({ attachmentType, attachmentUrl, options }: any) => {
  options = React.useMemo(() => {
    return options.sort((a: any, b: any) => b.votes - a.votes);
  }, [options]);

  const totalVotes = React.useMemo(() => {
    return options.reduce((acc: any, cur: any) => acc + cur.votes, 0) || 0;
  }, [options]);

  return (
    <div className='d-flex flex-column ranking__options'>
      <div className='d-flex flex-row align-items-center justify-content-center ranking__options-media'>
        {attachmentType === E_TYPE_MEDIA._IMAGE ? (
          <Image
            src={process.env.REACT_APP_PUBLIC_S3_URL + attachmentUrl}
            height={'100%'}
            width={'100%'}
            alt=''
          />
        ) : (
          <VideoPlayer
            url={process.env.REACT_APP_PUBLIC_S3_URL + attachmentUrl}
            view={true}
            height={'100%'}
            width={'100%'}
          />
        )}
      </div>

      <div className='d-flex flex-column ranking__options-content'>
        {options.map((option: any, index: number) => (
          <div
            className={classNames([
              'd-flex flex-row ranking__options-content-item',
              `color-${index + 1}`,
            ])}
            key={index}
          >
            {options.filter((op: any) => op.attachmentUrl).length > 0 && (
              <div className='ranking__options-content-item-image'>
                {option.attachmentType === E_TYPE_MEDIA._IMAGE ||
                !option.attachmentType ? (
                  <Image
                    src={
                      option.attachmentUrl
                        ? process.env.REACT_APP_PUBLIC_S3_URL +
                          option.attachmentUrl
                        : generateImgCanvasURLFromText(
                            option.title,
                            210,
                            128,
                            '#212121'
                          )
                    }
                    height={128}
                    width={210}
                    alt=''
                  />
                ) : (
                  <VideoPlayer
                    url={
                      process.env.REACT_APP_PUBLIC_S3_URL + option.attachmentUrl
                    }
                    view={true}
                    height={128}
                    width={210}
                  />
                )}
              </div>
            )}
            <div className='ranking__options-content-item-option d-flex flex-column w-full'>
              <div className='ranking__options-content-item-option-title'>
                <span>{option.title}</span>
              </div>
              <div className='ranking__options-content-item-option-calc d-flex flex-row align-items-center justify-content-between text-center'>
                <span className='number'>{index + 1}</span>
                <span className='count'>
                  {option?.votes}{' '}
                  {translate(
                    locale_keys.voteManagement.pollList.detail.peopleChoice
                  )}{' '}
                  ({Math.round((option?.votes / totalVotes) * 100)}%)
                </span>
                <Button className='d-flex flex-row align-items-center justify-content-center gap-2 vote'>
                  {<IoMdRadioButtonOff size={24} />}

                  <span>
                    {translate(locale_keys.voteManagement.pollList.detail.vote)}
                  </span>
                </Button>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* {allowUserAddOptions && (
        <div className='d-flex flex-row w-full gap-2 align-items-center justify-content-between'>
          <strong
            style={{
              padding: '12px',
              background: '#f4f4f2',
              borderRadius: '8px',
              height: '48px',
              width: '100%',
            }}
          >
            Write your option
          </strong>
          <span
            style={{
              padding: '12px',
              background: '#f4f4f2',
              borderRadius: '8px',
              height: '48px',
              width: '48px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              color: 'rgba(0, 0, 0, 0.6)',
            }}
          >
            <BiChevronDown size={48} />
          </span>
          <Button
            disabled
            className='text-uppercase'
            style={{
              height: '48px',
              whiteSpace: 'nowrap',
              padding: '12px',
              width: '108px',
              background: 'rgba(0, 0, 0, 0.4)',
              border: 'none',
              color: '#fff',
            }}
          >
            <BiPlus /> Add
          </Button>
        </div>
      )} */}
    </div>
  );
};

export default Ranking;
