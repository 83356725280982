import { IVoteManagement } from '../locale';
const voteManagement: IVoteManagement = {
  pollList: {
    author: 'Author',
    category: 'Category',
    listUser: 'List of Posts',
    recommend: 'Recommend by Admin',
    status: 'Status',
    title: 'Title',
    type: 'Type',
    viewDetail: 'View detail',
    listEmail: 'List Emails',
    voteType: {
      ALL: 'ALL',
      // RECOMMEND_BY_ADMIN: 'Recommended by admin',
      // TOP_VOTE: 'Top vote',
      // TOP_LIKE: 'Top like',
      // TOP_COMMENT: 'Top comment',
      // PRIVATE: 'Private',
      // CLOSED: 'Closed',
      PUBLIC: 'Public',
      PRIVATE: 'Private',
    },
    process: 'Process',
    processStatus: {
      PENDING: 'PENDING',
      PROCESSING: 'PROCESSING',
      COMPLETED: 'COMPLETED',
      FAILED: 'FAILED',
    },
    detail: {
      title: 'Post Detail',
      recommend: 'Recommend this post',
      blockPoll: 'Block Post',
      activePoll: 'Active Post',
      published: 'Published',
      like: 'Like',
      comment: 'Comment',
      removeRecommend: 'Remove recommend this post',
      rejectPoll: 'Reject this post',
      owner: 'Owner - {{count}} followers',
      question: 'Question',
      vote: 'VOTE',
      peopleChoice: 'People choice ',
      confirm: {
        block: 'Block Post',
        contentBlock: 'Are you sure you want to block this post?',
        reject: 'Do Reject post?',
        contentReject: 'Are you sure you want to reject this post?',
        active: 'Active Post',
        contentActive: 'Are you sure you want to active this post?',
        recommend: 'Recommend Post',
        contentRecommend: 'Do you want to recommend this post?',
        removeRecommend: 'Delete Recommendation',
        removeContentRecommend:
          'Do you want to remove this post from recommendation?',
      },
      toastr: {
        activeSuccessful: 'Active post successfully',
        recommentSuccessful: 'Recommend post successfully',
        removeRecommentSuccessful: 'Remove Recommend post successfully',
        blockSuccessful: 'Block post successfully',
      },
      sampleVideo: 'Sample Video',
      practiceVideo: 'Practice Video',
      aiScore: "AI's Score",
      view: 'View',
    },
  },
  pollConfiguration: {
    freeUser: 'Free User',
    paidUser: 'Paid User',
    mediaUploadSize: 'Media upload size',
    mediaUploadSizeDes: 'Set maximum size of media upload in MB',
    pointCreate: 'Point for post creating',
    pointCreateDes:
      'Set the number of points received when creating a post for each type of user',
    pointReceive: 'Point for receiving voting',
    pointReceiveDes:
      'Set the number of points when receiving voting for each type of user',
    pointVoteOther: 'Point for voting others post',
    pointVoteOtherDes:
      'Set the number of points when voting polls of other users',
    save: 'Save',
    maxSize: 'Maximum Size (MB)',
    refresh: 'Refresh',
    confirm: {
      update: 'Update post settings',
      contentUpdate: 'Are you sure you want to update post settings?',
    },
    toastr: {
      updateSuccessful: 'Update post settings successfully',
    },
  },
  banWord: {
    title: 'New ban word',
    createPlaceholder: 'Create new ban-word',
    banWordList: 'Ban-words List',
    createBanWordSuccessful: 'Create new ban word successfully',
    removeBanWordSuccessful: 'Remove ban-word successfully',
  },
  category: {
    name: 'Category',
    addNewSub: 'Add new sub-category',
    categoryList: 'Category List',
    englishName: 'English name',
    englishNameDes: 'Create new english title',
    japanName: 'Japan name',
    japanNameDes: 'Create new japan title',
    title: 'New category',
    englishSub: 'English sub-category',
    japanSub: 'Japan sub-category',
    japanTitleRequired: 'This field is required',
    toastr: {
      createSubSuccessful: 'Create new sub-category successfully',
      createSuccessful: 'Create new category successfully',
      deleteSuccessful: 'Delete category successfully',
      updateSuccessful: 'Update category successfully',
    },
    confirm: {
      create: 'Create new category?',
      contentCreate: 'Are you sure you want to create new category?',
      contentDelete: 'Are you sure you want to delete category?',
      delete: 'Delete category?',
      contentCreateSub: 'Are you sure you want to create new sub-category?',
      createSub: 'Create new sub-category?',
      update: 'Update category?',
      contentUpdate: 'Are you sure you want to update this category?',
    },
    englishCategory: 'English category',
    englishSubCategory: 'English sub-category',
    japanCategory: 'Japan category',
    japanSubCategory: 'Japan sub-category',
  },
  imageDefault: {
    title: 'New image',
    listImage: 'List image',
    selectFile: 'Select File',
    uploading: 'Uploading',
    startUpload: 'Start upload',
    toastr: {
      createSuccessful: 'Create new default image successfully',
      deleteSuccessful: 'Delete default image successfully',
      uploadSizeWarning: 'Maximum size allowed is',
      uploadTypeWarning:
        'Invalid file type. Only PNG, JPG, and JPEG files are allowed.',
    },
    confirm: {
      contentDelete: 'Are you sure you want to delete this default image?',
      delete: 'Delete default image?',
    },
  },
};

export default voteManagement;
