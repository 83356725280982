import { IFeedbackManagement } from '../locale';

const feedbackManagement: IFeedbackManagement = {
  inquiry: {
    viewDetail: 'Detail',
    listInquiry: 'List Inquiries',
    keywordFilterPlaceholder: 'Search by title and email',
    detail: {
      title: 'Detail Inquiry',
      toastr: {
        deleteSuccessful: 'Delete inquiry successfully',
      },
      confirm: {
        delete: 'Delete Inquiry',
        contentDelete: 'Are you sure you want to delete this inquiry?',
      },
    },
  },
};

export default feedbackManagement;
