import { IStatus } from '../locale';

const status: IStatus = {
  ALL: 'ALL',
  ACTIVE: 'ACTIVE',
  PENDING: 'PENDING',
  BLOCKED: 'BLOCKED',
  REJECTED: 'REJECTED',
  BLOCK: 'BLOCK',
  INACTIVE: 'INACTIVE',
  CANCELED: 'CANCELED',
  INCOMPLETE: 'INCOMPLETE',
  IN_PROGRESS: 'IN_PROGRESS',
  COMPLETED: 'COMPLETED',
  CANCEL: 'CANCEL',
  PAID: 'COMPLETED',
  UNPAID: 'UNPAID',
  PAST_DUE: 'PAST_DUE',
  INCOMPLETE_EXPIRED: 'INCOMPLETE_EXPIRED',
};

export default status;
