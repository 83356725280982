import { StopOutlined } from '@ant-design/icons';
import { ErrorMessage } from '@hookform/error-message';
import { Button, DatePicker, Drawer, Image, Input, Space, Tooltip } from 'antd';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FaCloudDownloadAlt } from 'react-icons/fa';
import { toast } from 'react-toastify';
import '@/styles/pages/ads.scss';

import { confirmModal } from '@/components/ShowConfirm';

import { useAppDispatch } from '@/stores';
import { setLoading } from '@/stores/features/auth.slice';

import { LOCATION_TYPE_WITHOUT_ALL } from '@/helpers/constants/common.constant';
import { validateEndDate } from '@/helpers/functions/datetime.helper';
import {
  validateNonNegativeNumber,
  validateRequired,
  validURL,
} from '@/helpers/functions/form-validation.helper';
import {
  translate,
  translateChartLegend,
} from '@/helpers/functions/language.helper';
import { locale_keys } from '@/helpers/locales/locale.key';
import apiService from '@/services/api/api.services';
import endpointConfig from '@/services/api/endpoint.config';

interface AdsDetailDrawerProps {
  resetDrawer: boolean;
  selectedId: string;
  open: boolean;
  onCloseDetailDrawer: () => void;
  onRefresh: () => void;
}

const AdsRequestDetailDrawer = ({
  resetDrawer,
  selectedId,
  open,
  onCloseDetailDrawer,
  onRefresh,
}: AdsDetailDrawerProps) => {
  const dispatch = useAppDispatch();
  const { RangePicker } = DatePicker;

  const [verticalImage, setVerticalImage] = useState<any>(null);
  const [horizontalImage, setHorizontalImage] = useState<any>(null);
  const [video, setVideo] = useState<any>(null);

  const defaultValues = {
    url: '',
    timeStart: null,
    timeEnd: null,
    timeRange: null,
    location: LOCATION_TYPE_WITHOUT_ALL[0].value,
    description: '',
    price: '',
    verticalField: '',
    horizontalField: '',
    videoField: '',
    timeDisplay: '',
    email: '',
  };

  const {
    handleSubmit,
    register,
    control,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues,
  });

  const getDetailAds = async (selectedId: string) => {
    dispatch(setLoading(true));

    try {
      const { data } = await apiService.get(
        `${endpointConfig.advertisementRequest}/${selectedId}`
      );
      setVerticalImage(data?.data?.verticalUrl);
      setHorizontalImage(data?.data?.horizontalUrl);
      setVideo(data?.data?.videoUrl);
      reset({
        ...data?.data,
        verticalField: data?.data?.verticalUrl,
        horizontalField: data?.data?.horizontalUrl,
        videoField: data?.data?.videoUrl,
        timeRange: [data?.data?.timeStart, data?.data?.timeEnd],
        location: translateChartLegend(
          data?.data?.location,
          locale_keys.location
        ),
        email: data?.data?.owner?.email,
      });
    } finally {
      setTimeout(() => {
        dispatch(setLoading(false));
      }, 250);
    }
  };

  const onSubmit = async (dataForm: any) => {
    try {
      const reqData: any = {
        url: dataForm.url,
        timeStart: new Date(dataForm?.timeRange[0]).toISOString(),
        timeEnd: new Date(dataForm?.timeRange[1]).toISOString(),
        location:
          dataForm.location === translate(locale_keys.location.OTHER)
            ? LOCATION_TYPE_WITHOUT_ALL[1].value
            : LOCATION_TYPE_WITHOUT_ALL[0].value,
        description: dataForm.description,
        price: Number(dataForm.price),
        videoUrl: dataForm.videoField,
        verticalUrl: dataForm.verticalField,
        horizontalUrl: dataForm.horizontalField,
        timeDisplay: Number(dataForm.timeDisplay),
      };

      if (selectedId !== '') {
        const { data } = await apiService.patch(
          endpointConfig.advertisement + '/' + selectedId,
          reqData
        );
        if (data?.success) {
          toast.success(
            translate(locale_keys.advertisement.detail.toastr.updateSuccessful)
          );
          onCloseDetailDrawer();
          reset(defaultValues);
          onRefresh();
        }
      } else {
        const { data } = await apiService.post(
          endpointConfig.advertisement,
          reqData
        );
        if (data?.success) {
          toast.success(
            translate(locale_keys.advertisement.detail.toastr.createSuccessful)
          );
          onCloseDetailDrawer();
          reset(defaultValues);
          onRefresh();
        }
      }
    } catch (error) {
      dispatch(setLoading(false));
    }
  };

  const onDeleteAds = async () => {
    try {
      await confirmModal({
        title: translate(locale_keys.advertisement.detail.confirm.delete),
        content: translate(
          locale_keys.advertisement.detail.confirm.contentDelete
        ),
        okText: translate(locale_keys.filter.yes),
        cancelText: translate(locale_keys.filter.no),
        // icon: <InfoCircleFilled />,
      });

      doDelete();
    } catch (e) {
      // User clicked Cancel or closed the modal
    }
  };

  const doDelete = async () => {
    dispatch(setLoading(true));

    try {
      const res = await apiService.delete(
        `${endpointConfig.advertisement}/${selectedId}`
      );
      if (res?.data?.success) {
        onCloseDetailDrawer();
        toast.success(
          translate(locale_keys.advertisement.detail.toastr.deleteSuccessful)
        );
        onRefresh();
      }
    } finally {
      setTimeout(() => {
        dispatch(setLoading(false));
      }, 250);
    }
  };

  useEffect(() => {
    setHorizontalImage(null);
    setVerticalImage(null);
    setVideo(null);

    if (selectedId !== '') {
      getDetailAds(selectedId);
    } else {
      reset(defaultValues);
    }
  }, [resetDrawer]);

  return (
    <>
      <Drawer
        title={translate(locale_keys.advertisement.detail.requestTitle)}
        width={720}
        placement='right'
        onClose={onCloseDetailDrawer}
        open={open}
        bodyStyle={{ paddingBottom: 80 }}
        extra={
          <Space>
            {selectedId !== '' ? (
              <Button
                type='dashed'
                danger
                icon={<StopOutlined />}
                className='d-flex align-items-center'
                onClick={onDeleteAds}
              >
                {translate(locale_keys.filter.delete)}
              </Button>
            ) : null}
          </Space>
        }
      >
        <form id='create-form' onSubmit={handleSubmit(onSubmit)}>
          <div className='d-flex justify-content-between mb-20'>
            <div style={{ width: '100%' }}>
              <label htmlFor='email' className='form-label filter__form-label'>
                {translate(locale_keys.user.email)}
              </label>
              <div>
                <Controller
                  name='email'
                  control={control}
                  defaultValue=''
                  rules={{ validate: validateRequired }}
                  render={({ field }) => (
                    <Input
                      size='large'
                      value={field.value}
                      placeholder={translate(locale_keys.user.email)}
                      className={classNames('input_disable')}
                      maxLength={256}
                      {...register('email')}
                      onChange={(e: any) => field.onChange(e.target.value)}
                      disabled={true}
                    />
                  )}
                />
              </div>
            </div>
          </div>
          <div className='d-flex justify-content-between mb-20'>
            <div style={{ width: '100%' }}>
              <label
                htmlFor='description'
                className='form-label filter__form-label'
              >
                {translate(locale_keys.advertisement.description)}
              </label>
              <div>
                <Controller
                  name='description'
                  control={control}
                  defaultValue=''
                  rules={{ validate: validateRequired }}
                  render={({ field }) => (
                    <Input
                      size='large'
                      value={field.value}
                      placeholder={translate(
                        locale_keys.advertisement.detail.descriptionPlaceholder
                      )}
                      className={classNames(['input_disable'], {
                        ['--error']: errors['description'],
                      })}
                      maxLength={256}
                      {...register('description')}
                      onChange={(e: any) => field.onChange(e.target.value)}
                      disabled
                    />
                  )}
                />
                <ErrorMessage
                  errors={errors}
                  name='description'
                  render={({ message }) => (
                    <p className='text--error'>{message}</p>
                  )}
                />
              </div>
            </div>
          </div>
          <div className='d-flex justify-content-between mb-20'>
            <div style={{ width: '68%' }}>
              <label
                htmlFor='timeEnd'
                className='form-label filter__form-label'
              >
                {translate(locale_keys.advertisement.url)}
              </label>
              <div>
                <Controller
                  name='url'
                  control={control}
                  defaultValue=''
                  rules={{
                    validate: {
                      required: validateRequired,
                      url: validURL,
                    },
                  }}
                  render={({ field }) => (
                    <Input
                      size='large'
                      value={field.value}
                      placeholder={translate(
                        locale_keys.advertisement.detail.urlPlaceholder
                      )}
                      className={classNames(['input_disable'], {
                        ['--error']: errors['url'],
                      })}
                      maxLength={256}
                      {...register('url')}
                      onChange={(e: any) => field.onChange(e.target.value)}
                      disabled
                    />
                  )}
                />
                <ErrorMessage
                  errors={errors}
                  name='url'
                  render={({ message }) => (
                    <p className='text--error'>{message}</p>
                  )}
                />
              </div>
            </div>
            <div style={{ width: '30%' }}>
              <label htmlFor='type' className='form-label filter__form-label'>
                {translate(locale_keys.advertisement.location)}
              </label>
              <div>
                <Controller
                  name='location'
                  control={control}
                  defaultValue={LOCATION_TYPE_WITHOUT_ALL[0].value}
                  render={({ field }) => (
                    <Input
                      size='large'
                      defaultValue={field.value}
                      value={field.value}
                      onChange={(value) => field.onChange(value)}
                      style={{ width: '100%' }}
                      disabled
                      className='input_disable'
                    />
                  )}
                />
              </div>
            </div>
          </div>
          <div className='d-flex justify-content-between mb-20'>
            <div style={{ width: '68%' }}>
              <label
                htmlFor='timeEnd'
                className='form-label filter__form-label'
              >
                {translate(locale_keys.advertisement.detail.startEndDate)}
              </label>
              <div>
                <Controller
                  name='timeRange'
                  control={control}
                  rules={{
                    validate: {
                      required: validateRequired,
                      validateEndDate: (value: any) =>
                        validateEndDate(value[1], value[0]),
                    },
                  }}
                  render={({ field }) => (
                    <RangePicker
                      placeholder={[
                        translate(
                          locale_keys.advertisement.detail.startPlaceholder
                        ),
                        translate(
                          locale_keys.advertisement.detail.endPlaceholder
                        ),
                      ]}
                      size='large'
                      style={{ width: '100%' }}
                      format='YYYY-MM-DD'
                      {...register('timeEnd')}
                      className={classNames(['input_disable'], {
                        ['--error']: errors['timeRange'],
                      })}
                      onChange={(dates: any, dateStrings: any) => {
                        if (dateStrings[0] === '') {
                          field.onChange(null);
                        } else {
                          field.onChange(dateStrings);
                        }
                      }}
                      value={
                        field.value
                          ? [dayjs(field.value[0]), dayjs(field.value[1])]
                          : null
                      }
                      disabled
                    />
                  )}
                />
                <ErrorMessage
                  errors={errors}
                  name='timeRange'
                  render={({ message }) => (
                    <p className='text--error'>{message}</p>
                  )}
                />
              </div>
            </div>

            <div style={{ width: '30%' }}>
              <label htmlFor='type' className='form-label filter__form-label'>
                {translate(locale_keys.advertisement.detail.timeDisplay)}
              </label>
              <div>
                <Controller
                  name='timeDisplay'
                  control={control}
                  rules={{ validate: validateNonNegativeNumber }}
                  render={({ field }) => (
                    <Input
                      size='large'
                      value={field.value}
                      placeholder='Point'
                      className={classNames(['input_disable'], {
                        ['--error']: errors['timeDisplay'],
                      })}
                      {...register('timeDisplay', {
                        required: translate(
                          locale_keys.advertisement.detail.required.input
                        ),
                      })}
                      onChange={(e: any) => field.onChange(e.target.value)}
                      disabled
                    />
                  )}
                />
                <ErrorMessage
                  errors={errors}
                  name='timeDisplay'
                  render={({ message }) => (
                    <p className='text--error'>{message}</p>
                  )}
                />
              </div>
            </div>
          </div>

          <div className='d-flex mb-20'>
            {verticalImage && (
              <div className='mr-30'>
                <label htmlFor='type' className='form-label filter__form-label'>
                  {translate(locale_keys.advertisement.detail.verticalBanner)}
                  <Tooltip
                    title={translate(locale_keys.advertisement.detail.download)}
                  >
                    <a
                      href={process.env.REACT_APP_PUBLIC_S3_URL + verticalImage}
                      download='vertical-image'
                    >
                      <FaCloudDownloadAlt className='icon_download' />
                    </a>
                  </Tooltip>
                </label>

                <div>
                  {verticalImage && (
                    <div
                      className='ads__banner ads__banner-vertical'
                      style={{ position: 'relative' }}
                    >
                      <Image
                        src={
                          process.env.REACT_APP_PUBLIC_S3_URL + verticalImage
                        }
                      />
                    </div>
                  )}
                </div>
              </div>
            )}
            {video && (
              <div>
                <label htmlFor='type' className='form-label filter__form-label'>
                  {translate(locale_keys.advertisement.detail.uploadVideo)}
                  <Tooltip
                    title={translate(locale_keys.advertisement.detail.download)}
                  >
                    <a
                      href={process.env.REACT_APP_PUBLIC_S3_URL + video}
                      download
                    >
                      <FaCloudDownloadAlt className='icon_download' />
                    </a>
                  </Tooltip>
                </label>
                <div>
                  {video && (
                    <div
                      className='ads__banner ads__banner-video'
                      style={{ position: 'relative' }}
                    >
                      <video
                        src={process.env.REACT_APP_PUBLIC_S3_URL + video}
                        controls
                        style={{
                          width: '100%',
                          height: '100%',
                          objectFit: 'cover',
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
          <div className='d-flex justify-content-between mb-20'>
            {horizontalImage && (
              <div>
                <label htmlFor='type' className='form-label filter__form-label'>
                  {translate(locale_keys.advertisement.detail.horizontalBanner)}
                  <Tooltip
                    title={translate(locale_keys.advertisement.detail.download)}
                  >
                    <a
                      href={
                        process.env.REACT_APP_PUBLIC_S3_URL + horizontalImage
                      }
                      download='horizontal-image'
                    >
                      <FaCloudDownloadAlt className='icon_download' />
                    </a>
                  </Tooltip>
                </label>
                <div>
                  <ErrorMessage
                    errors={errors}
                    name='horizontalField'
                    render={({ message }) => (
                      <p className='text--error'>{message}</p>
                    )}
                  />

                  {horizontalImage && (
                    <div
                      className='ads__banner ads__banner-horizontal'
                      style={{ position: 'relative' }}
                    >
                      <Image
                        src={
                          process.env.REACT_APP_PUBLIC_S3_URL + horizontalImage
                        }
                      />
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </form>
      </Drawer>
    </>
  );
};

export default AdsRequestDetailDrawer;
