import { useState } from 'react';
import { Collapse } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import { translate } from '@/helpers/functions/language.helper';
import {
  MenuItemChildrenInterface,
  MenuItemInterface,
} from '@/helpers/types/menu.types';

const MenuMultiItem = ({
  item,
  currentPath,
}: {
  item: MenuItemInterface;
  currentPath: string;
}) => {
  const [open, setOpen] = useState(false);

  const navigate = useNavigate();

  const goToMenu = (path: any) => {
    navigate(path);
  };

  return (
    <>
      <div
        className='menu__list-item d-flex align-items-center '
        onClick={() => setOpen(!open)}
        aria-controls='menu-collapse'
        aria-expanded={open}
      >
        <img
          src={currentPath === item.path ? item.iconBold : item.icon}
          alt='icon-adminAccount'
        />
        <span className='ml-10'>{translate(item.localeKeys)}</span>
      </div>

      <Collapse in={open}>
        <div id='menu-collapse' className='menu-collapse'>
          {item?.children &&
            item.children.map((child: MenuItemChildrenInterface) => (
              <div
                key={child.path}
                className={
                  'menu__list-item d-flex align-items-center menu-collapse__item ' +
                  (currentPath === child.path ? 'menu__list-item--active' : '')
                }
                onClick={() => goToMenu(child.path)}
              >
                {translate(child.localeKeys)}
              </div>
            ))}
        </div>
      </Collapse>
    </>
  );
};

export default MenuMultiItem;
