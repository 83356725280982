import { IReport } from '../locale';

const report: IReport = {
  listReport: 'List Reports',
  type: 'Reason',
  reporter: 'Reporter',
  reporterEmail: 'Reporter Email',
  keywordPlaceholder: 'Search by username or email',
  reportType: {
    ALL: 'All',
    SEXUAL_CONTENT: 'Sexual content',
    VIOLENT_CONTENT: 'Violent or repulsive content',
    HATEFUL_CONTENT: 'Hateful or abusive content',
    HARASSMENT_OR_BULLYING: 'Harassment or bullying',
    HARMFUL_ACTS: 'Harmful or dangerous acts',
    MISINFORMATION: 'Misinformation',
    CHILD_ABUSE: 'Child abuse',
    PROMOTES_TERRORISM: 'Promotes terrorism',
    SPAM_OR_MISLEADING: 'Spam or misleading',
  },
  detail: {
    title: 'Detail Report',
    reporterInfo: 'Reporter information',
    block: 'Block',
    pollInfo: 'Poll information',
    blockPoll: 'Block this report',
    deleteReport: 'Delete report',
    reason: 'Report reason',
    activePoll: 'Active this poll',
    confirm: {
      block: 'Do Block Poll?',
      contentBlock: 'Are you sure you want to block this poll?',
      active: 'Do Active Poll?',
      contentActive: 'Are you sure you want to active this poll?',
      delete: 'Delete Report',
      contentDelete: 'Are you sure you want to delete this report?',
    },
    toastr: {
      deleteSuccessful: 'Delete Report successfully',
      successful: 'poll successfully',
    },
  },
};

export default report;
