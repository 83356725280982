import { IReport } from '../locale';

const report: IReport = {
  listReport: 'リストレポート',
  type: '理由',
  reporter: 'レポーター',
  reporterEmail: 'レ記者のメールアドレス',
  keywordPlaceholder: 'ユーザー名またはメールアドレスで検索',
  reportType: {
    ALL: '全て',
    SEXUAL_CONTENT: '性的なコンテンツ',
    VIOLENT_CONTENT: '暴力的または不快なコンテンツ',
    HATEFUL_CONTENT: '憎悪または虐待的なコンテンツ',
    HARASSMENT_OR_BULLYING: 'ハラスメントまたはいじめ',
    HARMFUL_ACTS: '有害または危険な行為',
    MISINFORMATION: '誤報',
    CHILD_ABUSE: '児童虐待',
    PROMOTES_TERRORISM: 'テロを助長する',
    SPAM_OR_MISLEADING: 'スパムまたは誤解を招くもの',
  },
  detail: {
    title: 'レポート詳細',
    reporterInfo: 'レポーター情報',
    block: 'ブロック',
    pollInfo: '投稿情報',
    blockPoll: 'この投票をブロックする',
    deleteReport: 'この投票を削除する',
    reason: '理由の報告',
    activePoll: 'この投票をアクティブする',
    confirm: {
      block: '投票ブロック実施?',
      contentBlock: '本当にこの投票をブロックしますか？',
      active: '投票アクティブ実施?',
      contentActive: '本当にこの投票をアクティブしますか?',
      delete: 'レポート削除',
      contentDelete: '本当にこの投票を削除しますか?',
    },
    toastr: {
      deleteSuccessful: 'レポート削除が完了しました',
      successful: '投票は成功した',
    },
  },
};

export default report;
