import { Navigate } from 'react-router-dom';

import PublicLayout from '@/layouts/PublicLayout';
import ForgotPassword from '@/pages/auth/forgot-password';
import ConfirmOTP from '@/pages/auth/forgot-password/confirm-otp';
import ResetPassword from '@/pages/auth/forgot-password/reset-password';
import Login from '@/pages/auth/login';

const PublicRoutes = {
  path: '/',
  element: <PublicLayout />,
  exact: true,
  children: [
    {
      path: '/',
      element: <Navigate to='login' replace />,
    },
    {
      path: 'login',
      element: <Login />,
    },
    {
      path: 'forgot-password',
      element: <ForgotPassword />,
    },
    {
      path: 'forgot-password/confirm-otp',
      element: <ConfirmOTP />,
    },
    {
      path: 'forgot-password/reset-password',
      element: <ResetPassword />,
    },
  ],
};

export default PublicRoutes;
