import { combineReducers } from '@reduxjs/toolkit';

import authSlice from './features/auth.slice';
import counterSlice from './features/counter.slice';

const rootReducer = combineReducers({
  counter: counterSlice,
  auth: authSlice,
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
