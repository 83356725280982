export const generateImgCanvasURLFromText = (
  text: string,
  width: number,
  height: number,
  color: string
) => {
  // get first letter of each word in a string
  const name = text
    ?.toUpperCase()
    ?.split(' ')
    .map((word) => word.charAt(0))
    .join('')[0];

  const canvas = document.createElement('canvas');
  // eslint-disable-next-line prefer-const
  let context = canvas.getContext('2d');

  if (context) {
    canvas.width = width;
    canvas.height = height;

    context.fillStyle = assignColors()[name] || color;
    context.fillRect(0, 0, width, height);

    context.fillStyle = `${color}50`;
    context.fillRect(0, 0, width, height);

    context.fillStyle = '#fff';
    context.textBaseline = 'middle';
    context.textAlign = 'center';
    context.font = `${height / 2}px Roboto`;
    context.fillText(name, width / 2, height / 2);

    return canvas.toDataURL();
  }

  return '';
};

function assignColors() {
  const colors: any = {};
  const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890';

  for (let i = 0; i < alphabet.length; i++) {
    const hue = (i * (360 / alphabet.length)) % 360;
    const saturation = '100%';
    const lightness = '20%';

    const color = 'hsl(' + hue + ', ' + saturation + ', ' + lightness + ')';

    colors[alphabet[i]] = color;
  }

  return colors;
}

export const displayMediaUser = (user: any) => {
  if (!user?.avatar)
    return generateImgCanvasURLFromText(user?.username ?? '', 60, 60, '#ccc');

  return user.avatar?.startsWith('http')
    ? user?.avatar
    : process.env.REACT_APP_PUBLIC_S3_URL + user?.avatar;
};

export const displayMediaPoll = (poll: any) => {
  if (!poll.attachmentUrl)
    return generateImgCanvasURLFromText(poll.title, 120, 60, '#ccc');

  return poll.attachmentUrl?.startsWith('http')
    ? poll.attachmentUrl
    : process.env.REACT_APP_PUBLIC_S3_URL + poll.attachmentUrl;
};
