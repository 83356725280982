import { ISale } from '../locale';

const sale: ISale = {
  listSale: 'Payment List',
  id: 'Payment ID',
  plan: 'Plan',
  type: 'Type',
  amount: 'Amount',
  status: 'Order status',
  userRequest: 'User request',
  created: 'Date order',
};

export default sale;
