import { Image } from 'antd';
import classNames from 'classnames';
import React from 'react';
import { GiQueenCrown } from 'react-icons/gi';
import { IoMdRadioButtonOff } from 'react-icons/io';
import '@/styles/components/voting/live-view/compare.scss';

import VideoPlayer from '@/components/VideoPlayer';

import { E_TYPE_MEDIA } from '@/helpers/constants/voting.constant';

import CompareBar from './CompareBar';

const Compare = ({ options }: any) => {
  return (
    <div className='comparison d-flex flex-column'>
      <div className='comparison__options'>
        {options.map((option: any, index: number) => (
          <div className='comparison__options-item' key={index}>
            <div className='comparison__options-item-media'>
              {option.attachmentType === E_TYPE_MEDIA._IMAGE ? (
                <Image
                  src={
                    process.env.REACT_APP_PUBLIC_S3_URL + option.attachmentUrl
                  }
                  height={'100%'}
                  width={'100%'}
                  alt=''
                />
              ) : (
                <VideoPlayer
                  url={
                    process.env.REACT_APP_PUBLIC_S3_URL + option.attachmentUrl
                  }
                  view={true}
                />
              )}
            </div>
            <div className='comparison__options-item-content d-flex flex-column'>
              <div
                className={classNames([
                  'comparison__options-item-content-option d-flex flex-row align-items-center w-full justify-content-start',
                  {
                    'is-first': index === 0,
                  },
                ])}
              >
                {<IoMdRadioButtonOff size={20} />}
                <span>{option.title}</span>
              </div>
              <div
                className='comparison__options-item-content-calc'
                style={{
                  color:
                    options.reduce((prev: any, current: any) =>
                      prev.votes > current.votes ? prev : current
                    )._id === option._id
                      ? '#FFB300'
                      : 'rgba(0, 0, 0, 0.48',
                }}
              >
                <GiQueenCrown size={40} />
                <span>{option?.votes || 0} People choice</span>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className='comparison__options-rate'>
        <CompareBar options={options} />
      </div>
    </div>
  );
};

export default Compare;
