import { IPoitAndGift } from '../locale';

const pointAndGift: IPoitAndGift = {
  config: {
    pointSetting: 'ポイント設定',
    other: 'その他',
    japan: '日本',
    voucherList: 'バウチャーリスト管理',
    voucherDetail: 'バウチャーリスト詳細',
    point: '点',
    titlePlaceholder: 'タイトルを入力',
    pointPlaceholder: 'ポイント',
    wheelSetting: 'ホイール設定',
    pointForSpin: '1スピンのポイント',
    giftList: 'ギフトリスト管理',
    giftDetail: 'ギフトの詳細',
    rate: '率 (%)',
    add: '追加',
    confirm: {
      deleteVoucher: 'バウチャー削除',
      contentDeleteVoucher: 'このバウチャーを削除してもよろしいですか？',
      update: 'ギフトを更新',
      contentUpdate: 'このギフトを本当に更新しますか？',
      deleteGift: 'ギフトを削除',
      contentDeleteGift: 'このギフトを本当に削除しますか?',
      contentUpdateSpin: '本当に1スピンのポイントを更新しますか？',
    },
    toastr: {
      deleteVoucherSuccessful: 'バウチャー削除が成功しました',
      updateSuccessful: 'ギフトの更新が成功しました',
      maxVoucherWarning: '最大 {{count}} 個のギフトがすでにあります!',
      createSuccessful: '新バウチャーの追加が成功しました',
      deleteGiftSuccessful: 'ギフトの削除が成功しました',
      totalWheelWarning: 'ホイールのギフト率は100%でなければならない',
      updateSpinSuccessful: 'スピンポイントの更新が成功しました',
    },
  },
};

export default pointAndGift;
