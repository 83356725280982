import { ErrorMessage } from '@hookform/error-message';
import { Button, Input } from 'antd';
import classNames from 'classnames';
import { createContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import '@/styles/pages/category.scss';

import useDocumentTitle from '@/hooks/useDocumentTitle';

import { confirmModal } from '@/components/ShowConfirm';

import { useAppDispatch } from '@/stores';
import { setLoading } from '@/stores/features/auth.slice';

import { translate } from '@/helpers/functions/language.helper';
import { locale_keys } from '@/helpers/locales/locale.key';
import { CategoryModel } from '@/helpers/types/voting.types';
import apiService from '@/services/api/api.services';
import endpointConfig from '@/services/api/endpoint.config';

import CategoryGroup from './category-group';
export interface CategoryContextType {
  state: boolean;
  resetState: () => void;
}

export const CategoryContext = createContext<CategoryContextType>({
  state: true,
  resetState: () => undefined,
});

const CategoryManagement = () => {
  useDocumentTitle('Voting Management');
  const dispatch = useAppDispatch();
  const [categories, setCategories] = useState<CategoryModel[]>();
  const location = useLocation();
  const [, setOldLocation] = useState(location);

  const resetState = () => {
    setResetTheme((prev) => ({
      state: !prev,
      resetState,
    }));
  };

  const [resetTheme, setResetTheme] = useState({
    state: true,
    resetState,
  });

  const defaultValues = {
    titleEn: '',
    titleJp: '',
  };

  const {
    handleSubmit,
    register,
    control,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues,
  });

  const onSubmit = async (data: any) => {
    try {
      await confirmModal({
        title: translate(locale_keys.voteManagement.category.confirm.create),
        content: translate(
          locale_keys.voteManagement.category.confirm.contentCreate
        ),
        okText: translate(locale_keys.filter.create),
        cancelText: translate(locale_keys.filter.cancel),
        // icon: <InfoCircleFilled />,
      });
      doCreateNewCategory(data);
    } catch (e) {
      // User clicked Cancel or closed the modal
    }
  };

  const doCreateNewCategory = async (dataForm: any) => {
    dispatch(setLoading(true));
    // eslint-disable-next-line no-debugger
    debugger;
    try {
      const reqData = {
        titleEn: dataForm?.titleEn,
        titleJp: dataForm?.titleJp,
      };
      const { data } = await apiService.post(
        endpointConfig.categories,
        reqData
      );

      if (data?.success) {
        toast.success(
          translate(locale_keys.voteManagement.category.toastr.createSuccessful)
        );
        reset(defaultValues);
        getListCategories();
      }
    } finally {
      setTimeout(() => {
        dispatch(setLoading(false));
      }, 250);
    }
  };

  const getListCategories = async () => {
    dispatch(setLoading(true));

    try {
      const { data } = await apiService.get(endpointConfig.categories);
      setCategories(data?.data);
    } finally {
      setTimeout(() => {
        dispatch(setLoading(false));
      }, 250);
    }
  };

  useEffect(() => {
    getListCategories();
    setOldLocation((prevLocation: any) => {
      if (prevLocation !== location) {
        reset(defaultValues);
        return location;
      }
      return prevLocation;
    });
  }, [resetTheme, location]);

  return (
    <div className='d-flex mh-100'>
      <form
        style={{ width: '30%', marginRight: '30px' }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className='filter'>
          <div className='filter__title'>
            {translate(locale_keys.voteManagement.category.title)}
          </div>
          <div className='filter__form'>
            <div className='mr-20'>
              <label
                htmlFor='titleJp'
                className='form-label filter__form-label'
              >
                {translate(locale_keys.voteManagement.category.japanName)}
              </label>
              <div>
                <Controller
                  name='titleJp'
                  control={control}
                  defaultValue=''
                  render={({ field }) => (
                    <Input
                      size='large'
                      value={field.value}
                      placeholder={translate(
                        locale_keys.voteManagement.category.japanNameDes
                      )}
                      className={classNames(['login__form-input'], {
                        ['--error']: errors['titleJp'],
                      })}
                      maxLength={80}
                      {...register('titleJp', {
                        required: translate(
                          locale_keys.voteManagement.category.japanTitleRequired
                        ),
                      })}
                      onChange={(e: any) => field.onChange(e.target.value)}
                    />
                  )}
                />
                <ErrorMessage
                  errors={errors}
                  name='titleJp'
                  render={({ message }) => (
                    <p className='text--error'>{message}</p>
                  )}
                />
              </div>
            </div>
            <div className='mr-20 mt-20'>
              <label
                htmlFor='titleEn'
                className='form-label filter__form-label'
              >
                {translate(locale_keys.voteManagement.category.englishName)}
              </label>
              <div>
                <Controller
                  name='titleEn'
                  control={control}
                  defaultValue=''
                  render={({ field }) => (
                    <Input
                      size='large'
                      value={field.value}
                      placeholder={translate(
                        locale_keys.voteManagement.category.englishNameDes
                      )}
                      className={classNames(['login__form-input'], {
                        ['--error']: errors['titleEn'],
                      })}
                      maxLength={80}
                      {...register('titleEn', {
                        required: translate(
                          locale_keys.voteManagement.category.japanTitleRequired
                        ),
                      })}
                      onChange={(e: any) => field.onChange(e.target.value)}
                    />
                  )}
                />
                <ErrorMessage
                  errors={errors}
                  name='titleEn'
                  render={({ message }) => (
                    <p className='text--error'>{message}</p>
                  )}
                />
              </div>
            </div>
            <div className='d-flex align-items-end mt-20'>
              <Button className='form-btn form-btn--black' htmlType='submit'>
                {translate(locale_keys.filter.create)}
              </Button>
            </div>
          </div>
        </div>
      </form>

      <div className='category d-flex flex-column'>
        <div className='category__heading'>
          {translate(locale_keys.voteManagement.category.categoryList)}
        </div>
        <CategoryContext.Provider value={resetTheme}>
          <CategoryGroup categories={categories}></CategoryGroup>
        </CategoryContext.Provider>
      </div>
    </div>
  );
};

export default CategoryManagement;
