import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import '@/styles/components/_toggle.scss';

import { E_LANGUAGE } from '@/helpers/constants/common.constant';
import { switchLanguage } from '@/helpers/functions/language.helper';
import i18n from '@/services/i18n/i18n.config';

const Language = () => {
  const location = useLocation();
  const [checked, setChecked] = useState<boolean>(false);

  const navigate = useNavigate();
  const lng = i18n.language;

  useEffect(() => {
    if (lng === E_LANGUAGE._EN) {
      setChecked(true);
    } else {
      setChecked(false);
    }
  }, [lng]);

  const changeLanguage = () => {
    switchLanguage(lng === E_LANGUAGE._EN ? E_LANGUAGE._JP : E_LANGUAGE._EN);
    navigate(location.pathname);
  };
  return (
    <center className='d-flex'>
      <div className='switch'>
        <input
          id='language-toggle'
          className='check-toggle check-toggle-round-flat'
          type='checkbox'
          checked={checked}
          onChange={changeLanguage}
        />
        <label htmlFor='language-toggle'></label>
        <span className='on'>JP</span>
        <span className='off'>EN</span>
      </div>
    </center>
  );
};

export default Language;
