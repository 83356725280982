export enum GIFTS_TYPE {
  _VOUCHER = 'VOUCHER',
  _WHEEL = 'WHEEL',
}

export enum HISTORY_GIFT_STATUS {
  _COMPLETED = 'COMPLETED',
  _IN_PROGRESS = 'IN_PROGRESS',
  _CANCEL = 'CANCEL',
}

export const HISTORY_GIFT_STATUS_OPTIONS = [
  {
    label: 'ALL',
    key: 'ALL',
    value: '',
  },
  {
    label: 'COMPLETED',
    key: 'COMPLETED',
    value: 'COMPLETED',
  },
  {
    label: 'IN_PROGRESS',
    key: 'IN_PROGRESS',
    value: 'IN_PROGRESS',
  },
  {
    label: 'CANCEL',
    key: 'CANCEL',
    value: 'CANCEL',
  },
];

export const HISTORY_GIFT_STATUS_TYPES = [
  {
    label: 'ALL',
    key: 'ALL',
    value: '',
  },
  {
    label: 'VOUCHER',
    key: 'VOUCHER',
    value: 'VOUCHER',
  },
  {
    label: 'WHEEL',
    key: 'WHEEL',
    value: 'WHEEL',
  },
];
