import { CheckOutlined, StopOutlined } from '@ant-design/icons';
import { Button, Drawer, Space } from 'antd';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { confirmModal } from '@/components/ShowConfirm';

import { useAppDispatch } from '@/stores';
import { setLoading } from '@/stores/features/auth.slice';

import { translate } from '@/helpers/functions/language.helper';
import { locale_keys } from '@/helpers/locales/locale.key';
import apiService from '@/services/api/api.services';
import endpointConfig from '@/services/api/endpoint.config';

import PollDetails, { IPollDetailsProps } from './detail-poll';

interface PollDetailDrawerProps {
  resetDrawer: boolean;
  selectedId: string;
  open: boolean;
  onCloseDetailDrawer: () => void;
  onRefresh: () => void;
}

const PollDetailDrawer = ({
  resetDrawer,
  selectedId,
  open,
  onCloseDetailDrawer,
  onRefresh,
}: PollDetailDrawerProps) => {
  const dispatch = useAppDispatch();

  const [poll, setPoll] = useState<IPollDetailsProps>({
    title: '',
    type: '',
    description: '',
    deadline: '',
    _id: '',
    listEmails: [],
  });

  const getDetailPoll = async (selectedId: string) => {
    dispatch(setLoading(true));

    try {
      const { data } = await apiService.get(
        `${endpointConfig.polls}/${selectedId}`
      );
      setPoll(data?.data);
    } finally {
      setTimeout(() => {
        dispatch(setLoading(false));
      }, 250);
    }
  };

  const onBlockPoll = async (type: string = 'BLOCKED' || 'REJECTED') => {
    try {
      await confirmModal({
        title:
          type === 'BLOCKED'
            ? translate(
                locale_keys.voteManagement.pollList.detail.confirm.block
              )
            : translate(
                locale_keys.voteManagement.pollList.detail.confirm.reject
              ),
        content:
          type === 'BLOCKED'
            ? translate(
                locale_keys.voteManagement.pollList.detail.confirm.contentBlock
              )
            : translate(
                locale_keys.voteManagement.pollList.detail.confirm.contentReject
              ),
        okText: translate(locale_keys.filter.yes),
        cancelText: translate(locale_keys.filter.cancel),
        // icon: <InfoCircleFilled />,
      });

      doBlock(type);
    } catch (e) {
      // User clicked Cancel or closed the modal
    }
  };

  const doBlock = async (type: string = 'BLOCKED' || 'REJECTED') => {
    dispatch(setLoading(true));

    try {
      const res = await apiService.patch(
        `${endpointConfig.updateStatusPoll}/${selectedId}`,
        {
          status: type,
        }
      );
      if (res?.data?.success) {
        onCloseDetailDrawer();
        toast.success(
          translate(
            locale_keys.voteManagement.pollList.detail.toastr.blockSuccessful
          )
        );
        onRefresh();
      }
    } finally {
      setTimeout(() => {
        dispatch(setLoading(false));
      }, 250);
    }
  };

  const onActivePoll = async () => {
    try {
      await confirmModal({
        title: translate(
          locale_keys.voteManagement.pollList.detail.confirm.active
        ),
        content: translate(
          locale_keys.voteManagement.pollList.detail.confirm.contentActive
        ),
        okText: translate(locale_keys.filter.active),
        cancelText: translate(locale_keys.filter.cancel),
        // icon: <InfoCircleFilled />,
      });

      doActive();
    } catch (e) {
      // User clicked Cancel or closed the modal
    }
  };

  const doActive = async () => {
    dispatch(setLoading(true));

    try {
      const res = await apiService.patch(
        `${endpointConfig.updateStatusPoll}/${selectedId}`,
        {
          status: 'ACTIVE',
        }
      );
      if (res?.data?.success) {
        onCloseDetailDrawer();
        toast.success(
          translate(
            locale_keys.voteManagement.pollList.detail.toastr.activeSuccessful
          )
        );
        onRefresh();
      }
    } finally {
      setTimeout(() => {
        dispatch(setLoading(false));
      }, 250);
    }
  };

  const onActionRecommend = async (doRecommend: boolean) => {
    try {
      await confirmModal({
        title: doRecommend
          ? translate(
              locale_keys.voteManagement.pollList.detail.confirm.recommend
            )
          : translate(
              locale_keys.voteManagement.pollList.detail.confirm.removeRecommend
            ),
        content: doRecommend
          ? translate(
              locale_keys.voteManagement.pollList.detail.confirm
                .contentRecommend
            )
          : translate(
              locale_keys.voteManagement.pollList.detail.confirm
                .removeContentRecommend
            ),
        okText: translate(locale_keys.filter.yes),
        cancelText: translate(locale_keys.filter.cancel),
        // icon: <InfoCircleFilled />,
      });

      doActionRecommend(doRecommend);
    } catch (e) {
      // User clicked Cancel or closed the modal
    }
  };

  const doActionRecommend = async (doRecommend: boolean) => {
    dispatch(setLoading(true));

    try {
      if (doRecommend) {
        const res = await apiService.post(
          `${endpointConfig.actionRecommend}/${selectedId}`
        );
        if (res?.data?.success) {
          onCloseDetailDrawer();
          toast.success(
            translate(
              locale_keys.voteManagement.pollList.detail.toastr
                .recommentSuccessful
            )
          );
          onRefresh();
        }
      } else {
        const res = await apiService.delete(
          `${endpointConfig.actionRecommend}/${selectedId}`
        );
        if (res?.data?.success) {
          onCloseDetailDrawer();
          toast.success(
            translate(
              locale_keys.voteManagement.pollList.detail.toastr
                .removeRecommentSuccessful
            )
          );
          onRefresh();
        }
      }
    } finally {
      setTimeout(() => {
        dispatch(setLoading(false));
      }, 250);
    }
  };

  useEffect(() => {
    if (selectedId !== '') {
      getDetailPoll(selectedId);
    }
  }, [resetDrawer]);

  return (
    <>
      <Drawer
        title={translate(locale_keys.voteManagement.pollList.detail.title)}
        width={1000}
        placement='right'
        onClose={onCloseDetailDrawer}
        open={open}
        bodyStyle={{ paddingBottom: 80 }}
        extra={
          poll?.status === 'ACTIVE' ? (
            <Space>
              {poll?.recommendByAdmin ? (
                <Button
                  type='dashed'
                  danger
                  icon={<StopOutlined />}
                  className='d-flex align-items-center'
                  onClick={() => onActionRecommend(false)}
                >
                  {translate(
                    locale_keys.voteManagement.pollList.detail.removeRecommend
                  )}
                </Button>
              ) : (
                <Button
                  type='dashed'
                  icon={<CheckOutlined />}
                  className='d-flex align-items-center text-active text-active--border'
                  onClick={() => onActionRecommend(true)}
                >
                  {translate(
                    locale_keys.voteManagement.pollList.detail.recommend
                  )}
                </Button>
              )}
              <Button
                type='dashed'
                danger
                icon={<StopOutlined />}
                className='d-flex align-items-center'
                onClick={() => onBlockPoll('BLOCKED')}
              >
                {translate(
                  locale_keys.voteManagement.pollList.detail.blockPoll
                )}
              </Button>
            </Space>
          ) : poll?.status === 'REJECTED' || poll?.status === 'BLOCKED' ? (
            <Space>
              <Button
                type='dashed'
                icon={<CheckOutlined />}
                className='d-flex align-items-center text-active text-active--border'
                onClick={onActivePoll}
              >
                {translate(
                  locale_keys.voteManagement.pollList.detail.activePoll
                )}
              </Button>
            </Space>
          ) : poll?.status === 'PENDING' ? (
            <Space>
              <Button
                type='dashed'
                icon={<CheckOutlined />}
                className='d-flex align-items-center text-active text-active--border'
                onClick={onActivePoll}
              >
                {translate(
                  locale_keys.voteManagement.pollList.detail.activePoll
                )}
              </Button>
              <Button
                type='dashed'
                danger
                icon={<StopOutlined />}
                className='d-flex align-items-center'
                onClick={() => onBlockPoll('REJECTED')}
              >
                {translate(
                  locale_keys.voteManagement.pollList.detail.rejectPoll
                )}
              </Button>
            </Space>
          ) : null
        }
      >
        <PollDetails {...poll} />
      </Drawer>
    </>
  );
};

export default PollDetailDrawer;
