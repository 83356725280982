import { ErrorMessage } from '@hookform/error-message';
import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import { FiX } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import '@/styles/pages/forgot-password.scss';
import '@/styles/pages/login.scss';

import useDocumentTitle from '@/hooks/useDocumentTitle';

import Logo from '@/components/Logo';

import { useAppDispatch } from '@/stores';
import { forgotPassword, setLoading } from '@/stores/features/auth.slice';

import { E_PATHS } from '@/helpers/constants/common.constant';
import { translate } from '@/helpers/functions/language.helper';
import { locale_keys } from '@/helpers/locales/locale.key';
import regex from '@/helpers/regex';
import apiService from '@/services/api/api.services';
import endpointConfig from '@/services/api/endpoint.config';

const ForgotPassword = () => {
  useDocumentTitle(translate(locale_keys.heading.forgotPwd));

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const onForgotPassword = (data: any) => {
    dispatch(setLoading(true));

    apiService
      .post(endpointConfig.validEmailToken + '?isAdmin=true', data)
      .then(() => {
        dispatch(forgotPassword({ email: data.email }));
        navigate(E_PATHS._FORGOT_PASSWORD_CONFIRM_OTP);
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };

  const backToLoginPage = () => {
    navigate(E_PATHS._LOGIN);
  };

  return (
    <div className='forgot-password'>
      <div className='forgot-password__background p-5'>
        <Logo />
      </div>

      <div className='forgot-password__quit' onClick={backToLoginPage}>
        <FiX height={72} width={72} />
      </div>

      <div className='forgot-password__container'>
        <form onSubmit={handleSubmit(onForgotPassword)}>
          {/* form */}
          <h3 className='forgot-password__welcome'>
            {translate(locale_keys.auth.welcomTitle.forgotPassword)}
          </h3>
          <div className='forgot-password__form'>
            <label htmlFor='email' className='text-center'>
              {translate(locale_keys.auth.description.forgotPassword)}
            </label>
            <input
              type='text'
              id='email'
              placeholder={translate(locale_keys.auth.placeholder.email)}
              className={classNames(['login__form-input mt-50'], {
                ['--error']: errors['email'],
              })}
              {...register('email', {
                required: translate(locale_keys.formRule.required),
                validate: (value) =>
                  regex.email(value) || translate(locale_keys.formRule.email),
              })}
            />
            <ErrorMessage
              errors={errors}
              name='email'
              render={({ message }) => <p className='text--error'>{message}</p>}
            />

            <button
              type='submit'
              className='login__form-btn app-btn--black-21 mt-30'
            >
              {translate(locale_keys.auth.button.forgotPassword)}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ForgotPassword;
