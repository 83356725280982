import { IAdvertisement } from '../locale';

const advertisement: IAdvertisement = {
  listAds: 'List Ads',
  description: 'Description',
  location: 'Location',
  start: 'Start',
  end: 'End',
  viewDetail: 'Detail',
  keywordPlaceholder: 'keyword',
  url: 'URL',
  detail: {
    title: 'Detail Ads',
    listCreateTitle: 'Create advertisement',
    requestTitle: 'Advertising Request Detail',
    descriptionPlaceholder: 'Brief description',
    urlPlaceholder: 'URL of this Ads',
    startEndDate: 'Start date - End date',
    startPlaceholder: 'Start date',
    endPlaceholder: 'End date',
    timeDisplay: 'Time / display (seconds)',
    timeDisplayPlaceholder: 'Point',
    verticalBanner: 'Vertical banner',
    uploadVideo: 'Upload video',
    horizontalBanner: 'Horizontal banner',
    selectImage: 'Select Image',
    selectVideo: 'Select Video',
    download: 'Download',
    toastr: {
      createSuccessful: 'Create new ad successfully',
      updateSuccessful: 'Update ad successfully',
      deleteSuccessful: 'Delete ads successfully',
      typeVideoWarning: 'Invalid file type. Only video files are allowed.',
      minSizeVideo: 'File size must be smaller than 20MB',
    },
    confirm: {
      delete: 'Delete Ads',
      contentDelete: 'Are you sure you want to delete this ads?',
    },
    required: {
      input: 'This field is required',
      upload: 'Requires uploading at least one media',
    },
  },
};
export default advertisement;
