import { Modal } from 'antd';

export const confirmModal = (props: any) => {
  return new Promise<void>((resolve, reject) => {
    Modal.confirm({
      ...props,
      onOk: () => {
        resolve();
      },
      onCancel: () => {
        reject();
      },
    });
  });
};
