import { IValidation } from '../locale';

const validation: IValidation = {
  fieldRequired: 'This field is required',
  nonNegativeNumber: 'Do not enter a non-negative number',
  valueAsInteger: 'Value must be an integer',
  invalidUrl: 'This is an invalid URL',
  negativeNumber: 'Do not enter a negative number',
};

export default validation;
