import { IInvoiceStatus } from '../locale';

const invoiceStatus: IInvoiceStatus = {
  PAID: '支払い済み',
  DRAFT: '下書き',
  OPEN: '開ける',
  UNCOLLECTIBLE: '取得できません',
  VOID: 'キャンセル',
};
export default invoiceStatus;
