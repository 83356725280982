import { Collapse } from 'antd';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import useDocumentTitle from '@/hooks/useDocumentTitle';

import { translate } from '@/helpers/functions/language.helper';
import { locale_keys } from '@/helpers/locales/locale.key';

import Overview from './overview';
import Policy from './policy';
import TermOfService from './termOfService';

const SiteInformation = () => {
  const { Panel } = Collapse;
  useDocumentTitle(translate(locale_keys.menu.site));

  const location = useLocation();
  useEffect(() => {
    useDocumentTitle;
  }, [location]);

  return (
    <Collapse expandIconPosition={'end'} size='large'>
      <Panel header={translate(locale_keys.siteInformation.about)} key='1'>
        <Overview />
      </Panel>
      <Panel
        header={translate(locale_keys.siteInformation.privacyPolicy)}
        key='2'
      >
        <Policy />
      </Panel>
      <Panel
        header={translate(locale_keys.siteInformation.termOfService)}
        key='3'
      >
        <TermOfService />
      </Panel>
    </Collapse>
  );
};

export default SiteInformation;
