import { IAuth } from '../locale';

const auth: IAuth = {
  button: {
    login: 'HILFE AI にログイン', // Login
    register: '新規登録', // Register
    forgotPassword: '送信', // sent request
    confirmOtp: '確認', // Confirm OTP
    resetPassword: '変更', // Change
  },
  text: {
    forgotPassword: 'パスワードを忘れた場合', // Forgot your password
    register: '新規登録', // Register
    login: 'ログイン', // Login
    logout: 'ログアウト',
    resendOtp: '再送信', // Resend OTP
    haveAccount: 'アカウントをお持ちの場合', // Already a member?
    notHaveAccount: 'アカウントをお持ちでないですか?', // Not on Pitchcasts yet?
    countDownOTP: 'OTPコードを入力してください', // Enter your OTP
    notSeeOTP: 'OTP認証メールが確認できません',
  },
  title: {
    login: '',
    register: '',
    forgotPassword: '',
  },
  description: {
    login: '無限に広がる意見、思想と本音を展開しよう。', // Let's develop infinite opinions, thoughts and true intentions.
    register: '簡単で簡単です。', // It's quick and easy.
    forgotPassword: 'メールアドレスを入力してください?', // What your email address?
    confirmOtp: '',
    resetPassword: '',
  },
  welcomTitle: {
    login: 'ピッチキャストへようこそ',
    register: '新規登録',
    forgotPassword: 'HILFE AIアカウントを見つけましょう',
    confirmOtp: 'メールをご確認ください',
    resetPassword: 'パスワードを変更する',
  },
  placeholder: {
    email: 'ユーザー名またはメールアドレス',
    password: 'パスワード',
    fullname: 'フルネーム',
    confirmPassword: 'パスワード確認',
    otp: '0',
    username: 'ユーザー名',
  },
  toast: {
    loginFailed: 'ログインに失敗しました',
    resendOTPSuccessful: 'OTPコードの再送が成功しました',
    resetPwdSuccessful: 'パスワードを更新しました',
  },
};

export default auth;
