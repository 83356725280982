import { ErrorMessage } from '@hookform/error-message';
import { Button } from 'antd';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import TextEditor from '@/components/TextEditor';

import { useAppDispatch } from '@/stores';
import { setLoading } from '@/stores/features/auth.slice';

import { translate } from '@/helpers/functions/language.helper';
import { locale_keys } from '@/helpers/locales/locale.key';
import apiService from '@/services/api/api.services';
import endpointConfig from '@/services/api/endpoint.config';

const TermOfService = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  const defaultValues = {
    termOfUseEn: '',
    termOfUseJp: '',
  };

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues,
  });

  const getInformations = async () => {
    dispatch(setLoading(true));
    try {
      const { data } = await apiService.get(
        `${endpointConfig.informations}?type=TERM_OF_USE`
      );
      reset({
        ...data?.data,
        termOfUseEn: data?.data?.termOfUseEn,
        termOfUseJp: data?.data?.termOfUseJp,
      });
    } finally {
      setTimeout(() => {
        dispatch(setLoading(false));
      }, 250);
    }
  };

  const onRefresh = async () => {
    await getInformations();
  };

  const onSubmit = async (dataForm: any) => {
    dispatch(setLoading(true));

    try {
      const reqData: any = {
        termOfUseEn: dataForm?.termOfUseEn,
        termOfUseJp: dataForm?.termOfUseJp,
      };

      const { data } = await apiService.patch(
        endpointConfig.informations,
        reqData
      );
      if (data?.success) {
        toast.success(
          translate(locale_keys.siteInformation.updateTermSuccessful)
        );
        onRefresh();
      }
    } catch (error) {
      dispatch(setLoading(false));
    } finally {
      setTimeout(() => {
        dispatch(setLoading(false));
      }, 250);
    }
  };

  useEffect(() => {
    getInformations();
  }, [location]);

  return (
    <>
      <form id='createTerm' onSubmit={handleSubmit(onSubmit)}>
        <div className='d-flex justify-content-end mb-20'>
          <Button className='form-btn' onClick={() => onRefresh()}>
            {translate(locale_keys.filter.refresh)}
          </Button>
          <Button className='form-btn form-btn--black ml-20' htmlType='submit'>
            {translate(locale_keys.filter.update)}
          </Button>
        </div>
        <div className='d-flex justify-content-between'>
          <div style={{ width: '48%' }}>
            <label htmlFor='timeEnd' className='form-label filter__form-label'>
              {translate(locale_keys.siteInformation.japanese)}
            </label>
            <div>
              <Controller
                name='termOfUseJp'
                control={control}
                defaultValue=''
                // rules={{
                //   maxLength: {
                //     value: 20000,
                //     message: translate(
                //       locale_keys.siteInformation.descriptionMaxlength
                //     ),
                //   },
                // }}
                render={({ field }) => (
                  <TextEditor
                    value={field.value}
                    onChange={(value: any) => {
                      field.onChange(value);
                    }}
                  />
                )}
              />
              <ErrorMessage
                errors={errors}
                name='termOfUseJp'
                render={({ message }) => (
                  <p className='text--error'>{message}</p>
                )}
              />
            </div>
          </div>
          <div style={{ width: '48%' }}>
            <label htmlFor='timeEnd' className='form-label filter__form-label'>
              {translate(locale_keys.siteInformation.english)}
            </label>
            <div>
              <Controller
                name='termOfUseEn'
                control={control}
                defaultValue=''
                // rules={{
                //   maxLength: {
                //     value: 20000,
                //     message: translate(
                //       locale_keys.siteInformation.descriptionMaxlength
                //     ),
                //   },
                // }}
                render={({ field }) => (
                  <TextEditor
                    value={field.value}
                    onChange={(value: any) => {
                      field.onChange(value);
                    }}
                  />
                )}
              />
              <ErrorMessage
                errors={errors}
                name='termOfUseEn'
                render={({ message }) => (
                  <p className='text--error'>{message}</p>
                )}
              />
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default TermOfService;
