import { Button, Image, Space, Tag } from 'antd';
import dayjs from 'dayjs';
import React from 'react';
import { AiOutlineHeart, AiOutlineMail } from 'react-icons/ai';
import { BiCategoryAlt, BiComment } from 'react-icons/bi';
import { BsCalendar } from 'react-icons/bs';
import { SiZwave } from 'react-icons/si';
import '@/styles/components/voting/poll-details.scss';

import { infoModal } from '@/components/ShowInformation';

import { useAppSelector } from '@/stores';

import { E_LANGUAGE } from '@/helpers/constants/common.constant';
import { E_DEFAULT, E_TYPE_USER } from '@/helpers/constants/voting.constant';
import { displayMediaUser } from '@/helpers/functions/canvas.helper';
import {
  translate,
  translateChartLegend,
} from '@/helpers/functions/language.helper';
import { locale_keys } from '@/helpers/locales/locale.key';
import i18n from '@/services/i18n/i18n.config';

import PollLiveViewOptions from './options';

export interface IPollDetailsProps {
  _id: string;
  title: string;
  description: string;
  category?: any;
  hashtags?: string[];
  deadline?: Date | string;
  type: string;
  allowUserAddOptions?: boolean;
  created_at?: Date;
  options?: any[];
  attachmentUrl?: string;
  attachmentType?: string;
  owner?: any;
  isDetail?: any;
  likes?: any;
  [key: string]: any;
  listEmails: any[];
}

const PollDetails = ({
  title,
  description,
  category,
  hashtags,
  deadline,
  type,
  allowUserAddOptions,
  created_at,
  options,
  attachmentUrl,
  attachmentType,
  owner,
  isDetail,
  listEmails,
  ...props
}: IPollDetailsProps) => {
  const { user }: any = useAppSelector((state) => state.auth.auth);

  const commentRef = React.useRef<any>(null);

  const [author, setAuthor] = React.useState({
    fullName: '',
    follower: 0,
  });

  const handleShowEmail = () => {
    infoModal(
      listEmails.map((email: any, index: number) => {
        return (
          <>
            <p>{`${index + 1}. ${email}`}</p>
          </>
        );
      }),
      { title: translate(locale_keys.voteManagement.pollList.listEmail) }
    );
  };

  React.useEffect(() => {
    if (owner) {
      setAuthor({
        fullName: owner?.fullName,
        follower: 0,
      });
    } else {
      setAuthor({
        fullName: user?.fullName,
        follower: user?.role !== E_TYPE_USER._ANONYMOUS ? user?.follow || 0 : 0,
      });
    }
  }, [owner, user]);

  const onScrollToComment = () => {
    commentRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };

  const date = new Date(dayjs(created_at).format('MMMM DD YYYY'));

  const lang = 'en',
    year = date.toLocaleString(lang, { year: 'numeric' }),
    month = date.toLocaleString(lang, { month: 'numeric' }),
    day = date.toLocaleString(lang, { day: 'numeric' });
  const jpDate = `${year}年 ${month}月 ${day}日`;
  const lng = i18n.language;

  const createAt =
    lng === E_LANGUAGE._EN ? dayjs(created_at).format('MMMM DD YYYY') : jpDate;

  return (
    <React.Fragment>
      <div className='d-flex flex-column align-items-start poll-detail'>
        <div className='poll-detail__header'>
          <div className='poll-detail__type-poll'>
            <span className='poll-detail__type-poll--text'>
              <SiZwave className='me-2' />
              {translateChartLegend(type, locale_keys.pollType)}
            </span>
          </div>
        </div>
        <div className='poll-detail__body'>
          <div className='poll-detail__body-title'>
            <div className='title'>
              <h1>{title}</h1>
            </div>
            <div className='publish-time'>
              <span>
                {translate(
                  locale_keys.voteManagement.pollList.detail.published
                )}{' '}
                {createAt}
              </span>
            </div>
          </div>

          <div className='d-flex flex-row gap-2 align-items-end poll-detail__other'>
            <Space size={[0, 8]} wrap>
              {deadline && (
                <Tag
                  color='rgba(0, 150, 136, 0.64)'
                  className='d-flex flex-row gap-2 align-items-center'
                >
                  <BsCalendar />
                  {dayjs(deadline).format('DD-MM-YYYY')}
                </Tag>
              )}
              <Tag
                color='rgba(255, 193, 7, 0.64)'
                className='d-flex flex-row gap-2 align-items-center'
              >
                <BiCategoryAlt />
                {category?.title}
              </Tag>
              {hashtags?.map((item: any, index: number) => (
                <Tag
                  key={index}
                  color='rgba(205, 220, 57, 0.64)'
                  className='d-flex flex-row gap-2 align-items-center'
                >
                  #{item.title || item}
                </Tag>
              ))}
            </Space>
          </div>

          {options && (
            <PollLiveViewOptions
              pollId={props._id}
              options={options}
              attachmentUrl={attachmentUrl}
              attachmentType={attachmentType}
              type={type}
              allowUserAddOptions={allowUserAddOptions}
            />
          )}

          <div
            className='poll-detail__body-description ql-editor'
            dangerouslySetInnerHTML={{ __html: description }}
          ></div>
        </div>

        <div className='poll-detail__action'>
          <Button
            type='text'
            className='d-flex flex-row align-items-center justify-content-center gap-2'
          >
            {<AiOutlineHeart size={20} />}
            {props?.likes}{' '}
            {translate(locale_keys.voteManagement.pollList.detail.like)}
          </Button>
          <Button
            type='text'
            className='d-flex flex-row align-items-center justify-content-center gap-2'
            onClick={() => {
              isDetail && onScrollToComment();
            }}
          >
            <BiComment size={20} />{' '}
            {translate(locale_keys.voteManagement.pollList.detail.comment)}
          </Button>

          <Button
            type='text'
            className='d-flex flex-row align-items-center justify-content-center gap-2'
            onClick={handleShowEmail}
          >
            {<AiOutlineMail size={20} />}
            {translate(locale_keys.voteManagement.pollList.listEmail)}
          </Button>
        </div>
        <hr className='w-full' />
        <div className='poll-detail__author'>
          <div className='poll-detail__author-avatar'>
            <Image
              src={displayMediaUser(owner)}
              alt='avatar'
              width={40}
              height={40}
              style={{
                objectFit: 'cover',
              }}
              fallback={E_DEFAULT._IMAGE_FALLBACK}
            />
          </div>
          <div className='poll-detail__author-info'>
            <div className='name'>
              <span>{author.fullName}</span>
            </div>
            <div className='other'>
              <span>
                {translate(locale_keys.voteManagement.pollList.detail.owner, {
                  count: author?.follower,
                })}
              </span>
            </div>
          </div>
        </div>
        <hr className='w-full' />
      </div>
    </React.Fragment>
  );
};

export default PollDetails;
