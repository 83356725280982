import { IAnnouncement } from '../locale';

const announcement: IAnnouncement = {
  listAn: 'お知らせの一覧',
  createTitle: 'お知らせ作成',
  detailTitle: 'お知らせの詳細',
  titleFilterPlaceholder: 'タイトルを入力してください。',
  titlePlaceholder: 'タイトル',
  minDescription: '内容は３０文字以上で入力してください。',
  maxDescription: '内容は４８００文字以下で入力してください。',
  maxCharacter: 'タイトルは１００文字以下で入力してください。',
  minCharacter: 'タイトルは５文字以上で入力してください',
  toastr: {
    updateSuccessful: 'お知らせが更新されました。',
    createSuccessful: '新しいお知らせが作成されました。',
    deleteSuccessful: 'お知らせが削除されました。',
  },
  confirm: {
    delete: 'お知らせを削除する',
    contentDelete: 'このお知らせを削除してよろしいでしょうか？',
  },
};

export default announcement;
