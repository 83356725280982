import { CloseCircleTwoTone, UploadOutlined } from '@ant-design/icons';
import { Button, Image, Upload, UploadFile, UploadProps } from 'antd';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import '@/styles/pages/default-image.scss';

import useDocumentTitle from '@/hooks/useDocumentTitle';

import { confirmModal } from '@/components/ShowConfirm';

import { useAppDispatch } from '@/stores';
import { setLoading } from '@/stores/features/auth.slice';

import {
  E_FOLDER_MEDIA,
  E_TYPE_MEDIA,
} from '@/helpers/constants/voting.constant';
import { translate } from '@/helpers/functions/language.helper';
import { locale_keys } from '@/helpers/locales/locale.key';
import apiService from '@/services/api/api.services';
import endpointConfig from '@/services/api/endpoint.config';

const MEGABYTES = 1024 * 1024;

const DefaultImage = () => {
  useDocumentTitle('Voting Management');
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [uploading] = useState(false);
  const [images, setImages] = useState([]);
  const dispatch = useAppDispatch();
  const [maxUpload, setMaxUpload] = useState<number>(0);
  useEffect(() => {
    (async () => {
      try {
        const { data } = await apiService.get(endpointConfig.settings);
        setMaxUpload(data?.data?.polls?.configurations?.point?.maxUpload);
      } finally {
        setTimeout(() => {
          dispatch(setLoading(false));
        }, 250);
      }
    })();
  }, []);

  const props: UploadProps = {
    onRemove: (file: any) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file: any) => {
      const fileExtension = file?.name?.split('.').pop().toLowerCase();
      const allowedExtensions = ['png', 'jpg', 'jpeg'];

      if (!allowedExtensions.includes(fileExtension)) {
        toast.warning(
          translate(
            locale_keys.voteManagement.imageDefault.toastr.uploadTypeWarning
          )
        );
        return false; // Prevent the file from being uploaded
      }

      if (file?.size > maxUpload * MEGABYTES) {
        toast.warning(
          `${translate(
            locale_keys.voteManagement.imageDefault.toastr.uploadSizeWarning
          )} ${maxUpload} MB.`
        );
        return false; // Prevent the file from being uploaded
      }

      setFileList([...fileList, file]);

      return false;
    },
    fileList,
  };

  const handleUpload = async () => {
    if (fileList.length === 0) {
      return;
    }
    try {
      dispatch(setLoading(true));

      const response = await apiService.post(
        endpointConfig.pollPresignedUploadUrl,
        {
          type: E_TYPE_MEDIA._IMAGE,
          extension: fileList[0].name.split('.').pop(),
          folder: E_FOLDER_MEDIA._DEFAULT,
        }
      );

      const { url, key } = response.data.data;

      const { status } = await axios.put(url, fileList[0], {
        headers: {
          'Content-Type': fileList[0].type,
        },
      });

      if (status === 200) {
        saveDefaulImage(key);
      }
    } finally {
      dispatch(setLoading(false));
    }
  };

  const saveDefaulImage = async (key: any) => {
    dispatch(setLoading(true));

    try {
      const reqData = {
        key: key,
      };
      const { data } = await apiService.post(
        endpointConfig.defaultImages,
        reqData
      );
      if (data?.success) {
        toast.success(
          translate(
            locale_keys.voteManagement.imageDefault.toastr.createSuccessful
          )
        );
        setFileList([]);
        getListDefaultImage();
      }
    } finally {
      setTimeout(() => {
        dispatch(setLoading(false));
      }, 250);
    }
  };

  const getListDefaultImage = async () => {
    dispatch(setLoading(true));

    try {
      const { data } = await apiService.get(endpointConfig.defaultImages);
      setImages(data?.data);
    } finally {
      setTimeout(() => {
        dispatch(setLoading(false));
      }, 250);
    }
  };

  const onDeleteDefaultImage = async (key: any) => {
    await confirmModal({
      title: translate(locale_keys.voteManagement.imageDefault.confirm.delete),
      content: translate(
        locale_keys.voteManagement.imageDefault.confirm.contentDelete
      ),
      okText: translate(locale_keys.filter.yes),
      cancelText: translate(locale_keys.filter.cancel),
      // icon: <InfoCircleFilled />,
    });
    doDeleteDefaultImage(key);
  };

  const doDeleteDefaultImage = async (key: any) => {
    try {
      const reqData = {
        key: key,
      };
      const { data } = await apiService.patch(
        endpointConfig.defaultImages,
        reqData
      );

      if (data?.success) {
        toast.success(
          translate(
            locale_keys.voteManagement.imageDefault.toastr.deleteSuccessful
          )
        );
        getListDefaultImage();
      }
    } finally {
      setTimeout(() => {
        dispatch(setLoading(false));
      }, 250);
    }
  };

  const location = useLocation();
  useEffect(() => {
    getListDefaultImage();
  }, [location]);

  return (
    <div className='d-flex mh-100'>
      <div style={{ width: '27%', marginRight: '30px' }}>
        <div className='filter'>
          <div className='filter__title'>
            {translate(locale_keys.voteManagement.imageDefault.title)}
          </div>
          <div className='filter__form'>
            <div className='mr-20'>
              <Upload {...props} accept='.png,.jpg,.jpeg'>
                {fileList.length === 0 ? (
                  <Button icon={<UploadOutlined />}>
                    {translate(
                      locale_keys.voteManagement.imageDefault.selectFile
                    )}
                  </Button>
                ) : null}
              </Upload>
              <Button
                className='form-btn form-btn--black'
                onClick={handleUpload}
                disabled={fileList.length === 0}
                loading={uploading}
                style={{ marginTop: 16 }}
              >
                {uploading
                  ? translate(locale_keys.voteManagement.imageDefault.uploading)
                  : translate(
                      locale_keys.voteManagement.imageDefault.startUpload
                    )}
              </Button>
            </div>
          </div>
        </div>
      </div>

      <div className='category d-flex flex-column' style={{ width: '100%' }}>
        <div className='category__heading'>
          {translate(locale_keys.voteManagement.imageDefault.listImage)}
        </div>
        <div className='image__group d-flex flex-wrap'>
          {images && images.length > 0
            ? images.map((item, index) => (
                <div key={index} className='image__group-item'>
                  <div className='image__group-item__icon'>
                    <CloseCircleTwoTone
                      onClick={() => onDeleteDefaultImage(item)}
                    />
                  </div>
                  <div className='image__group-item__image'>
                    <Image src={process.env.REACT_APP_PUBLIC_S3_URL + item} />
                  </div>
                </div>
              ))
            : null}
        </div>
      </div>
    </div>
  );
};

export default DefaultImage;
