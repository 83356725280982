import { useNavigate } from 'react-router-dom';

import { translate } from '@/helpers/functions/language.helper';
import { MenuItemInterface } from '@/helpers/types/menu.types';

const MenuItem = ({
  item,
  currentPath,
}: {
  item: MenuItemInterface;
  currentPath: string;
}) => {
  const navigate = useNavigate();

  const goToMenu = (path: any) => {
    navigate(path);
  };

  return (
    <div
      className={
        'menu__list-item d-flex align-items-center ' +
        (currentPath === item.path ? 'menu__list-item--active' : '')
      }
      onClick={() => goToMenu(item.path)}
    >
      <img
        src={currentPath === item.path ? item.iconBold : item.icon}
        alt='icon-adminAccount'
      />
      <span className='ml-10'>{translate(item.localeKeys)}</span>
    </div>
  );
};

export default MenuItem;
