import { ErrorMessage } from '@hookform/error-message';
import classNames from 'classnames';
import React from 'react';
import { useForm } from 'react-hook-form';
import { FiX } from 'react-icons/fi';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import '@/styles/pages/forgot-password.scss';
import '@/styles/pages/login.scss';

import useDocumentTitle from '@/hooks/useDocumentTitle';

import Logo from '@/components/Logo';

import { useAppDispatch, useAppSelector } from '@/stores';
import { forgotPassword, setLoading } from '@/stores/features/auth.slice';

import { E_PATHS } from '@/helpers/constants/common.constant';
import { translate } from '@/helpers/functions/language.helper';
import { locale_keys } from '@/helpers/locales/locale.key';
import regex from '@/helpers/regex';
import apiService from '@/services/api/api.services';
import endpointConfig from '@/services/api/endpoint.config';

const ResetPassword = () => {
  useDocumentTitle(translate(locale_keys.heading.resetPwd));
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm();

  const { email, otp } = useAppSelector((state) => state.auth.forgotPassword);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();

  const onResetPassword = (data: any) => {
    dispatch(setLoading(true));
    const { password } = data;

    apiService
      .post(endpointConfig.forgotPassword + '?isAdmin=true', {
        password,
        email,
        otp,
      })
      .then(() => {
        toast.success(translate(locale_keys.auth.toast.resetPwdSuccessful));
        dispatch(forgotPassword({ email: '', otp: '' }));
        navigate(E_PATHS._LOGIN);
      })
      .catch(() => {
        navigate(E_PATHS._FORGOT_PASSWORD_CONFIRM_OTP);

        dispatch(forgotPassword({ email, otp: '' }));
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };

  const backToLoginPage = () => {
    navigate(E_PATHS._LOGIN);
  };

  React.useEffect(() => {
    if (
      (email === '' || otp === '') &&
      !location.pathname.includes(E_PATHS._FORGOT_PASSWORD as string)
    ) {
      navigate(E_PATHS._FORGOT_PASSWORD);
    }
  }, [email, otp, navigate, location]);

  return (
    <div className='forgot-password'>
      <div className='forgot-password__background forgot-password__background--secondary'>
        <Logo />
      </div>

      <div onClick={backToLoginPage} className='forgot-password__quit'>
        <FiX height={72} width={72} />
      </div>

      <div className='forgot-password__container'>
        <form onSubmit={handleSubmit(onResetPassword)}>
          {/* form */}
          <h3 className='forgot-password__welcome'>
            {translate(locale_keys.auth.welcomTitle.forgotPassword)}
          </h3>
          <div className='forgot-password__form'>
            <label htmlFor='email' className='d-none'>
              {translate(locale_keys.auth.welcomTitle.resetPassword)}
            </label>
            <input
              type='password'
              id='password'
              placeholder={translate(locale_keys.auth.placeholder.password)}
              className={classNames(['login__form-input mt-4'], {
                ['--error']: errors['password'],
              })}
              {...register('password', {
                required: translate(locale_keys.formRule.required),
                validate: {
                  length: (value) =>
                    (value.length >= 6 && value.length <= 30) ||
                    translate(locale_keys.formRule.passwordNotLong),
                  strongPassword: (value) =>
                    regex.password(value) ||
                    translate(locale_keys.formRule.passwordNotStrong),
                },
              })}
            />
            <ErrorMessage
              errors={errors}
              name='password'
              render={({ message }) => <p className='text--error'>{message}</p>}
            />

            <input
              type='password'
              id='confirmPassword'
              placeholder={translate(
                locale_keys.auth.placeholder.confirmPassword
              )}
              className={classNames(['login__form-input mt-4'], {
                ['--error']: errors['confirmPassword'],
              })}
              {...register('confirmPassword', {
                required: translate(locale_keys.formRule.required),
                validate: (value) =>
                  value === getValues('password') ||
                  translate(locale_keys.formRule.passwordNotMatch),
              })}
            />
            <ErrorMessage
              errors={errors}
              name='confirmPassword'
              render={({ message }) => <p className='text--error'>{message}</p>}
            />

            <button
              type='submit'
              className='login__form-btn app-btn--black-21 mt-30'
            >
              {translate(locale_keys.auth.button.resetPassword)}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
