import { DeleteOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Drawer, Row, Space } from 'antd';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { DescriptionItem } from '@/components/DescriptionItem';
import { confirmModal } from '@/components/ShowConfirm';

import { useAppDispatch } from '@/stores';
import { setLoading } from '@/stores/features/auth.slice';

import { getDateFromDateString } from '@/helpers/functions/datetime.helper';
import {
  translate,
  translateChartLegend,
} from '@/helpers/functions/language.helper';
import { locale_keys } from '@/helpers/locales/locale.key';
import apiService from '@/services/api/api.services';
import endpointConfig from '@/services/api/endpoint.config';

interface DetailDrawerProps {
  selectedId: string;
  open: boolean;
  onCloseDetailDrawer: () => void;
  onRefresh: () => void;
}

const DetailDrawer = ({
  selectedId,
  open,
  onCloseDetailDrawer,
  onRefresh,
}: DetailDrawerProps) => {
  const dispatch = useAppDispatch();

  const [profile, setProfile] = useState<any>();

  const getDetailAdmin = async (selectedId: string) => {
    dispatch(setLoading(true));

    try {
      const { data } = await apiService.get(
        `${endpointConfig.getAllAdmins}/${selectedId}`
      );

      setProfile(data?.data);
    } finally {
      setTimeout(() => {
        dispatch(setLoading(false));
      }, 250);
    }
  };

  const deleteAdminAccount = async () => {
    try {
      await confirmModal({
        title: translate(locale_keys.confirm.deleteAdminTitle),
        content: translate(locale_keys.confirm.deleteAdminDesc),
        okText: translate(locale_keys.filter.delete),
        cancelText: translate(locale_keys.filter.cancel),
        // icon: <InfoCircleFilled />,
      });

      doDelete();
    } catch (e) {
      // User clicked Cancel or closed the modal
    }
  };

  const doDelete = async () => {
    dispatch(setLoading(true));

    try {
      const { data } = await apiService.delete(
        `${endpointConfig.actionAccountAdmin}/${selectedId}`
      );

      if (data?.success) {
        onCloseDetailDrawer();
        toast.success(translate(locale_keys.user.toast.deleteAdminSuccess));
        onRefresh();
      }
    } finally {
      setTimeout(() => {
        dispatch(setLoading(false));
      }, 250);
    }
  };

  useEffect(() => {
    if (selectedId !== '') {
      getDetailAdmin(selectedId);
    }
  }, [selectedId]);

  return (
    <>
      <Drawer
        title={translate(locale_keys.user.modal.detailAdminTitle)}
        width={640}
        placement='right'
        onClose={onCloseDetailDrawer}
        open={open}
        bodyStyle={{ paddingBottom: 80 }}
        extra={
          profile?.role === 'SUPER_ADMIN' ? null : (
            <Space>
              <Button
                type='dashed'
                danger
                icon={<DeleteOutlined />}
                className='d-flex align-items-center'
                onClick={deleteAdminAccount}
              >
                {translate(locale_keys.filter.delete)}
              </Button>
            </Space>
          )
        }
      >
        <p className='site-description-item-profile-p'>
          {translate(locale_keys.user.modal.personalInfor)}
        </p>
        <Row>
          <Col span={12}>
            <DescriptionItem
              title={translate(locale_keys.user.username)}
              content={profile?.username}
            />
          </Col>
          <Col span={12}>
            <DescriptionItem
              title={translate(locale_keys.user.fullName)}
              content={profile?.fullName}
            />
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <DescriptionItem
              title={translate(locale_keys.user.email)}
              content={profile?.email}
            />
          </Col>
          <Col span={12}>
            <DescriptionItem
              title={translate(locale_keys.user.role)}
              content={translateChartLegend(profile?.role, locale_keys.user)}
            />
          </Col>
        </Row>

        <Divider />

        <Row>
          <Col span={12}>
            <DescriptionItem
              title={translate(locale_keys.user.createAt)}
              content={getDateFromDateString(
                profile?.created_at,
                'dd-mm-yyyy',
                true
              )}
            />
          </Col>
        </Row>
      </Drawer>
    </>
  );
};

export default DetailDrawer;
