import { Button, Input, Select, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import * as _ from 'lodash';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';

import useDocumentTitle from '@/hooks/useDocumentTitle';

import TablePagination from '@/components/Pagination';

import { useAppDispatch } from '@/stores';
import { setLoading } from '@/stores/features/auth.slice';

import {
  DEFAULT_LIMIT,
  DEFAULT_SIZE,
  MAX_TITLE_LENGTH,
} from '@/helpers/constants/common.constant';
import {
  REPORT_TYPES,
  REPORT_TYPES_KEY,
} from '@/helpers/constants/report.constant';
import { getDateFromDateString } from '@/helpers/functions/datetime.helper';
import { translate } from '@/helpers/functions/language.helper';
import { sorterTable } from '@/helpers/functions/table.helper';
import { locale_keys } from '@/helpers/locales/locale.key';
import {
  PaginationDefault,
  PaginationInterface,
} from '@/helpers/types/pagination.types';
import { ReportModel } from '@/helpers/types/report.types';
import apiService from '@/services/api/api.services';
import endpointConfig from '@/services/api/endpoint.config';

import ReportDetailDrawer from './detail.drawer';

const Report = () => {
  useDocumentTitle(translate(locale_keys.menu.report));
  const dispatch = useAppDispatch();
  const location = useLocation();

  const [reportData, setReportData] = useState([]);
  const [openDetailDrawer, setOpenDetailDrawer] = useState(false);
  const [selectedId, setselectedId] = useState('');
  const [resetDrawer, setResetDrawer] = useState<boolean>(false);

  const reasonOptions = (Object.keys(REPORT_TYPES) as REPORT_TYPES_KEY[]).map(
    (key: REPORT_TYPES_KEY) => {
      return {
        label: translate(locale_keys.report.reportType[key]),
        value: key !== 'ALL' ? key : '',
      };
    }
  );

  const defaultValues = {
    keyword: '',
    type: '',
  };

  const { handleSubmit, control, reset } = useForm({
    defaultValues,
  });

  const [searchValues, setSearchValues] = useState({
    keyword: '',
    type: '',
    limit: DEFAULT_LIMIT,
    page: DEFAULT_SIZE,
  });
  const [pagination, setPagination] =
    useState<PaginationInterface>(PaginationDefault);

  const columns: ColumnsType<ReportModel> = [
    {
      title: translate(locale_keys.common.title),
      dataIndex: 'pollId',
      key: 'title',
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a, b) => sorterTable(a?.pollId?.title, b?.pollId?.title),
      width: 350,
      render: ({ title }) => {
        if (title?.length > MAX_TITLE_LENGTH) {
          return `${title.substring(0, MAX_TITLE_LENGTH)}...`;
        }
        return title;
      },
    },
    {
      title: translate(locale_keys.report.type),
      dataIndex: 'type',
      key: 'type',
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a, b) => sorterTable(a.type, b.type),
      render: (title: REPORT_TYPES_KEY) =>
        translate(locale_keys.report.reportType[title]),
    },
    {
      title: translate(locale_keys.report.reporter),
      dataIndex: 'userId',
      key: 'fullName',
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a, b) => sorterTable(a.userId.fullName, b.userId.fullName),
      render: ({ fullName }) => fullName,
    },
    {
      title: translate(locale_keys.report.reporterEmail),
      dataIndex: 'userId',
      key: 'email',
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a, b) => sorterTable(a.userId.email, b.userId.email),
      render: ({ email }) => email,
    },
    {
      title: translate(locale_keys.user.createAt),
      dataIndex: 'created_at',
      key: 'created_at',
      render: (date: string) => getDateFromDateString(date, 'dd-mm-yyyy', true),
    },
    {
      title: translate(locale_keys.user.action),
      key: 'action',
      width: 150,
      render: (_, record) => (
        <div
          className='table__action--default'
          onClick={() => onOpenDetailDrawer(record._id)}
        >
          <span className='mr-8'>
            {translate(locale_keys.feedbackManagement.inquiry.viewDetail)}
          </span>
          <span>
            <i className='bi bi-box-arrow-up-right'></i>
          </span>
        </div>
      ),
    },
  ];

  const getListReports = async () => {
    dispatch(setLoading(true));

    const params = _.pickBy(searchValues, _.identity);

    try {
      const { data } = await apiService.get(endpointConfig.report, {
        params,
      });

      setReportData(data.data);
      setPagination(data.paginate);
    } finally {
      setTimeout(() => {
        dispatch(setLoading(false));
      }, 250);
    }
  };

  const handlePaginationChange = (page: number, pageSize: number) => {
    if (pageSize !== pagination.pageSize) {
      setPagination({
        ...PaginationDefault,
        pageSize: pageSize,
      });
      page = 1;
    } else {
      setPagination((prev) => ({
        ...prev,
        currentPage: page,
      }));
    }

    setSearchValues((prev) => ({
      ...prev,
      limit: pageSize,
      page,
    }));
  };

  const onSubmit = async (data: any) => {
    setSearchValues((prev) => ({
      ...prev,
      ...data,
    }));
  };

  const onOpenDetailDrawer = (_id: string) => {
    setResetDrawer(!resetDrawer);
    setselectedId(_id);
    setOpenDetailDrawer(true);
  };

  const onClearFilter = () => {
    reset(defaultValues);
    setSearchValues((prev) => ({
      ...prev,
      ...defaultValues,
      limit: DEFAULT_LIMIT,
      page: DEFAULT_SIZE,
    }));
  };

  const handleOnCloseDetailDrawer = () => {
    setOpenDetailDrawer(false);
  };

  const handleOnRefresh = () => {
    getListReports();
  };

  useEffect(() => {
    getListReports();
  }, [searchValues, location]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='filter'>
          <div className='filter__title'>
            {translate(locale_keys.filter.filterBy)}
          </div>
          <div className='filter__form d-flex'>
            <div className='mr-20' style={{ width: '40%' }}>
              <label
                htmlFor='keyword'
                className='form-label filter__form-label'
              >
                {translate(locale_keys.report.reporter)}
              </label>
              <Controller
                control={control}
                name='keyword'
                defaultValue=''
                render={({ field }) => (
                  <Input
                    size='large'
                    value={field.value}
                    placeholder={translate(
                      locale_keys.report.keywordPlaceholder
                    )}
                    onChange={(e: any) => field.onChange(e.target.value)}
                  />
                )}
              />
            </div>

            <div className='mr-20'>
              <label htmlFor='status' className='form-label filter__form-label'>
                {translate(locale_keys.filter.status)}
              </label>
              <div>
                <Controller
                  name='type'
                  control={control}
                  defaultValue={reasonOptions[0].value}
                  render={({ field }) => (
                    <Select
                      size='large'
                      defaultValue={field.value}
                      value={field.value}
                      onChange={(value) => field.onChange(value)}
                      style={{ width: 250 }}
                      options={reasonOptions}
                    />
                  )}
                />
              </div>
            </div>

            <div className='ms-auto d-flex align-items-end'>
              <Button className='form-btn' onClick={onClearFilter}>
                {translate(locale_keys.filter.clear)}
              </Button>
              <Button
                className='form-btn form-btn--black ml-20'
                htmlType='submit'
              >
                {translate(locale_keys.filter.apply)}
              </Button>
            </div>
          </div>
        </div>
      </form>

      <div className='table mt-24'>
        <div className='table__heading d-flex justify-content-between'>
          <div className='table__heading-title'>
            {translate(locale_keys.report.listReport)}
          </div>
        </div>
        <div className='table__grid'>
          <Table
            rowKey='_id'
            scroll={{ y: 400 }}
            // loading={true}
            columns={columns}
            dataSource={reportData}
            pagination={false}
          />
          <TablePagination
            currentPage={pagination.currentPage}
            total={pagination.total}
            pageSize={pagination.pageSize}
            onPageChange={handlePaginationChange}
          ></TablePagination>
        </div>
      </div>

      <ReportDetailDrawer
        resetDrawer={resetDrawer}
        selectedId={selectedId}
        open={openDetailDrawer}
        onCloseDetailDrawer={handleOnCloseDetailDrawer}
        onRefresh={handleOnRefresh}
      ></ReportDetailDrawer>
    </>
  );
};

export default Report;
