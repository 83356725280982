import { Image } from 'antd';
import classNames from 'classnames';
import React from 'react';
import { GiQueenCrown } from 'react-icons/gi';
import { IoMdRadioButtonOff } from 'react-icons/io';
import '@/styles/components/voting/live-view/judgement.scss';

import VideoPlayer from '@/components/VideoPlayer';

import { E_TYPE_MEDIA } from '@/helpers/constants/voting.constant';
import { translate } from '@/helpers/functions/language.helper';
import { locale_keys } from '@/helpers/locales/locale.key';

import CompareBar from './CompareBar';

const Judgement = ({ options, attachmentUrl, attachmentType }: any) => {
  return (
    <div className='judgement__options w-full'>
      <div className='judgement__options-media'>
        {attachmentType === E_TYPE_MEDIA._IMAGE ? (
          <Image
            src={process.env.REACT_APP_PUBLIC_S3_URL + attachmentUrl}
            height={'100%'}
            width={'100%'}
            alt=''
          />
        ) : (
          <VideoPlayer
            url={process.env.REACT_APP_PUBLIC_S3_URL + attachmentUrl}
            view={true}
          />
        )}
      </div>
      <div className='judgement__options-content d-flex flex-column justify-content-start align-items-start'>
        {options.map((option: any, index: number) => (
          <div
            className='judgement__options-content-item d-flex flex-column align-items-start w-full'
            key={index}
          >
            <div
              className={classNames([
                'judgement__options-content-item-option d-flex flex-row align-items-center w-full',
                {
                  'is-first': index === 0,
                },
              ])}
            >
              {<IoMdRadioButtonOff size={20} />}
              <span>{option.title}</span>
            </div>
            <div
              className='judgement__options-content-item-calc d-flex flex-row align-items-center'
              style={{
                color:
                  options.reduce((prev: any, current: any) =>
                    prev.votes > current.votes ? prev : current
                  )._id === option._id
                    ? '#FFB300'
                    : 'rgba(0, 0, 0, 0.48',
              }}
            >
              <GiQueenCrown size={40} />
              <span>
                {option.votes}{' '}
                {translate(
                  locale_keys.voteManagement.pollList.detail.peopleChoice
                )}
              </span>
            </div>
          </div>
        ))}
        <div className='judgement__options-content-rate w-full'>
          <CompareBar options={options} />
        </div>
      </div>
    </div>
  );
};

export default Judgement;
