import { Pagination } from 'antd';

interface TablePaginationProps {
  currentPage: number;
  total: number;
  pageSize: number;
  onPageChange: (currentPage: number, pageSize: number) => void;
}

const TablePagination = ({
  currentPage,
  total,
  pageSize,
  onPageChange,
}: TablePaginationProps) => {
  return (
    <div className='table__grid-pagination'>
      <Pagination
        showSizeChanger
        showTotal={(total, range) =>
          `${range[0]}-${range[1]} of ${total} items`
        }
        current={currentPage}
        total={total}
        defaultPageSize={pageSize}
        onChange={onPageChange}
        // nextIcon='Next'
        // prevIcon='Previous'
      />
    </div>
  );
};

export default TablePagination;
