import React from 'react';

const CompareBar = ({ options }: any) => {
  const totalVotes = options.reduce((acc: number, option: any) => {
    return acc + option.votes;
  }, 0);

  if (totalVotes === 0 || !totalVotes) {
    return (
      <div className='d-flex flex-row align-items-center justify-content-center w-full h-full'>
        {options.map((option: any, index: number) => (
          <div
            key={index}
            className='h-full d-flex flex-row align-items-center justify-content-center'
            style={{
              width: '50%',
              background: index === 0 ? '#F4F4F2' : '#FFFFFF',
              borderRadius: index === 0 ? '0 0 0 0.5rem' : '0 0 0.5rem 0',
            }}
          >
            50%
          </div>
        ))}
      </div>
    );
  }

  return (
    <div className='d-flex flex-row align-items-center justify-content-center w-full h-full'>
      {options.map((option: any, index: number) => (
        <div
          key={index}
          className='h-full d-flex flex-row align-items-center justify-content-center'
          style={{
            width: `${(option.votes / totalVotes) * 100}%`,
            background: index === 0 ? '#F4F4F2' : '#FFFFFF',
            borderRadius:
              index === 0 ? '0.5rem 0 0 0.5rem' : '0 0.5rem 0.5rem 0',
            visibility:
              (option.votes / totalVotes) * 100 === 0 ? 'hidden' : 'visible',
            color: index === 0 ? '#2E7D32' : '#1565C0',
            fontWeight: 600,
          }}
        >
          {((option.votes / totalVotes) * 100).toFixed(0)}%
        </div>
      ))}
    </div>
  );
};

export default CompareBar;
