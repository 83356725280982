import { CheckOutlined, StopOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Drawer, Row, Space } from 'antd';
import DOMPurify from 'dompurify';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { DescriptionItem } from '@/components/DescriptionItem';
import { confirmModal } from '@/components/ShowConfirm';

import { useAppDispatch } from '@/stores';
import { setLoading } from '@/stores/features/auth.slice';

import {
  translate,
  translateChartLegend,
} from '@/helpers/functions/language.helper';
import { locale_keys } from '@/helpers/locales/locale.key';
import { ReportModel } from '@/helpers/types/report.types';
import apiService from '@/services/api/api.services';
import endpointConfig from '@/services/api/endpoint.config';

interface ReportDetailDrawerProps {
  resetDrawer: boolean;
  selectedId: string;
  open: boolean;
  onCloseDetailDrawer: () => void;
  onRefresh: () => void;
}

const ReportDetailDrawer = ({
  resetDrawer,
  selectedId,
  open,
  onCloseDetailDrawer,
  onRefresh,
}: ReportDetailDrawerProps) => {
  const dispatch = useAppDispatch();
  const [report, setReport] = useState<ReportModel>();

  const [userStatus, setUserStatus] = useState('');
  const [userLocation, setUserLocation] = useState('');
  const [role, setRole] = useState('');
  const [type, setType] = useState('');

  const getDetailReport = async (selectedId: string) => {
    dispatch(setLoading(true));
    try {
      const { data } = await apiService.get(
        `${endpointConfig.report}/${selectedId}`
      );
      setReport(data);
      setUserStatus(data?.pollId?.status);
      setUserLocation(data?.pollId?.location);
      setRole(data?.userId?.role);
      setType(data?.pollId?.type);
    } finally {
      setTimeout(() => {
        dispatch(setLoading(false));
      }, 250);
    }
  };

  const onBlockPoll = async () => {
    try {
      await confirmModal({
        title: translate(locale_keys.report.detail.confirm.block),
        content: translate(locale_keys.report.detail.confirm.contentBlock),
        okText: translate(locale_keys.filter.yes),
        cancelText: translate(locale_keys.filter.cancel),
        // icon: <InfoCircleFilled />,
      });

      changePollStatus('BLOCKED');
    } catch (e) {
      // User clicked Cancel or closed the modal
    }
  };

  const onActivePoll = async () => {
    try {
      await confirmModal({
        title: translate(locale_keys.report.detail.confirm.active),
        content: translate(locale_keys.report.detail.confirm.contentActive),
        okText: translate(locale_keys.filter.active),
        cancelText: translate(locale_keys.filter.cancel),
        // icon: <InfoCircleFilled />,
      });

      changePollStatus('ACTIVE');
    } catch (e) {
      // User clicked Cancel or closed the modal
    }
  };

  const changePollStatus = async (type: 'BLOCKED' | 'ACTIVE') => {
    dispatch(setLoading(true));

    try {
      const res = await apiService.patch(
        `${endpointConfig.updateStatusPoll}/${report?.pollId._id}`,
        {
          status: type,
        }
      );
      if (res?.data?.success) {
        onCloseDetailDrawer();
        toast.success(
          `${
            type === 'BLOCKED'
              ? translate(locale_keys.report.detail.block)
              : translate(locale_keys.filter.active)
          } ${translate(locale_keys.report.detail.toastr.successful)}`
        );
        onRefresh();
      }
    } finally {
      setTimeout(() => {
        dispatch(setLoading(false));
      }, 250);
    }
  };

  const onDeleteReport = async () => {
    try {
      await confirmModal({
        title: translate(locale_keys.report.detail.confirm.delete),
        content: translate(locale_keys.report.detail.confirm.contentDelete),
        okText: translate(locale_keys.filter.yes),
        cancelText: translate(locale_keys.filter.no),
        // icon: <InfoCircleFilled />,
      });

      doDeleteReport();
    } catch (e) {
      // User clicked Cancel or closed the modal
    }
  };

  const doDeleteReport = async () => {
    dispatch(setLoading(true));

    try {
      const res = await apiService.delete(
        `${endpointConfig.report}/${selectedId}`
      );
      if (res?.data?.success) {
        onCloseDetailDrawer();
        toast.success(
          translate(locale_keys.report.detail.toastr.deleteSuccessful)
        );
        onRefresh();
      }
    } finally {
      setTimeout(() => {
        dispatch(setLoading(false));
      }, 250);
    }
  };

  useEffect(() => {
    if (selectedId) {
      getDetailReport(selectedId);
    }
  }, [resetDrawer]);

  return (
    <>
      <Drawer
        title={translate(locale_keys.report.detail.title)}
        width={640}
        placement='right'
        onClose={onCloseDetailDrawer}
        open={open}
        bodyStyle={{ paddingBottom: 80 }}
        extra={
          <>
            <Space>
              {report?.pollId?.status === 'ACTIVE' ? (
                <Button
                  type='dashed'
                  danger
                  icon={<StopOutlined />}
                  className='d-flex align-items-center'
                  onClick={onBlockPoll}
                >
                  {translate(locale_keys.report.detail.blockPoll)}
                </Button>
              ) : report?.pollId?.status === 'BLOCKED' ? (
                <Button
                  type='dashed'
                  icon={<CheckOutlined />}
                  className='d-flex align-items-center text-active text-active--border'
                  onClick={onActivePoll}
                >
                  {translate(locale_keys.report.detail.activePoll)}
                </Button>
              ) : null}
              {selectedId !== '' ? (
                <Button
                  type='dashed'
                  danger
                  icon={<StopOutlined />}
                  className='d-flex align-items-center'
                  onClick={onDeleteReport}
                >
                  {translate(locale_keys.report.detail.deleteReport)}
                </Button>
              ) : null}
            </Space>
          </>
        }
      >
        <p className='site-description-item-profile-p'>
          {translate(locale_keys.report.detail.reporterInfo)}
        </p>
        <Row>
          <Col span={12}>
            <DescriptionItem
              title={translate(locale_keys.saleManagement.username)}
              content={report?.userId?.username}
            />
          </Col>
          <Col span={12}>
            <DescriptionItem
              title={translate(locale_keys.user.fullName)}
              content={report?.userId?.fullName}
            />
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <DescriptionItem
              title={translate(locale_keys.user.email)}
              content={report?.userId?.email}
            />
          </Col>
          <Col span={12}>
            <DescriptionItem
              title={translate(locale_keys.filter.role)}
              content={translateChartLegend(role, locale_keys.user)}
            />
          </Col>
        </Row>
        <Row>
          <DescriptionItem
            title={translate(locale_keys.report.detail.reason)}
            content={
              report?.type &&
              translateChartLegend(report?.type, locale_keys.report.reportType)
            }
          />
        </Row>
        <Divider />
        <p className='site-description-item-profile-p'>
          {translate(locale_keys.report.detail.pollInfo)}
        </p>
        <Row>
          <Col span={12}>
            <DescriptionItem
              title={translate(locale_keys.common.title)}
              content={report?.pollId?.title}
            />
          </Col>
          <Col span={12}>
            <DescriptionItem
              title={translate(locale_keys.sale.type)}
              content={translateChartLegend(type, locale_keys.pollType)}
            />
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <DescriptionItem
              title={translate(locale_keys.advertisement.location)}
              content={translateChartLegend(userLocation, locale_keys.location)}
            />
          </Col>
          <Col span={12}>
            <DescriptionItem
              title={translate(locale_keys.user.status)}
              content={
                <span
                  className={
                    'fw-600 ' +
                    (report?.pollId?.status === 'ACTIVE'
                      ? 'text-active'
                      : report?.pollId?.status === 'BLOCKED'
                      ? 'text-block'
                      : '')
                  }
                >
                  {translateChartLegend(userStatus, locale_keys.status)}
                </span>
              }
            />
          </Col>
        </Row>
        <Row>
          <DescriptionItem
            title={translate(locale_keys.advertisement.description)}
            content={
              <div
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(report?.pollId?.description ?? ''),
                }}
              />
            }
          />
        </Row>
        <Divider />
      </Drawer>
    </>
  );
};

export default ReportDetailDrawer;
