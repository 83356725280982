import { CheckOutlined, DeleteOutlined } from '@ant-design/icons';
import { ErrorMessage } from '@hookform/error-message';
import { Button, Col, Divider, Input, Tabs, TabsProps, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { MdAddCircle } from 'react-icons/md';
import { useLocation } from 'react-router';
import { toast } from 'react-toastify';
import '@/styles/pages/gift-configure.scss';

import { confirmModal } from '@/components/ShowConfirm';

import { useAppDispatch } from '@/stores';
import { setLoading } from '@/stores/features/auth.slice';

import { validateNonNegativeNumber } from '@/helpers/functions/form-validation.helper';
import { translate } from '@/helpers/functions/language.helper';
import { locale_keys } from '@/helpers/locales/locale.key';
import { GiftModel } from '@/helpers/types/exchange.types';
import apiService from '@/services/api/api.services';
import endpointConfig from '@/services/api/endpoint.config';

const MAXIMUM_WHEEL_GIFTS_ALLOW = 8;
interface Gift {
  _id?: string;
  title: string;
  rate: number;
}

interface FormState {
  gifts: Gift[];
}

const WheelSetting = () => {
  const dispatch = useAppDispatch();
  const [tab, setTab] = useState('OTHER');
  const [data, setData] = useState<GiftModel[]>([]);
  const [deletedGifts, setDeletedGifts] = useState<string[]>([]);
  const [pointSettings, setPointSettings] = useState<any>({});
  const [spinWheelPoint, setSpinWheelPoint] = useState<number>(0);

  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormState>({
    defaultValues: { gifts: data },
  });

  const { fields, append, remove } = useFieldArray<FormState>({
    control,
    name: 'gifts',
  });

  const location = useLocation();

  useEffect(() => {
    onRefresh();
  }, [tab, location]);

  useEffect(() => {
    reset({ gifts: data });
  }, [data, data?.length]);

  useEffect(() => {
    getPointSpinWheel();
  }, []);

  const getListGifts = async () => {
    dispatch(setLoading(true));

    try {
      const { data } = await apiService.get(endpointConfig.gift, {
        params: {
          type: 'WHEEL',
          location: tab,
        },
      });
      setData(data?.data);
    } finally {
      setTimeout(() => {
        dispatch(setLoading(false));
      }, 250);
    }
  };

  const getPointSpinWheel = async () => {
    dispatch(setLoading(true));

    try {
      const { data } = await apiService.get(endpointConfig.settings);
      setPointSettings(data?.data?.polls?.configurations?.point);
      setSpinWheelPoint(data?.data?.polls?.configurations?.point?.spinWheel);
    } finally {
      setTimeout(() => {
        dispatch(setLoading(false));
      }, 250);
    }
  };

  const onUpdate = async (dataForm: FormState) => {
    try {
      await confirmModal({
        title: translate(locale_keys.pointAndGift.config.confirm.update),
        content: translate(
          locale_keys.pointAndGift.config.confirm.contentUpdate
        ),
        okText: translate(locale_keys.filter.yes),
        cancelText: translate(locale_keys.filter.no),
      });
      if (
        dataForm?.gifts?.reduce((prev, curr) => prev + curr.rate, 0) !== 100
      ) {
        toast.warning(
          translate(locale_keys.pointAndGift.config.toastr.totalWheelWarning)
        );
      } else {
        doUpdate(dataForm);
      }
    } catch (e) {
      // User clicked Cancel or closed the modal
    }
  };

  const doUpdate = async (dataForm: FormState) => {
    dispatch(setLoading(true));
    try {
      const updatedGifts = {
        updateGifts: dataForm?.gifts?.filter((gift) => gift._id),
        newGifts: dataForm?.gifts
          ?.filter((gift) => !gift._id)
          .map((gift) => ({
            ...gift,
            type: 'WHEEL',
            location: tab,
            point: 0,
          })),
        deletedGifts,
      };
      const res = await apiService.post(
        `${endpointConfig.gift}/updateMany`,
        updatedGifts
      );
      if (res?.data?.success) {
        toast.success(
          translate(locale_keys.pointAndGift.config.toastr.updateSuccessful)
        );
        onRefresh();
      }
    } finally {
      setTimeout(() => {
        dispatch(setLoading(false));
      }, 250);
    }
  };

  const onRefresh = () => {
    reset({ gifts: [] });
    getListGifts();
    setDeletedGifts([]);
  };

  const onAddGift = async () => {
    if (fields?.length >= MAXIMUM_WHEEL_GIFTS_ALLOW) {
      toast.warning(
        translate(locale_keys.pointAndGift.config.toastr.maxVoucherWarning, {
          count: MAXIMUM_WHEEL_GIFTS_ALLOW,
        })
      );
    } else {
      append({ title: '', rate: 0 });
    }
  };

  const onUpdateSpinWheel = async () => {
    try {
      await confirmModal({
        title: translate(locale_keys.pointAndGift.config.confirm.update),
        content: translate(
          locale_keys.pointAndGift.config.confirm.contentUpdateSpin
        ),
        okText: translate(locale_keys.filter.yes),
        cancelText: translate(locale_keys.filter.no),
      });

      doUpdateSpinWheel();
    } catch (e) {
      // User clicked Cancel or closed the modal
    }
  };

  const doUpdateSpinWheel = async () => {
    dispatch(setLoading(true));

    try {
      const res = await apiService.patch(`${endpointConfig.settings}`, {
        polls: {
          configurations: {
            point: {
              ...pointSettings,
              spinWheel: spinWheelPoint,
            },
          },
        },
      });
      if (res?.data?.success) {
        toast.success(
          translate(locale_keys.pointAndGift.config.toastr.updateSpinSuccessful)
        );
        getPointSpinWheel();
        onRefresh();
      }
    } finally {
      setTimeout(() => {
        dispatch(setLoading(false));
      }, 250);
    }
  };

  const onChangeTab = (key: string) => {
    setTab(key);
  };

  const onDelete = (index: number) => {
    if (fields?.[index]?._id) {
      setDeletedGifts([...deletedGifts, fields?.[index]._id as string]);
    }
    remove(index);
  };

  const giftList = () => {
    return (
      <div className='list'>
        <div className='title fw-600 mb-3 mt-2 fs-6'>
          {translate(locale_keys.pointAndGift.config.pointForSpin)}
        </div>
        <div className='d-flex justify-content-between'>
          <Col span={22}>
            <Input
              value={spinWheelPoint}
              onChange={(e) =>
                setSpinWheelPoint(parseInt(e?.target?.value ?? spinWheelPoint))
              }
              size='large'
              type='number'
            ></Input>
          </Col>
          {spinWheelPoint !== pointSettings?.spinWheel && (
            <Col className='d-flex align-items-center m-2 fs-4 icon icon_confirm'>
              <Tooltip title='Save'>
                <CheckOutlined onClick={onUpdateSpinWheel} />
              </Tooltip>
            </Col>
          )}
        </div>
        <div className='d-flex justify-content-between mt-4 mb-4'>
          <div className='title fw-600 fs-6'>
            {translate(locale_keys.pointAndGift.config.giftList)}
          </div>
        </div>
        <div className=''>
          {fields?.map((gift, index) => (
            <div key={gift.id}>
              <GiftItem
                keyName={`gifts.${index}`}
                control={control}
                errors={errors}
                register={register}
                onDelete={() => onDelete(index)}
              />
            </div>
          ))}
        </div>
      </div>
    );
  };

  const items: TabsProps['items'] = [
    {
      key: 'OTHER',
      label: translate(locale_keys.pointAndGift.config.other),
      children: giftList(),
    },
    {
      key: 'JAPAN',
      label: translate(locale_keys.pointAndGift.config.japan),
      children: giftList(),
    },
  ];

  return (
    <div className='container_wheel'>
      <div className='title fw-600 mb-2 fs-3'>
        {translate(locale_keys.pointAndGift.config.wheelSetting)}
      </div>
      <Tabs
        size='large'
        centered
        defaultActiveKey='OTHER'
        items={items}
        onChange={onChangeTab}
      />
      <div className='d-flex justify-content-center m-2 fs-4 icon'>
        <Tooltip title={translate(locale_keys.pointAndGift.config.add)}>
          <MdAddCircle
            onClick={onAddGift}
            size={36}
            style={{
              color: '#3279c7',
            }}
          />
        </Tooltip>
      </div>
      <Divider />
      <div className='d-flex justify-content-end'>
        <Button
          className='form-btn form-btn--black ml-20 me-3'
          htmlType='submit'
          onClick={handleSubmit(onUpdate)}
        >
          {translate(locale_keys.filter.update)}
        </Button>
        <Button className='form-btn' onClick={onRefresh}>
          {translate(locale_keys.filter.refresh)}
        </Button>
      </div>
    </div>
  );
};

const GiftItem = ({ onDelete, control, register, keyName, errors }: any) => {
  return (
    <div className='gift'>
      <div className='d-flex justify-content-between'>
        <Col span={17}>
          <div className='mb-1'>
            {translate(locale_keys.pointAndGift.config.giftDetail)}
          </div>
          <Controller
            name={`${keyName}.title`}
            control={control}
            render={({ field }) => (
              <Input
                size='large'
                value={field.value}
                placeholder={translate(locale_keys.common.title)}
                {...register(`${keyName}.title`, {
                  required: translate(
                    locale_keys.advertisement.detail.required.input
                  ),
                })}
                onChange={(e: any) => field.onChange(e.target.value)}
              />
            )}
          />
          <ErrorMessage
            errors={errors}
            name={`${keyName}.title`}
            render={({ message }) => <p className='text--error'>{message}</p>}
          />
        </Col>
        <Col span={5}>
          <div className='mb-1'>
            {translate(locale_keys.pointAndGift.config.rate)}
          </div>
          <Controller
            name={`${keyName}.rate`}
            control={control}
            rules={{ validate: validateNonNegativeNumber }}
            render={({ field }) => (
              <Input
                size='large'
                value={field.value}
                placeholder='%'
                {...register(`${keyName}.rate`, {
                  required: 'Required',
                  setValueAs: (v: string) => parseInt(v) || '',
                })}
                onChange={(e: any) => field.onChange(e.target.value)}
              />
            )}
          />
          <ErrorMessage
            errors={errors}
            name={`${keyName}.rate`}
            render={({ message }) => <p className='text--error'>{message}</p>}
          />
        </Col>
        <Col className={'d-flex align-items-md-end fs-4 m-2 icon icon_delete'}>
          <Tooltip title={translate(locale_keys.filter.delete)}>
            <DeleteOutlined onClick={onDelete} />
          </Tooltip>
        </Col>
      </div>
    </div>
  );
};

export default WheelSetting;
