import { StopOutlined } from '@ant-design/icons';
import { Button, Drawer, Space } from 'antd';
import { Descriptions } from 'antd';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import '@/styles/pages/ads.scss';

import { confirmModal } from '@/components/ShowConfirm';

import { useAppDispatch } from '@/stores';
import { setLoading } from '@/stores/features/auth.slice';

import { getDateFromDateString } from '@/helpers/functions/datetime.helper';
import { translate } from '@/helpers/functions/language.helper';
import { locale_keys } from '@/helpers/locales/locale.key';
import apiService from '@/services/api/api.services';
import endpointConfig from '@/services/api/endpoint.config';

interface InquiryDetailDrawerProps {
  resetDrawer: boolean;
  selectedId: string;
  open: boolean;
  onCloseDetailDrawer: () => void;
  onRefresh: () => void;
}

const InquiryDetailDrawer = ({
  resetDrawer,
  selectedId,
  open,
  onCloseDetailDrawer,
  onRefresh,
}: InquiryDetailDrawerProps) => {
  const dispatch = useAppDispatch();
  const [inquiry, setInquiry] = useState<any>();

  const getDetailInquiry = async (selectedId: string) => {
    dispatch(setLoading(true));

    try {
      const { data } = await apiService.get(
        `${endpointConfig.inquiries}/${selectedId}`
      );
      setInquiry(data?.data);
    } finally {
      setTimeout(() => {
        dispatch(setLoading(false));
      }, 250);
    }
  };

  const onDeleteNotice = async () => {
    try {
      await confirmModal({
        title: translate(
          locale_keys.feedbackManagement.inquiry.detail.confirm.delete
        ),
        content: translate(
          locale_keys.feedbackManagement.inquiry.detail.confirm.contentDelete
        ),
        okText: translate(locale_keys.filter.yes),
        cancelText: translate(locale_keys.filter.no),
        // icon: <InfoCircleFilled />,
      });

      doDelete();
    } catch (e) {
      // User clicked Cancel or closed the modal
    }
  };

  const doDelete = async () => {
    dispatch(setLoading(true));

    try {
      const res = await apiService.delete(
        `${endpointConfig.inquiries}/${selectedId}`
      );
      if (res?.data?.success) {
        onCloseDetailDrawer();
        toast.success(
          translate(
            locale_keys.feedbackManagement.inquiry.detail.toastr
              .deleteSuccessful
          )
        );
        onRefresh();
      }
    } finally {
      setTimeout(() => {
        dispatch(setLoading(false));
      }, 250);
    }
  };

  useEffect(() => {
    getDetailInquiry(selectedId);
  }, [resetDrawer]);

  return (
    <>
      <Drawer
        title={translate(locale_keys.feedbackManagement.inquiry.detail.title)}
        width={720}
        placement='right'
        onClose={onCloseDetailDrawer}
        open={open}
        bodyStyle={{ paddingBottom: 80 }}
        extra={
          <Space>
            {selectedId !== '' ? (
              <Button
                type='dashed'
                danger
                icon={<StopOutlined />}
                className='d-flex align-items-center'
                onClick={onDeleteNotice}
              >
                {translate(locale_keys.filter.delete)}
              </Button>
            ) : null}
          </Space>
        }
      >
        <Descriptions layout='vertical'>
          <Descriptions.Item
            label={translate(locale_keys.common.title)}
            span={3}
          >
            {inquiry?.title}
          </Descriptions.Item>
          <Descriptions.Item label={translate(locale_keys.user.createAt)}>
            {getDateFromDateString(inquiry?.created_at, 'dd-mm-yyyy', true)}
          </Descriptions.Item>
          <Descriptions.Item label={translate(locale_keys.user.email)} span={2}>
            {inquiry?.email}
          </Descriptions.Item>
          <Descriptions.Item
            label={translate(locale_keys.user.description)}
            span={3}
          >
            <textarea
              style={{ width: '100%', minHeight: '50px' }}
              rows={20}
              value={inquiry?.description}
              disabled
            ></textarea>
          </Descriptions.Item>
        </Descriptions>
      </Drawer>
    </>
  );
};

export default InquiryDetailDrawer;
