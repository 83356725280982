import { CheckOutlined, CloseOutlined, MoreOutlined } from '@ant-design/icons';
import { ErrorMessage } from '@hookform/error-message';
import { Dropdown, Input, MenuProps } from 'antd';
import classNames from 'classnames';
import { useContext, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { confirmModal } from '@/components/ShowConfirm';

import { useAppDispatch } from '@/stores';
import { setLoading } from '@/stores/features/auth.slice';

import { validateCategory } from '@/helpers/functions/form-validation.helper';
import { translate } from '@/helpers/functions/language.helper';
import { locale_keys } from '@/helpers/locales/locale.key';
import { CategoryModel } from '@/helpers/types/voting.types';
import apiService from '@/services/api/api.services';
import endpointConfig from '@/services/api/endpoint.config';

import { CategoryContext } from '.';

interface CategoryItemProps {
  category: CategoryModel;
  onRemoveSubCategory?: (parentId: any) => void;
  parentId?: string;
  isParent?: boolean;
}

export const CategoryItem = ({
  category,
  onRemoveSubCategory,
  parentId,
  isParent,
}: CategoryItemProps) => {
  const dispatch = useAppDispatch();
  const categoryContextValue = useContext(CategoryContext);

  const [readOnly, setReadOnly] = useState<boolean>(
    category?.newItem ? false : true
  );

  const defaultValues = {
    titleJp: category?.titleJp,
    titleEn: category?.titleEn,
  };

  const {
    handleSubmit,
    register,
    control,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues,
  });

  const onEditAction = () => {
    setReadOnly(false);
  };

  const onDeleteAction = async () => {
    await confirmModal({
      title: translate(locale_keys.voteManagement.category.confirm.delete),
      content: translate(
        locale_keys.voteManagement.category.confirm.contentDelete
      ),
      okText: translate(locale_keys.filter.yes),
      cancelText: translate(locale_keys.filter.no),
      // icon: <InfoCircleFilled />,
    });

    doDeleteCategory();
  };

  const doDeleteCategory = async () => {
    dispatch(setLoading(true));
    try {
      const { data } = await apiService.delete(
        endpointConfig.categories + `/${category?._id}`
      );
      if (data?.success) {
        toast.success(
          translate(locale_keys.voteManagement.category.toastr.deleteSuccessful)
        );
        categoryContextValue.resetState();
      }
    } finally {
      setTimeout(() => {
        dispatch(setLoading(false));
      }, 250);
    }
  };

  const onSubmit = async (dataForm: any) => {
    try {
      if (category?.newItem) {
        await confirmModal({
          title: translate(
            locale_keys.voteManagement.category.confirm.createSub
          ),
          content: translate(
            locale_keys.voteManagement.category.confirm.contentCreateSub
          ),
          okText: translate(locale_keys.filter.yes),
          cancelText: translate(locale_keys.filter.no),
          // icon: <InfoCircleFilled />,
        });

        doCreateCategory(dataForm);
      } else {
        await confirmModal({
          title: translate(locale_keys.voteManagement.category.confirm.update),
          content: translate(
            locale_keys.voteManagement.category.confirm.contentUpdate
          ),
          okText: translate(locale_keys.filter.yes),
          cancelText: translate(locale_keys.filter.no),
          // icon: <InfoCircleFilled />,
        });

        doUpdateCategory(dataForm);
      }
    } catch (e) {
      // User clicked Cancel or closed the modal
    }
  };

  const doCreateCategory = async (dataForm: any) => {
    dispatch(setLoading(true));
    try {
      const reqData = {
        titleJp: dataForm?.titleJp.trim(),
        titleEn: dataForm?.titleEn.trim(),
        parentCategory: parentId,
      };
      const { data } = await apiService.post(
        endpointConfig.categories,
        reqData
      );
      if (data?.success) {
        toast.success(
          translate(locale_keys.voteManagement.category.toastr.createSuccessful)
        );
        categoryContextValue.resetState();
        setReadOnly(true);
      }
    } finally {
      setTimeout(() => {
        dispatch(setLoading(false));
      }, 250);
    }
  };

  const doUpdateCategory = async (dataForm: any) => {
    dispatch(setLoading(true));
    try {
      const reqData = {
        titleJp: dataForm?.titleJp.trim(),
        titleEn: dataForm?.titleEn.trim(),
      };
      const { data } = await apiService.patch(
        endpointConfig.categories + `/${category?._id}`,
        reqData
      );
      if (data?.success) {
        toast.success(
          translate(locale_keys.voteManagement.category.toastr.updateSuccessful)
        );
        categoryContextValue.resetState();
        setReadOnly(true);
      }
    } finally {
      setTimeout(() => {
        dispatch(setLoading(false));
      }, 250);
    }
  };

  const cancelEditing = () => {
    if (category.newItem && onRemoveSubCategory) {
      onRemoveSubCategory(parentId);
    } else {
      setReadOnly(true);
      reset(defaultValues);
    }
  };

  const items: MenuProps['items'] = [
    {
      key: 'edit',
      label: (
        <div onClick={onEditAction}>
          {translate(locale_keys.voteManagement.category.confirm.update)}
        </div>
      ),
    },
    {
      key: 'delete',
      label: (
        <div onClick={onDeleteAction}>
          {translate(locale_keys.voteManagement.category.confirm.delete)}
        </div>
      ),
    },
  ];

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div
        className={
          'category__group-item d-flex justify-content-between ' +
          (isParent ? 'category__group-item--parent' : '')
        }
      >
        <div className='d-flex justify-content-between align-items-center'>
          <div className='category__group-item__title category__group-item__title mr-8'>
            <Controller
              name='titleJp'
              control={control}
              defaultValue=''
              rules={{ validate: validateCategory }}
              render={({ field }) => (
                <Input
                  value={field.value}
                  bordered={!readOnly}
                  readOnly={readOnly}
                  placeholder={
                    isParent
                      ? translate(
                          locale_keys.voteManagement.category.japanCategory
                        )
                      : translate(
                          locale_keys.voteManagement.category.japanSubCategory
                        )
                  }
                  className={classNames([''], {
                    ['--error']: errors['titleJp'],
                  })}
                  maxLength={80}
                  {...register('titleJp')}
                  onChange={(e: any) => field.onChange(e.target.value)}
                />
              )}
            />
            <ErrorMessage
              errors={errors}
              name='titleJp'
              render={({ message }) => <p className='text--error'>{message}</p>}
            />
          </div>
          <div className='mr-8'>/</div>
          <div className='category__group-item__title category__group-item__title'>
            <Controller
              name='titleEn'
              control={control}
              defaultValue=''
              rules={{ validate: validateCategory }}
              render={({ field }) => (
                <Input
                  value={field.value}
                  bordered={!readOnly}
                  readOnly={readOnly}
                  placeholder={
                    isParent
                      ? translate(
                          locale_keys.voteManagement.category.englishCategory
                        )
                      : translate(
                          locale_keys.voteManagement.category.englishSubCategory
                        )
                  }
                  className={classNames([''], {
                    ['--error']: errors['titleEn'],
                  })}
                  maxLength={80}
                  {...register('titleEn')}
                  onChange={(e: any) => field.onChange(e.target.value)}
                />
              )}
            />
            <ErrorMessage
              errors={errors}
              name='titleEn'
              render={({ message }) => <p className='text--error'>{message}</p>}
            />
          </div>
        </div>
        {readOnly ? (
          <Dropdown
            menu={{ items }}
            trigger={['click']}
            placement='bottomRight'
          >
            <div className='category__group-item__action'>
              <MoreOutlined
                style={{
                  fontSize: '24px',
                  fontWeight: 'bolder',
                  cursor: 'pointer',
                }}
              />
            </div>
          </Dropdown>
        ) : (
          <div className='category__group-item__action'>
            <button type='submit' className='icon-button-ant'>
              <CheckOutlined
                style={{
                  fontSize: '15px',
                  fontWeight: 'bolder',
                  cursor: 'pointer',
                }}
              />
            </button>

            <CloseOutlined
              style={{
                fontSize: '15px',
                fontWeight: 'bolder',
                cursor: 'pointer',
                marginLeft: '15px',
              }}
              onClick={cancelEditing}
            />
          </div>
        )}
      </div>
    </form>
  );
};

export default CategoryItem;
