import { useAppSelector } from '@/stores';

export const useCheckAuth = () => {
  const { access_token, refresh_token, user }: any = useAppSelector(
    (state) => state.auth.auth
  );

  const isAuth = access_token && refresh_token && user;

  return { isAuth, user };
};
