import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart as LineChartComponent,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { E_TYPE_POLL } from '@/helpers/constants/voting.constant';
import {
  translate,
  translateChartLegend,
} from '@/helpers/functions/language.helper';
import { locale_keys } from '@/helpers/locales/locale.key';

import useChartLegend from './useChartLegend';

interface BarChartProps {
  data: any[];
}
const defautStyleLines = {
  [E_TYPE_POLL._JUDGE]: {
    title: 'Judgement',
    color: '#1b0624',
  },
  [E_TYPE_POLL._ARTICLE]: {
    title: 'Article',
    color: '#3047f2',
  },
  [E_TYPE_POLL._RANK]: {
    title: 'Ranking',
    color: '#13d44d',
  },
  [E_TYPE_POLL._COMPARE]: {
    title: 'Comparison',
    color: '#d43313',
  },
  [E_TYPE_POLL._SURVEY]: {
    title: 'Questionary',
    color: '#FFBB28',
  },
};

const LineChart = ({ data }: BarChartProps) => {
  const [styleLines, setStyleLines] = useState(defautStyleLines);

  const [opacity, onActive, onReset] = useChartLegend({
    keys: Object.keys(styleLines),
  });

  const onMouseEnter = (o: any) => {
    onActive(o?.target?.id);
  };
  const location = useLocation();

  useEffect(() => {
    setStyleLines((preStyles) => ({
      ...preStyles,
      // [E_TYPE_POLL._JUDGE]: {
      //   ...preStyles[E_TYPE_POLL._JUDGE],
      //   title: translate(locale_keys.pollType.JUDGE),
      // },
      // [E_TYPE_POLL._ARTICLE]: {
      //   ...preStyles[E_TYPE_POLL._ARTICLE],
      //   title: translate(locale_keys.pollType.ARTICLE),
      // },
      // [E_TYPE_POLL._COMPARE]: {
      //   ...preStyles[E_TYPE_POLL._COMPARE],
      //   title: translate(locale_keys.pollType.COMPARE),
      // },
      // [E_TYPE_POLL._RANK]: {
      //   ...preStyles[E_TYPE_POLL._RANK],
      //   title: translate(locale_keys.pollType.RANK),
      // },
      // [E_TYPE_POLL._SURVEY]: {
      //   ...preStyles[E_TYPE_POLL._SURVEY],
      //   title: translate(locale_keys.pollType.SURVEY),
      // },
    }));
  }, [location]);

  const daysOfWeek: any = {};
  for (let i = 0; i < 7; i++) {
    const date = dayjs().subtract(i, 'day');
    const title = date.format('ddd');
    daysOfWeek[title] = date.format('DD-MM-YYYY');
  }

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      return (
        <div className='custom-tooltip'>
          <p style={{ fontWeight: 600, fontSize: '18px', marginBottom: '5px' }}>
            {label}, {daysOfWeek[label]}
          </p>
          {payload?.map((line: any) => (
            <p
              className='label mb-2'
              key={line?.dataKey}
              style={{ color: line.stroke }}
            >
              {`${
                styleLines[line.dataKey as keyof typeof styleLines]?.title
              } : ${line.payload[line.dataKey]} ${translate(
                locale_keys.dashboard.postDetail.post
              )}`}
            </p>
          ))}
        </div>
      );
    }

    return null;
  };

  const renderLegend = ({ onMouseEnter, onMouseLeave }: any) => {
    return (
      <>
        <div className='dashboard_chart_title float-start'>
          {translate(locale_keys.dashboard.postDetail.title)}
        </div>
        <div className='mx-auto d-flex justify-content-center'>
          {Object.entries(styleLines).map(([key, value]) => (
            <div
              className='mx-2'
              key={`${key}`}
              id={key}
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
            >
              <span
                className='line-dot'
                style={{ backgroundColor: value.color }}
              />
              {translateChartLegend(key, locale_keys.pollType)}
            </div>
          ))}
        </div>
      </>
    );
  };

  const CustomizedAxisTick = (props: any) => {
    const { x, y, payload } = props;

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={12}
          y={5}
          dy={16}
          textAnchor='end'
          fill='rgba(0, 0, 0, 0.4)'
          className='gray-text'
          fontSize={16}
        >
          {payload.value}
        </text>
      </g>
    );
  };

  const CustomizedYAxisTick = (props: any) => {
    const { x, y, payload } = props;

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={-12}
          y={-2}
          textAnchor='end'
          fill='rgba(0, 0, 0, 0.4)'
          fontSize={16}
        >
          {payload.value}
        </text>
      </g>
    );
  };

  return (
    <>
      <ResponsiveContainer width='100%' height='100%'>
        <LineChartComponent
          width={500}
          height={200}
          data={data}
          margin={{
            right: 20,
            left: 0,
            bottom: 10,
          }}
        >
          <CartesianGrid vertical={false} />
          <XAxis
            dataKey='title'
            tickLine={false}
            padding={{ left: 40, right: 20 }}
            tick={<CustomizedAxisTick />}
          />
          <YAxis
            tickLine={false}
            axisLine={false}
            allowDecimals={false}
            tick={<CustomizedYAxisTick />}
          />
          <Tooltip content={<CustomTooltip />} />
          <Legend
            content={renderLegend({ onMouseEnter, onMouseLeave: onReset })}
            verticalAlign='top'
            height={70}
          />
          {Object.entries(styleLines).map(([key, value]) => (
            <Line
              key={key}
              type='monotone'
              dataKey={key}
              stroke={value.color}
              strokeWidth={4}
              dot={false}
              opacity={opacity[key]}
            />
          ))}
        </LineChartComponent>
      </ResponsiveContainer>
    </>
  );
};

export default LineChart;
