import {
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Modal,
  Row,
  Table,
} from 'antd';
import { useEffect, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import '@/styles/pages/package.scss';

import useDocumentTitle from '@/hooks/useDocumentTitle';

import { useAppDispatch } from '@/stores';
import { setLoading } from '@/stores/features/auth.slice';

import {
  translate,
  translateChartLegend,
} from '@/helpers/functions/language.helper';
import { locale_keys } from '@/helpers/locales/locale.key';
import apiService from '@/services/api/api.services';
import endpointConfig from '@/services/api/endpoint.config';

import PlansDetail from './detail.plan';
import classNames from 'classnames';
import dayjs from 'dayjs';
import {
  PaginationDefault,
  PaginationInterface,
} from '@/helpers/types/pagination.types';
import { ColumnsType } from 'antd/es/table';
import { CampaignModel } from '@/helpers/types/campaign.types';
import TablePagination from '@/components/Pagination';
import {
  DEFAULT_LIMIT,
  DEFAULT_SIZE,
} from '@/helpers/constants/common.constant';
import _ from 'lodash';
const { RangePicker } = DatePicker;

const PackagesManagement = () => {
  useDocumentTitle(translate(locale_keys.menu.package));
  const dispatch = useAppDispatch();
  const location = useLocation();

  const {
    register,
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      plan: {},
      campaign: {
        timeRange: [dayjs(), dayjs()],
        status: false,
      },
    },
  });
  const [historyCampaign, setHistoryCampaign] = useState<any>([]);
  const [isShowModal, setIsShowModal] = useState(false);

  const getListPackages = async () => {
    try {
      const { data } = await apiService.get(endpointConfig.plans);
      return data?.data;
    } catch (e) {
      console.log(e);
    }
    return {};
  };
  const dataForm = watch();

  const getCampaign = async () => {
    try {
      const { data } = await apiService.get(endpointConfig.campaign);
      if (data?.success) {
        return data.data;
      }
    } catch (e) {
      console.log(e);
    }
    return {};
  };

  const [pagination, setPagination] =
    useState<PaginationInterface>(PaginationDefault);
  const [searchValues, setSearchValues] = useState({
    limit: DEFAULT_LIMIT,
    page: DEFAULT_SIZE,
  });
  const getHistoryCampaign = async (isFork?: boolean) => {
    if (!isFork && !isShowModal) return;
    dispatch(setLoading(true));
    const params = _.pickBy(searchValues, _.identity);
    try {
      const { data } = await apiService.get(
        `${endpointConfig.campaign}/history`,
        {
          params,
        }
      );
      if (data?.success) {
        setHistoryCampaign(data?.data?.data || []);
        setPagination(data?.data?.paginate);
      }
    } finally {
      setTimeout(() => {
        dispatch(setLoading(false));
      }, 250);
    }
  };

  const columns: ColumnsType<CampaignModel> = [
    {
      title: translate(locale_keys.paymentManagement.campaignConfig.no),
      key: 'id',
      render: (text, record, index) =>
        index + 1 + (pagination.currentPage - 1) * pagination.pageSize,
      width: 100,
    },
    {
      title: translate(locale_keys.paymentManagement.campaignConfig.dateStart),
      dataIndex: 'startDate',
      key: 'startDate',
      render: (date: number) => dayjs(date).format('DD-MM-YYYY'),
    },
    {
      title: translate(locale_keys.paymentManagement.campaignConfig.dateEnd),
      dataIndex: 'endDate',
      key: 'endDate',
      render: (date: number) => dayjs(date).format('DD-MM-YYYY'),
    },
    {
      title: '',
      dataIndex: 'status',
      key: 'status',
      render: (status: string) =>
        status === 'ACTIVE' && (
          <span
            style={{
              color: '#03ac13',
            }}
          >
            {status}
          </span>
        ),
      width: 100,
    },
  ];

  const refresh = async () => {
    try {
      dispatch(setLoading(true));
      const dataCampaign = await getCampaign();
      const dataPackage = await getListPackages();

      reset({
        campaign: {
          status: dataCampaign?.status == 'ACTIVE',
          timeRange: [
            dayjs(dataCampaign?.startDate),
            dayjs(dataCampaign?.endDate),
          ],
        },
        plan: dataPackage || {},
      });
    } finally {
      setTimeout(() => {
        dispatch(setLoading(false));
      }, 250);
    }
  };

  useEffect(() => {
    (async () => {
      await refresh();
    })();
  }, [location]);

  const viewHistory = () => {
    getHistoryCampaign(true);
    setIsShowModal(true);
  };
  const handlePaginationChange = (page: number, pageSize: number) => {
    if (pageSize !== pagination.pageSize) {
      setPagination({
        ...PaginationDefault,
        pageSize: pageSize,
      });
      page = 1;
    } else {
      setPagination((prev) => ({
        ...prev,
        currentPage: page,
      }));
    }

    setSearchValues((prev) => {
      if (pageSize === pagination.pageSize) {
        return {
          ...prev,
          limit: pageSize,
          page,
        };
      }
      return {
        ...prev,
        limit: pageSize,
        page,
      };
    });
  };
  useEffect(() => {
    getHistoryCampaign();
  }, [searchValues]);
  const onSubmit = async (dataForm: any) => {
    dispatch(setLoading(true));
    try {
      const { data } = await apiService.patch(
        endpointConfig.plans,
        dataForm?.plan
      );
      const response = await apiService.post(endpointConfig.campaign, {
        startDate: dataForm?.campaign?.timeRange[0],
        endDate: dataForm?.campaign?.timeRange[1],
        status: dataForm?.campaign?.status ? 'ACTIVE' : 'INACTIVE',
      });

      if (data?.success && response?.data?.success) {
        toast.success(
          translate(
            locale_keys.paymentManagement.packageConfig.toastr.updateSuccessful
          )
        );
        await refresh();
      }
    } finally {
      setTimeout(() => {
        dispatch(setLoading(false));
      }, 250);
    }
  };

  return (
    <div className='package'>
      <form id='basic-form' onSubmit={handleSubmit(onSubmit)}>
        <Row gutter={16} className={'mb-5 '}>
          <Col span={12}>
            <div
              style={{
                backgroundColor: '#cbebf7',
              }}
              className={'pt-1 px-1 rounded-2'}
            >
              <div
                className={
                  'd-flex justify-content-between bg-white rounded-3 py-2 px-3 align-items-center'
                }
              >
                <h6 className={'m-0'}>
                  {translate(
                    locale_keys.paymentManagement.campaignConfig.title
                  )}
                </h6>
                <div className='form-check form-switch'>
                  <Controller
                    control={control}
                    name={'campaign.status'}
                    render={({ field }) => (
                      <input
                        style={{
                          height: '20px',
                          width: '50px',
                        }}
                        className='form-check-input checkbox-campaign-period'
                        type='checkbox'
                        role='switch'
                        id='flexSwitchCheckDefault'
                        checked={field.value ? true : false}
                        onChange={(e) => {
                          field.onChange(e.target.checked);
                        }}
                      />
                    )}
                  />
                </div>
              </div>
              <div className={'px-3 py-3'}>
                <div
                  className={
                    'd-flex justify-content-between  align-items-center py-2'
                  }
                >
                  <span
                    style={{
                      fontSize: '16px',
                    }}
                  >
                    {translate(
                      locale_keys.paymentManagement.campaignConfig.period
                    )}
                  </span>
                  <a
                    style={{
                      color: '#A6A6A6',
                    }}
                    onClick={() => {
                      viewHistory();
                    }}
                  >
                    <i>
                      <u>
                        {' '}
                        {translate(
                          locale_keys.paymentManagement.campaignConfig.history
                        )}
                      </u>
                    </i>
                  </a>
                </div>
                <div>
                  <Controller
                    control={control}
                    key={'campaign.timeRange'}
                    name={'campaign.timeRange'}
                    defaultValue={[dayjs(), dayjs()]}
                    render={({ field }) => (
                      <RangePicker
                        placeholder={[
                          translate(
                            locale_keys.advertisement.detail.startPlaceholder
                          ),
                          translate(
                            locale_keys.advertisement.detail.endPlaceholder
                          ),
                        ]}
                        disabledDate={(current: any) => {
                          return current && current < dayjs().startOf('day');
                        }}
                        size='large'
                        style={{ width: '100%' }}
                        format='YYYY-MM-DD'
                        className={classNames([''], {
                          ['--error']: errors[`campaign`],
                        })}
                        onChange={(dates: any, dateStrings: any) => {
                          console.log(dateStrings);
                          if (dateStrings[0] === '') {
                            field.onChange(null);
                          } else {
                            field.onChange(dateStrings);
                          }
                        }}
                        value={[
                          dayjs(field.value[0], 'YYYY-MM-DD'),
                          dayjs(field.value[1], 'YYYY-MM-DD'),
                        ]}
                        disabled={[
                          !dataForm?.campaign?.status || false,
                          !dataForm?.campaign?.status || false,
                        ]}
                        allowClear={false}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Card>
              <PlansDetail
                control={control}
                register={register}
                errors={errors}
                keyName={`plan`}
              ></PlansDetail>
            </Card>
          </Col>
        </Row>
        <div className='d-flex justify-content-end mt-30'>
          <Button className='form-btn form-btn--black' htmlType='submit'>
            {translate(locale_keys.filter.save)}
          </Button>
          <Button className='form-btn ml-20' onClick={refresh}>
            {translate(locale_keys.filter.refresh)}
          </Button>
        </div>
      </form>
      {isShowModal && (
        <Modal
          title={translate(
            locale_keys.paymentManagement.campaignConfig.titleHistory
          )}
          open={isShowModal}
          width={600}
          onOk={() => setIsShowModal(false)}
          onCancel={() => setIsShowModal(false)}
          footer={null}
        >
          <div className='table__grid'>
            <Table
              rowKey='_id'
              scroll={{ y: 400 }}
              // loading={true}
              columns={columns}
              dataSource={historyCampaign}
              pagination={false}
            />
            <TablePagination
              currentPage={pagination.currentPage}
              total={pagination.total}
              pageSize={pagination.pageSize}
              onPageChange={handlePaginationChange}
            ></TablePagination>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default PackagesManagement;
