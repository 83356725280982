import { Col, Row } from 'antd';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import '@/styles/pages/dashboard.scss';

import useDocumentTitle from '@/hooks/useDocumentTitle';

import { useAppDispatch } from '@/stores';
import { setLoading } from '@/stores/features/auth.slice';

import WorldMapImage from '@/assets/images/png/world-map.png';
import { translate } from '@/helpers/functions/language.helper';
import { locale_keys } from '@/helpers/locales/locale.key';
import StackBarChart from '@/pages/dashboard/charts/StackBarChart';
import apiService from '@/services/api/api.services';
import endpointConfig from '@/services/api/endpoint.config';

import HorizontalBarChart from './charts/HorizontalBarChart';
import LineChart from './charts/LineChart';
import PieChart from './charts/PieChart';
import Counters from './Counters';

const Dashboard = () => {
  useDocumentTitle(translate(locale_keys.heading.dashboard));
  const dispatch = useAppDispatch();
  const [chartsData, setChartsData] = useState<{
    social: unknown;
    plans: unknown;
    userTypes: unknown[];
  }>({ social: {}, plans: {}, userTypes: [] });
  const [countersData, setCountersData] = useState<any>({});
  const [pollsData, setPollsData] = useState<any>([]);

  const location = useLocation();

  const getUserRoleData = async () => {
    dispatch(setLoading(true));

    try {
      const { data: countersData } = await apiService.get(
        endpointConfig.statisticCounters
      );
      const { data: chartsData } = await apiService.get(
        endpointConfig.statisticCharts
      );
      const { data: pollsData } = await apiService.get(
        endpointConfig.statisticPolls
      );
      setCountersData(countersData?.data);
      setChartsData(chartsData.data);
      setPollsData(pollsData.data);
    } finally {
      setTimeout(() => {
        dispatch(setLoading(false));
      }, 250);
    }
  };
  useEffect(() => {
    getUserRoleData();
  }, [location]);

  return (
    <div className='dashboard'>
      <div className='dashboard_firstline'>
        <Counters data={countersData} />
      </div>
      <div className='dashboard_secondline'>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col span={18}>
            <div className='dashboard_chart dashboard_chart_short'>
              <LineChart data={pollsData}></LineChart>
            </div>
          </Col>
          <Col span={6}>
            <div className='dashboard_chart dashboard_chart_short'>
              <div className='dashboard_chart_title mb-20'>
                {translate(locale_keys.dashboard.socialUser.title)}
              </div>
              <img
                src={WorldMapImage}
                alt='logo'
                className='dashboard_chart_wallpaper'
              />

              <HorizontalBarChart
                data={chartsData?.social}
              ></HorizontalBarChart>
            </div>
          </Col>
        </Row>
      </div>
      <div className='dashboard_thirdline'>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col span={12}>
            <div className='dashboard_chart'>
              <div className='dashboard_chart_title'>
                {translate(locale_keys.dashboard.planPurchase.title)}
              </div>
              <PieChart data={chartsData?.plans}></PieChart>
            </div>
          </Col>
          <Col span={12}>
            <div className='dashboard_chart'>
              <div className='dashboard_chart_title'>
                {translate(locale_keys.dashboard.userAndPayment.title)}
              </div>
              <StackBarChart data={chartsData?.userTypes}></StackBarChart>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Dashboard;
