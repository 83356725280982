export type ADMIN_ROLE_TYPE = 'ADMIN' | 'SUPER_ADMIN';
export const ADMIN_ROLE = [
  {
    label: 'ALL',
    key: 'ALL',
    value: '',
  },
  {
    label: 'ADMIN',
    key: 'ADMIN',
    value: 'ADMIN',
  },
  {
    label: 'SUPER_ADMIN',
    key: 'SUPER_ADMIN',
    value: 'SUPER_ADMIN',
  },
];
