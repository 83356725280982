import React from 'react';
import '@/styles/components/voting/live-view/options.scss';

import { E_TYPE_POLL } from '@/helpers/constants/voting.constant';

import Compare from './Compare';
import Judgement from './Judgement';
import Ranking from './Ranking';
import Survey from './Survey';

const PollLiveViewOptions = ({
  pollId,
  type,
  options,
  attachmentUrl,
  attachmentType,
}: any) => {
  return (
    <React.Fragment>
      {type === E_TYPE_POLL._JUDGE && (
        <Judgement
          pollId={pollId}
          options={options}
          attachmentUrl={attachmentUrl}
          attachmentType={attachmentType}
        />
      )}

      {type === E_TYPE_POLL._COMPARE && (
        <Compare pollId={pollId} options={options} />
      )}

      {type === E_TYPE_POLL._RANK && (
        <Ranking
          pollId={pollId}
          options={options}
          attachmentUrl={attachmentUrl}
          attachmentType={attachmentType}
        />
      )}

      {type === E_TYPE_POLL._SURVEY && (
        <Survey
          pollId={pollId}
          options={options}
          attachmentUrl={attachmentUrl}
          attachmentType={attachmentType}
        />
      )}
    </React.Fragment>
  );
};

export default PollLiveViewOptions;
