import { Modal } from 'antd';

export const infoModal = (content: any, props?: any) => {
  return new Promise<void>((reject) => {
    Modal.info({
      ...props,
      onOk: () => {
        reject();
      },
      icon: ' ',
      content: content,
    });
  });
};
