import { CheckOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Drawer, Row, Space } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import '@/styles/pages/ads.scss';

import { DescriptionItem } from '@/components/DescriptionItem';
import { confirmModal } from '@/components/ShowConfirm';

import { useAppDispatch } from '@/stores';
import { setLoading } from '@/stores/features/auth.slice';

import { HISTORY_GIFT_STATUS } from '@/helpers/constants/exchange.constant';
import {
  translate,
  translateChartLegend,
} from '@/helpers/functions/language.helper';
import { locale_keys } from '@/helpers/locales/locale.key';
import { HistoryGiftModel } from '@/helpers/types/exchange.types';
import apiService from '@/services/api/api.services';
import endpointConfig from '@/services/api/endpoint.config';

interface ExchangeDetailDrawerProps {
  resetDrawer: boolean;
  selectedId: any;
  open: boolean;
  onCloseDetailDrawer: () => void;
  onRefresh: () => void;
}

const ExchangeDetailDrawer = ({
  resetDrawer,
  selectedId,
  open,
  onCloseDetailDrawer,
  onRefresh,
}: ExchangeDetailDrawerProps) => {
  const dispatch = useAppDispatch();
  const [exchange, setExchange] = useState<HistoryGiftModel>();
  const [giftType, setGiftType] = useState('');
  const [userLocation, setUserLocation] = useState('');
  const [orderStatus, setOrderStatus] = useState('');

  const getDetailExchange = async (selectedId: any) => {
    dispatch(setLoading(true));
    try {
      // const { data } = await apiService.get(
      //   `${endpointConfig.point}/${selectedId}`
      // );
      // setExchange(data?.data);
      await setExchange(selectedId);
    } finally {
      setTimeout(() => {
        dispatch(setLoading(false));
      }, 250);
    }
  };

  const onUpdateStatus = async (status: string) => {
    try {
      await confirmModal({
        title: translate(locale_keys.exchange.detail.confirm.update),
        content: `${translate(
          locale_keys.exchange.detail.confirm.contentUpdate
        )} ${translate(locale_keys.exchange.statusType?.[status])}?`,
        okText: translate(locale_keys.filter.yes),
        cancelText: translate(locale_keys.filter.no),
        // icon: <InfoCircleFilled />,
      });

      doUpdateStatus(status);
    } catch (e) {
      // User clicked Cancel or closed the modal
    }
  };

  const doUpdateStatus = async (status: string) => {
    dispatch(setLoading(true));

    try {
      const res = await apiService.patch(
        `${endpointConfig.updateHistory}/${selectedId._id}`,
        {
          status,
        }
      );
      if (res?.data?.success) {
        onCloseDetailDrawer();
        toast.success(
          translate(locale_keys.exchange.detail.toastr.updateSuccessful)
        );
        onRefresh();
      }
    } finally {
      setTimeout(() => {
        dispatch(setLoading(false));
      }, 250);
    }
  };

  const ActiveButton = () => {
    if (exchange?.status === HISTORY_GIFT_STATUS._COMPLETED)
      return (
        <Button
          type='dashed'
          danger
          icon={<CheckOutlined />}
          className='d-flex align-items-center'
          onClick={() => onUpdateStatus(HISTORY_GIFT_STATUS._IN_PROGRESS)}
        >
          {translate(locale_keys.exchange.detail.moveToInprogress)}
        </Button>
      );
    if (exchange?.status === HISTORY_GIFT_STATUS._IN_PROGRESS)
      return (
        <Button
          type='dashed'
          icon={<CheckOutlined />}
          className='d-flex align-items-center text-active text-active--border'
          onClick={() => onUpdateStatus(HISTORY_GIFT_STATUS._COMPLETED)}
        >
          {translate(locale_keys.exchange.detail.markSuccess)}
        </Button>
      );
  };

  const RejectButton = () => {
    if (exchange?.status !== HISTORY_GIFT_STATUS._CANCEL)
      return (
        <Button
          type='dashed'
          danger
          icon={<CheckOutlined />}
          className='d-flex align-items-center'
          onClick={() => onUpdateStatus(HISTORY_GIFT_STATUS._CANCEL)}
        >
          {translate(locale_keys.filter.cancel)}
        </Button>
      );
  };

  useEffect(() => {
    if (selectedId) {
      getDetailExchange(selectedId);
      setGiftType(exchange?.type || '');
      setUserLocation(exchange?.giftId?.location || '');
      setOrderStatus(exchange?.status || '');
    }
  }, [resetDrawer, exchange]);

  return (
    <>
      <Drawer
        title={translate(locale_keys.exchange.detail.title)}
        width={640}
        placement='right'
        onClose={onCloseDetailDrawer}
        open={open}
        bodyStyle={{ paddingBottom: 80 }}
        extra={
          <>
            <Space>
              {ActiveButton()}
              {RejectButton()}
            </Space>
          </>
        }
      >
        <p className='site-description-item-profile-p'>
          {translate(locale_keys.saleManagement.detail.userInformation)}
        </p>
        <Row>
          <Col span={12}>
            <DescriptionItem
              title={translate(locale_keys.saleManagement.username)}
              content={exchange?.userId?.username}
            />
          </Col>
          <Col span={12}>
            <DescriptionItem
              title={translate(locale_keys.exchange.detail.fullName)}
              content={exchange?.userId?.fullName}
            />
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <DescriptionItem
              title={translate(locale_keys.user.email)}
              content={exchange?.userId?.email}
            />
          </Col>
        </Row>
        <Divider />
        <p className='site-description-item-profile-p'>
          {translate(locale_keys.exchange.detail.giftInfo)}
        </p>
        <Row>
          <Col span={12}>
            <DescriptionItem
              title={translate(locale_keys.common.title)}
              content={exchange?.giftId?.title}
            />
          </Col>
          <Col span={12}>
            <DescriptionItem
              title={translate(locale_keys.exchange.description)}
              content={exchange?.giftId?.description}
            />
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <DescriptionItem
              title={translate(locale_keys.exchange.type)}
              content={translateChartLegend(giftType, locale_keys.giftType)}
            />
          </Col>
          <Col span={12}>
            <DescriptionItem
              title={translate(locale_keys.advertisement.location)}
              content={translateChartLegend(userLocation, locale_keys.location)}
            />
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <DescriptionItem
              title={translate(locale_keys.saleManagement.orderStatus)}
              content={
                <span
                  className={
                    'fw-600 ' +
                    (exchange?.status === HISTORY_GIFT_STATUS._COMPLETED
                      ? 'text-active'
                      : exchange?.status === HISTORY_GIFT_STATUS._IN_PROGRESS
                      ? 'text-warning'
                      : 'text-reject')
                  }
                >
                  {translateChartLegend(orderStatus, locale_keys.status)}
                </span>
              }
            />
          </Col>
          <Col span={12}>
            <DescriptionItem
              title={translate(locale_keys.exchange.created)}
              content={
                exchange?.created_at &&
                dayjs(exchange?.created_at).format('DD-MM-YYYY, HH:mm')
              }
            />
          </Col>
        </Row>
      </Drawer>
    </>
  );
};

export default ExchangeDetailDrawer;
