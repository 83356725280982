import { ErrorMessage } from '@hookform/error-message';
import { Button, Input } from 'antd';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import useDocumentTitle from '@/hooks/useDocumentTitle';

import { confirmModal } from '@/components/ShowConfirm';

import { useAppDispatch } from '@/stores';
import { setLoading } from '@/stores/features/auth.slice';

import { getValueForInputNumber } from '@/helpers/functions/character.helper';
import { validateNonNegativeNumber } from '@/helpers/functions/form-validation.helper';
import { translate } from '@/helpers/functions/language.helper';
import { locale_keys } from '@/helpers/locales/locale.key';
import { SettingPollModel } from '@/helpers/types/voting.types';
import apiService from '@/services/api/api.services';
import endpointConfig from '@/services/api/endpoint.config';

const PollConfiguration = () => {
  useDocumentTitle('Voting Management');
  const dispatch = useAppDispatch();
  const [settings, setSetting] = useState<SettingPollModel>();

  const defaultValues = {
    freePointPoll: 0,
    paidPointPoll: 0,
    freePointReceiveVote: 0,
    paidPointReceiveVote: 0,
    freePointVoting: 0,
    paidPointVoting: 0,
    maxUpload: 0,
  };

  const {
    handleSubmit,
    register,
    control,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues,
  });

  const onSubmit = async (data: any) => {
    try {
      await confirmModal({
        title: translate(
          locale_keys.voteManagement.pollConfiguration.confirm.update
        ),
        content: translate(
          locale_keys.voteManagement.pollConfiguration.confirm.contentUpdate
        ),
        okText: translate(locale_keys.filter.yes),
        cancelText: translate(locale_keys.filter.cancel),
        // icon: <InfoCircleFilled />,
      });
      doUpdateSettings(data);
    } catch (e) {
      // User clicked Cancel or closed the modal
    }
  };

  const doUpdateSettings = async (dataForm: any) => {
    dispatch(setLoading(true));
    try {
      const reqData = {
        polls: {
          configurations: {
            typeUserUploadVideo: settings?.configurations?.typeUserUploadVideo,
            point: {
              createPoll: {
                free: getValueForInputNumber(dataForm?.freePointPoll),
                paid: getValueForInputNumber(dataForm?.paidPointPoll),
              },
              receivingVote: {
                free: getValueForInputNumber(dataForm?.freePointReceiveVote),
                paid: getValueForInputNumber(dataForm?.paidPointReceiveVote),
              },
              voting: {
                free: getValueForInputNumber(dataForm?.freePointVoting),
                paid: getValueForInputNumber(dataForm?.paidPointVoting),
              },
              maxUpload: getValueForInputNumber(dataForm?.maxUpload),
              spinWheel: settings?.configurations?.point?.spinWheel,
            },
          },
          blacklistWords: [...(settings?.blacklistWords || [])],
        },
      };

      const { data } = await apiService.patch(endpointConfig.settings, reqData);

      if (data?.success) {
        toast.success(
          translate(
            locale_keys.voteManagement.pollConfiguration.toastr.updateSuccessful
          )
        );
        getListSettings();
      }
    } finally {
      setTimeout(() => {
        dispatch(setLoading(false));
      }, 250);
    }
  };

  const getListSettings = async () => {
    dispatch(setLoading(true));

    try {
      const { data } = await apiService.get(endpointConfig.settings);
      setSetting(data?.data?.polls);
      const _settings = data?.data?.polls;
      if (_settings) {
        const formData = {
          freePointPoll: _settings?.configurations?.point?.createPoll?.free,
          paidPointPoll: _settings?.configurations?.point?.createPoll?.paid,
          freePointReceiveVote:
            _settings?.configurations?.point?.receivingVote?.free,
          paidPointReceiveVote:
            _settings?.configurations?.point?.receivingVote?.paid,
          freePointVoting: _settings?.configurations?.point?.voting?.free,
          paidPointVoting: _settings?.configurations?.point?.voting?.paid,
          maxUpload: _settings?.configurations?.point?.maxUpload,
        };
        reset(formData as any);
      }
    } finally {
      setTimeout(() => {
        dispatch(setLoading(false));
      }, 250);
    }
  };

  const onRefresh = () => {
    const formData = {
      freePointPoll: settings?.configurations?.point?.createPoll?.free,
      paidPointPoll: settings?.configurations?.point?.createPoll?.paid,
      freePointReceiveVote:
        settings?.configurations?.point?.receivingVote?.free,
      paidPointReceiveVote:
        settings?.configurations?.point?.receivingVote?.paid,
      freePointVoting: settings?.configurations?.point?.voting?.free,
      paidPointVoting: settings?.configurations?.point?.voting?.paid,
      maxUpload: settings?.configurations?.point?.maxUpload,
    };
    reset(formData as any);
  };

  const location = useLocation();

  useEffect(() => {
    getListSettings();
  }, [location]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className='filter'>
        <div className='filter__title'>
          {translate(locale_keys.voteManagement.pollConfiguration.pointCreate)}
        </div>
        <div className='filter__sub-title'>
          {translate(
            locale_keys.voteManagement.pollConfiguration.pointCreateDes
          )}
        </div>
        <div className='filter__form d-flex'>
          <div className='mr-20' style={{ width: '30%' }}>
            <label htmlFor='status' className='form-label filter__form-label'>
              {translate(locale_keys.voteManagement.pollConfiguration.freeUser)}
            </label>
            <div>
              <Controller
                name='freePointPoll'
                control={control}
                rules={{ validate: validateNonNegativeNumber }}
                render={({ field }) => (
                  <Input
                    size='large'
                    value={field.value}
                    placeholder='Point'
                    className={classNames([''], {
                      ['--error']: errors['freePointPoll'],
                    })}
                    {...register('freePointPoll', {
                      required: translate(locale_keys.validation.fieldRequired),
                    })}
                    onChange={(e: any) => field.onChange(e.target.value)}
                  />
                )}
              />
              <ErrorMessage
                errors={errors}
                name='freePointPoll'
                render={({ message }) => (
                  <p className='text--error'>{message}</p>
                )}
              />
            </div>
          </div>
          <div className='mr-20' style={{ width: '30%' }}>
            <label htmlFor='status' className='form-label filter__form-label'>
              {translate(locale_keys.voteManagement.pollConfiguration.paidUser)}
            </label>
            <div>
              <Controller
                name='paidPointPoll'
                control={control}
                rules={{ validate: validateNonNegativeNumber }}
                render={({ field }) => (
                  <Input
                    size='large'
                    value={field.value}
                    placeholder='Point'
                    className={classNames([''], {
                      ['--error']: errors['paidPointPoll'],
                    })}
                    {...register('paidPointPoll', {
                      required: translate(locale_keys.validation.fieldRequired),
                    })}
                    onChange={(e: any) => field.onChange(e.target.value)}
                  />
                )}
              />
              <ErrorMessage
                errors={errors}
                name='paidPointPoll'
                render={({ message }) => (
                  <p className='text--error'>{message}</p>
                )}
              />
            </div>
          </div>
        </div>
      </div>
      <div className='filter mt-15'>
        <div className='filter__title'>
          {translate(locale_keys.voteManagement.pollConfiguration.pointReceive)}
        </div>
        <div className='filter__sub-title'>
          {translate(
            locale_keys.voteManagement.pollConfiguration.pointReceiveDes
          )}
        </div>
        <div className='filter__form d-flex'>
          <div className='mr-20' style={{ width: '30%' }}>
            <label htmlFor='status' className='form-label filter__form-label'>
              {translate(locale_keys.voteManagement.pollConfiguration.freeUser)}
            </label>
            <div>
              <Controller
                name='freePointReceiveVote'
                control={control}
                rules={{ validate: validateNonNegativeNumber }}
                render={({ field }) => (
                  <Input
                    size='large'
                    value={field.value}
                    placeholder='Point'
                    className={classNames([''], {
                      ['--error']: errors['freePointReceiveVote'],
                    })}
                    {...register('freePointReceiveVote', {
                      required: translate(locale_keys.validation.fieldRequired),
                    })}
                    onChange={(e: any) => field.onChange(e.target.value)}
                  />
                )}
              />
              <ErrorMessage
                errors={errors}
                name='freePointReceiveVote'
                render={({ message }) => (
                  <p className='text--error'>{message}</p>
                )}
              />
            </div>
          </div>
          <div className='mr-20' style={{ width: '30%' }}>
            <label htmlFor='status' className='form-label filter__form-label'>
              {translate(locale_keys.voteManagement.pollConfiguration.paidUser)}
            </label>
            <div>
              <Controller
                name='paidPointReceiveVote'
                control={control}
                rules={{ validate: validateNonNegativeNumber }}
                render={({ field }) => (
                  <Input
                    size='large'
                    value={field.value}
                    placeholder='Point'
                    className={classNames([''], {
                      ['--error']: errors['paidPointReceiveVote'],
                    })}
                    {...register('paidPointReceiveVote', {
                      required: translate(locale_keys.validation.fieldRequired),
                    })}
                    onChange={(e: any) => field.onChange(e.target.value)}
                  />
                )}
              />
              <ErrorMessage
                errors={errors}
                name='paidPointReceiveVote'
                render={({ message }) => (
                  <p className='text--error'>{message}</p>
                )}
              />
            </div>
          </div>
        </div>
      </div>

      <div className='filter mt-15'>
        <div className='filter__title'>
          {translate(
            locale_keys.voteManagement.pollConfiguration.pointVoteOther
          )}
        </div>
        <div className='filter__sub-title'>
          {translate(
            locale_keys.voteManagement.pollConfiguration.pointVoteOtherDes
          )}
        </div>
        <div className='filter__form d-flex'>
          <div className='mr-20' style={{ width: '30%' }}>
            <label htmlFor='status' className='form-label filter__form-label'>
              {translate(locale_keys.voteManagement.pollConfiguration.freeUser)}
            </label>
            <div>
              <Controller
                name='freePointVoting'
                control={control}
                rules={{ validate: validateNonNegativeNumber }}
                render={({ field }) => (
                  <Input
                    size='large'
                    value={field.value}
                    placeholder='Point'
                    className={classNames([''], {
                      ['--error']: errors['freePointVoting'],
                    })}
                    {...register('freePointVoting', {
                      required: translate(locale_keys.validation.fieldRequired),
                    })}
                    onChange={(e: any) => field.onChange(e.target.value)}
                  />
                )}
              />
              <ErrorMessage
                errors={errors}
                name='freePointVoting'
                render={({ message }) => (
                  <p className='text--error'>{message}</p>
                )}
              />
            </div>
          </div>
          <div className='mr-20' style={{ width: '30%' }}>
            <label htmlFor='status' className='form-label filter__form-label'>
              {translate(locale_keys.voteManagement.pollConfiguration.paidUser)}
            </label>
            <div>
              <Controller
                name='paidPointVoting'
                control={control}
                rules={{ validate: validateNonNegativeNumber }}
                render={({ field }) => (
                  <Input
                    size='large'
                    value={field.value}
                    placeholder='Point'
                    className={classNames([''], {
                      ['--error']: errors['paidPointVoting'],
                    })}
                    {...register('paidPointVoting', {
                      required: translate(locale_keys.validation.fieldRequired),
                    })}
                    onChange={(e: any) => field.onChange(e.target.value)}
                  />
                )}
              />
              <ErrorMessage
                errors={errors}
                name='paidPointVoting'
                render={({ message }) => (
                  <p className='text--error'>{message}</p>
                )}
              />
            </div>
          </div>
        </div>
      </div>

      <div className='filter mt-15'>
        <div className='filter__title'>
          {translate(
            locale_keys.voteManagement.pollConfiguration.mediaUploadSize
          )}
        </div>
        <div className='filter__sub-title'>
          {translate(
            locale_keys.voteManagement.pollConfiguration.mediaUploadSizeDes
          )}
        </div>
        <div className='filter__form d-flex'>
          <div className='mr-20' style={{ width: 'calc(60% + 20px)' }}>
            <label htmlFor='status' className='form-label filter__form-label'>
              {translate(locale_keys.voteManagement.pollConfiguration.maxSize)}
            </label>
            <div>
              <Controller
                name='maxUpload'
                control={control}
                rules={{ validate: validateNonNegativeNumber }}
                render={({ field }) => (
                  <Input
                    size='large'
                    value={field.value}
                    placeholder='MB'
                    className={classNames([''], {
                      ['--error']: errors['maxUpload'],
                    })}
                    {...register('maxUpload', {
                      required: translate(locale_keys.validation.fieldRequired),
                    })}
                    onChange={(e: any) => field.onChange(e.target.value)}
                  />
                )}
              />
              <ErrorMessage
                errors={errors}
                name='maxUpload'
                render={({ message }) => (
                  <p className='text--error'>{message}</p>
                )}
              />
            </div>
          </div>
        </div>
      </div>

      <div className='d-flex mt-20 justify-content-end'>
        <Button className='form-btn form-btn--black' htmlType='submit'>
          {translate(locale_keys.voteManagement.pollConfiguration.save)}
        </Button>
        <Button className='form-btn ml-20' onClick={onRefresh}>
          {translate(locale_keys.voteManagement.pollConfiguration.refresh)}
        </Button>
      </div>
    </form>
  );
};

export default PollConfiguration;
