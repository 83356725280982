import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  auth: {
    access_token: '',
    user: null,
    refresh_token: '',
    device_token: '',
  },
  loading: false,
  forgotPassword: {
    email: '',
    otp: '',
  },
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuth: (state, action) => {
      state.auth = {
        ...state.auth,
        ...action.payload,
      };

      return state;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
      return state;
    },
    logout: (state) => {
      state.auth.access_token = '';
      state.auth.user = null;
      state.auth.refresh_token = '';
      return state;
    },
    forgotPassword: (state, action) => {
      state.forgotPassword = {
        ...state.forgotPassword,
        ...action.payload,
      };
      return state;
    },
  },
});

export const { setAuth, setLoading, forgotPassword } = authSlice.actions;

export default authSlice.reducer;
