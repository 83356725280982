import { IFormRule } from '../locale';

const formRules: IFormRule = {
  required: 'This field is required',
  passwordNotMatch: 'Password not match',
  number: 'This field must be a number',
  email: 'Email is invalid',
  passwordNotStrong:
    'Your password must contain at least one uppercase letter, one lowercase letter, one digit and one special character',
  passwordNotLong: 'Your password must be between 6 and 30 characters long',
  username:
    'Your username only letters, number, period(.), underscore(_) are allowed.',
  userNameNotLong: 'Your username must be between 6 and 30 characters long.',
  fullname:
    'Full name must not be more than 50 characters and only allow alphabet and space',
};

export default formRules;
