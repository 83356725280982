import { useEffect, useState } from 'react';

import { useLocalStorage } from '@/hooks/useLocalStorage';

import LogoImage from '@/assets/images/png/login-login.png';

import MenuAccount from './menu-account';
import MenuList from './menu-list';

const Menu = () => {
  const [currentUser, setCurrentUser] = useState(null);
  useEffect(() => {
    const persistRoot = useLocalStorage().getPersistRoot();
    const auth = persistRoot ? JSON.parse(persistRoot?.auth)?.auth : null;
    if (auth && auth?.user) {
      setCurrentUser(auth?.user);
    }
  }, []);

  return (
    <div className='menu d-flex flex-column'>
      <div className='menu__logo'>
        <img src={LogoImage} alt='logo' />
      </div>

      <MenuList currentUser={currentUser}></MenuList>

      <MenuAccount currentUser={currentUser}></MenuAccount>
    </div>
  );
};

export default Menu;
