import React from 'react';
import '@/styles/components/_loading.scss';

const Loading = () => {
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 500);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className={`page-loader ${loading ? 'active' : ''}`}>
      <span className='spinner-grow text-primary' role='status'>
        <span className='visually-hidden'>Loading...</span>
      </span>
    </div>
  );
};

export default Loading;
