import advertisement from './advertisement';
import announcement from './announcement';
import auth from './auth';
import common from './common';
import confirm from './confirm';
import dashboard from './dashboard';
import exchange from './exchange';
import feedbackManagement from './feedbackManagement';
import filter from './filter';
import formRules from './form';
import giftType from './giftType';
import heading from './heading';
import invoiceStatus from './invoiceStatus';
import location from './location';
import menu from './menu';
import paymentManagement from './paymentManagement';
import pointAndGift from './pointAndGift';
import pollType from './pollType';
import report from './report';
import required from './required';
import sale from './sale';
import saleManagement from './saleManagement';
import siteInformation from './siteInformation';
import status from './status';
import user from './user';
import validation from './validation';
import voteManagement from './voteManagement';
import { ILocale } from '../locale';

const jp: ILocale = {
  translation: {
    common: common,
    auth: auth,
    formRule: formRules,
    menu: menu,
    filter: filter,
    user: user,
    confirm: confirm,
    heading: heading,
    required: required,
    report: report,
    sale: sale,
    exchange: exchange,
    dashboard: dashboard,
    voteManagement: voteManagement,
    advertisement: advertisement,
    announcement: announcement,
    siteInformation: siteInformation,
    paymentManagement: paymentManagement,
    saleManagement: saleManagement,
    feedbackManagement: feedbackManagement,
    pointAndGift: pointAndGift,
    status: status,
    location: location,
    pollType: pollType,
    invoiceStatus: invoiceStatus,
    giftType: giftType,
    validation: validation,
  },
};

export default jp;
