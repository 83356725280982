import { IHeading } from '../locale';

const heading: IHeading = {
  login: 'Login',
  forgotPwd: 'Forgot Password',
  confirmOTP: 'Confirm OTP',
  resetPwd: 'Reset Password',
  dashboard: 'Dashboard',
  adminAccount: 'Admin Management',
  userAccount: 'User Management',
};

export default heading;
