import {
  FaApple,
  FaFacebookSquare,
  FaLine,
  FaTwitterSquare,
} from 'react-icons/fa';
import { FcGoogle } from 'react-icons/fc';
import {
  Bar,
  BarChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { translate } from '@/helpers/functions/language.helper';
import { locale_keys } from '@/helpers/locales/locale.key';

interface BarChartProps {
  data: any;
}

const HorizontalBarChart = ({ data }: BarChartProps) => {
  const raw = Object.entries(data).map(([key, value]: any) => {
    return {
      title: key,
      value,
    };
  });
  return (
    <>
      <ResponsiveContainer width='100%' height={160}>
        <BarChart layout='vertical' data={raw} barSize={8}>
          <XAxis type='number' hide />
          <YAxis
            type='category'
            dataKey='title'
            tickLine={false}
            axisLine={false}
            width={35}
            label={{ className: 'middle' }}
            tick={<CustomYAxisTick />}
          />
          <Tooltip content={<CustomTooltip />} />
          <Bar dataKey='value' stackId='a' fill='#808a96' />
        </BarChart>
      </ResponsiveContainer>
    </>
  );
};

const iconList: any = {
  GOOGLE: {
    icon: <FcGoogle y={-13} size={25} />,
    color: '#1977f3',
  },
  FACEBOOK: {
    icon: <FaFacebookSquare style={{ color: '#1977f3' }} y={-13} size={25} />,
    color: '#1977f3',
  },
  LINE: {
    icon: <FaLine style={{ color: '#06c052' }} y={-13} size={25} />,
    color: '#06c052',
  },
  TWITTER: {
    icon: <FaTwitterSquare style={{ color: '#1da1f2' }} y={-13} size={25} />,
    color: '#1da1f2',
  },
  APPLE: {
    icon: <FaApple style={{ color: '#000000' }} y={-13} size={25} />,
    color: '#000000',
  },
};

const CustomTooltip = ({ active, payload, label }: any) => {
  if (active && payload && payload.length) {
    return (
      <div className='custom-tooltip'>
        <p className='label'>
          <span
            className='login_icon'
            style={{ color: iconList[label]?.color }}
          >
            {iconList[label]?.icon}
          </span>
          {`${label?.charAt(0) + label?.slice(1)?.toLowerCase()}: `}
          {`${payload[0].value} ${translate(locale_keys.dashboard.user)}`}
        </p>
      </div>
    );
  }

  return null;
};

const CustomYAxisTick = (props: any) => {
  const { y, payload } = props;
  return (
    <g transform={`translate(${0},${y})`}>{iconList[payload?.value]?.icon}</g>
  );
};

export default HorizontalBarChart;
