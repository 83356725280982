import { IPaymentManagement } from '../locale';

const paymentManagement: IPaymentManagement = {
  packageConfig: {
    basicTitle: 'ベーシック',
    proTitle: 'プロ',
    advancedTitle: 'アドバンスト',
    japan: '日本',
    monthPrice: '月額 ',
    yearPrice: '年額 ',
    otherNation: '国際',
    usd: '(米ドル）',
    yen: '(円)',
    toastr: {
      updateSuccessful: 'プラン設定が保存されました。',
    },
  },
  campaignConfig: {
    title: '無料使用キャンペーン設定',
    period: 'キャンペーン期間',
    history: '履歴',
    titleHistory: 'キャンペーン履歴',
    dateStart: '開始日',
    dateEnd: '終了日',
    no: '番号',
  },
  functionConfig: {
    listFunction: '機能一覧',
    action: 'アクション',
    anonymous: '匿名ユーザー',
    userFree: '無料ユーザー',
    paidUser: '有料ユーザー',
    limitPost: '各ユーザータイプの一日あたりの投稿数制限',
    uploadVideo: '投稿の際に動画をアップロードします。',
    uploadMediaOption:
      '他のユーザーの投稿に追加オプションに画像と動画をアップロードします。',
    addOption: '他のユーザーの投稿に追加オプションを追加します。',
    comment: '他のユーザーの投稿にコメントします。',
    uploadMediaComment: '投稿にコメントする際に画像をアップロードします。',
    uploadCSV: '投稿の際にメールアドレスのCSVファイルをアップロードします。',
    limitEmail: 'CSVファイルのメールアドレス数の制限。',
    toastr: {
      updateSuccessful: '機能設定のアップデートが成功しました',
    },
  },
};

export default paymentManagement;
