import { DEFAULT_LIMIT } from '../constants/common.constant';

export interface PaginationInterface {
  totalPage: number;
  currentPage: number;
  total: number;
  pageSize: number;
}

export const PaginationDefault: PaginationInterface = {
  totalPage: 0,
  currentPage: 1,
  total: 0,
  pageSize: DEFAULT_LIMIT,
};
