import { Button, Input, Select, SelectProps, Table, Tag } from 'antd';
import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import * as _ from 'lodash';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';

import useDocumentTitle from '@/hooks/useDocumentTitle';

import TablePagination from '@/components/Pagination';

import { useAppDispatch } from '@/stores';
import { setLoading } from '@/stores/features/auth.slice';

import {
  DEFAULT_LIMIT,
  DEFAULT_SIZE,
} from '@/helpers/constants/common.constant';
import {
  HISTORY_GIFT_STATUS,
  HISTORY_GIFT_STATUS_OPTIONS,
  HISTORY_GIFT_STATUS_TYPES,
} from '@/helpers/constants/exchange.constant';
import {
  translate,
  translateChartLegend,
} from '@/helpers/functions/language.helper';
import { sorterTable } from '@/helpers/functions/table.helper';
import { locale_keys } from '@/helpers/locales/locale.key';
import { HistoryGiftModel } from '@/helpers/types/exchange.types';
import {
  PaginationDefault,
  PaginationInterface,
} from '@/helpers/types/pagination.types';
import apiService from '@/services/api/api.services';
import endpointConfig from '@/services/api/endpoint.config';

import ExchangeDetailDrawer from './detail.drawer';

const ExchangeManagement = () => {
  useDocumentTitle(translate(locale_keys.menu.pointExchange));
  const dispatch = useAppDispatch();
  const location = useLocation();

  const [exchangeData, setExchangeData] = useState<HistoryGiftModel[]>([]);
  const [selectedId, setSelectedId] = useState('');
  const [resetDrawer, setResetDrawer] = useState<boolean>(false);
  const [openDetailDrawer, setOpenDetailDrawer] = useState(false);
  const giftStatusDefault: SelectProps['options'] = HISTORY_GIFT_STATUS_OPTIONS;
  const [giftStatus, setGiftStatus] = useState(giftStatusDefault);
  const giftTypeDefault: SelectProps['options'] = HISTORY_GIFT_STATUS_TYPES;
  const [giftType, setGiftType] = useState(giftTypeDefault);

  const defaultValues = {
    timeRange: null,
    startDate: null,
    endDate: null,
    keyword: '',
    type: '',
    status: '',
  };

  const { handleSubmit, control, reset } = useForm({
    defaultValues,
  });

  const [searchValues, setSearchValues] = useState({
    keyword: '',
    location: '',
    type: '',
    status: '',
    limit: DEFAULT_LIMIT,
    page: DEFAULT_SIZE,
  });
  const [pagination, setPagination] =
    useState<PaginationInterface>(PaginationDefault);

  const columns: ColumnsType<HistoryGiftModel> = [
    {
      title: translate(locale_keys.user.username),
      dataIndex: 'userId',
      key: 'username',
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a, b) => sorterTable(a?.userId?.username, b?.userId?.username),
      render: ({ username }) => username,
    },
    {
      title: translate(locale_keys.exchange.gift),
      dataIndex: 'giftId',
      key: 'title',
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a, b) => sorterTable(a?.giftId?.title, b?.giftId?.title),
      render: ({ title }) => title,
    },
    // {
    //   title: translate(locale_keys.exchange.description),
    //   dataIndex: 'giftId',
    //   key: 'description',
    //   sortDirections: ['ascend', 'descend', 'ascend'],
    //   sorter: (a, b) =>
    //     sorterTable(a?.giftId?.description, b?.giftId?.description),
    //   render: ({ description }) => description,
    // },
    {
      title: translate(locale_keys.exchange.type),
      dataIndex: 'type',
      key: 'type',
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a, b) => sorterTable(a?.type, b?.type),
      render: (type) => translateChartLegend(type, locale_keys.giftType),
    },
    {
      title: translate(locale_keys.exchange.created),
      dataIndex: 'created',
      key: 'created',
      render: (date: number) => dayjs(date).format('DD-MM-YYYY, HH:mm'),
    },
    {
      title: translate(locale_keys.exchange.status),
      sorter: (a, b) => sorterTable(a.status, b.status),
      dataIndex: 'status',
      key: 'status',
      render: (status: string) => (
        <Tag
          className={
            'fw-600 ' +
            (status === HISTORY_GIFT_STATUS._COMPLETED
              ? 'text-active'
              : status === HISTORY_GIFT_STATUS._IN_PROGRESS
              ? 'text-warning'
              : 'text-reject')
          }
        >
          {translateChartLegend(status, locale_keys.status)}
        </Tag>
      ),
    },
    {
      title: translate(locale_keys.user.action),
      key: 'action',
      width: 150,
      render: (_, record) => (
        <div
          className='table__action--default'
          onClick={() => onOpenDetailDrawer(record._id)}
        >
          <span className='mr-8'>
            {translate(locale_keys.feedbackManagement.inquiry.viewDetail)}
          </span>
          <span>
            <i className='bi bi-box-arrow-up-right'></i>
          </span>
        </div>
      ),
    },
  ];

  const getListExchangeManagements = async () => {
    dispatch(setLoading(true));

    const params = _.pickBy(searchValues, _.identity);
    try {
      const { data } = await apiService.get(endpointConfig.exchange, {
        params,
      });

      setExchangeData(data.data);
      setPagination(data.paginate);
    } finally {
      setTimeout(() => {
        dispatch(setLoading(false));
      }, 250);
    }
  };

  const handlePaginationChange = (page: number, pageSize: number) => {
    if (pageSize !== pagination.pageSize) {
      setPagination({
        ...PaginationDefault,
        pageSize: pageSize,
      });
      page = 1;
    } else {
      setPagination((prev) => ({
        ...prev,
        currentPage: page,
      }));
    }

    setSearchValues((prev) => ({
      ...prev,
      limit: pageSize,
      page,
    }));
  };

  const onSubmit = async (data: any) => {
    const { timeRange, ...rest } = data;
    setSearchValues((prev) => ({
      ...prev,
      ...rest,
      ...(timeRange?.length === 2 && {
        startDate: new Date(timeRange[0])?.toISOString(),
        endDate: new Date(timeRange[1])?.toISOString(),
      }),
    }));
  };

  const onOpenDetailDrawer = (_id: string) => {
    setResetDrawer(!resetDrawer);
    setSelectedId(_id);
    setOpenDetailDrawer(true);
  };

  const onClearFilter = () => {
    reset(defaultValues);
    setSearchValues((prev) => ({
      ...prev,
      ...defaultValues,
      limit: DEFAULT_LIMIT,
      page: DEFAULT_SIZE,
    }));
  };

  const handleOnCloseDetailDrawer = () => {
    setOpenDetailDrawer(false);
  };

  const handleOnRefresh = () => {
    getListExchangeManagements();
  };

  useEffect(() => {
    getListExchangeManagements();
    setGiftStatus((prevOptions) =>
      prevOptions.map((option: any) => ({
        ...option,
        label: translateChartLegend(option.key, locale_keys.status),
      }))
    );
    setGiftType((prevOptions) =>
      prevOptions.map((option: any) => ({
        ...option,
        label: translateChartLegend(option.key, locale_keys.giftType),
      }))
    );
  }, [searchValues, location]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='filter'>
          <div className='filter__title'>
            {translate(locale_keys.filter.filterBy)}
          </div>
          <div className='filter__form d-flex'>
            <div className='mr-20'>
              <label
                htmlFor='keyword'
                className='form-label filter__form-label'
              >
                {translate(locale_keys.exchange.user)}
              </label>
              <Controller
                control={control}
                name='keyword'
                defaultValue=''
                render={({ field }) => (
                  <Input
                    size='large'
                    value={field.value}
                    placeholder={translate(locale_keys.filter.userPlaceholder)}
                    onChange={(e: any) => field.onChange(e.target.value)}
                  />
                )}
              />
            </div>

            <div className='mr-20'>
              <label htmlFor='type' className='form-label filter__form-label'>
                {translate(locale_keys.exchange.status)}
              </label>
              <div>
                <Controller
                  name='status'
                  control={control}
                  defaultValue={HISTORY_GIFT_STATUS_OPTIONS[0].value}
                  render={({ field }) => (
                    <Select
                      size='large'
                      defaultValue={field.value}
                      value={field.value}
                      onChange={(value) => field.onChange(value)}
                      style={{ width: 200 }}
                      options={giftStatus}
                    />
                  )}
                />
              </div>
            </div>

            <div className='mr-20'>
              <label htmlFor='type' className='form-label filter__form-label'>
                {translate(locale_keys.exchange.giftType)}
              </label>
              <div>
                <Controller
                  name='type'
                  control={control}
                  defaultValue={HISTORY_GIFT_STATUS_TYPES[0].value}
                  render={({ field }) => (
                    <Select
                      size='large'
                      defaultValue={field.value}
                      value={field.value}
                      onChange={(value) => field.onChange(value)}
                      style={{ width: 200 }}
                      options={giftType}
                    />
                  )}
                />
              </div>
            </div>

            <div className='ms-auto d-flex align-items-end'>
              <Button className='form-btn' onClick={onClearFilter}>
                {translate(locale_keys.filter.clear)}
              </Button>
              <Button
                className='form-btn form-btn--black ml-20'
                htmlType='submit'
              >
                {translate(locale_keys.filter.apply)}
              </Button>
            </div>
          </div>
        </div>
      </form>

      <div className='table mt-24'>
        <div className='table__heading d-flex justify-content-between'>
          <div className='table__heading-title'>
            {translate(locale_keys.exchange.listExchange)}
          </div>
        </div>
        <div className='table__grid'>
          <Table
            rowKey='_id'
            scroll={{ y: 400 }}
            // loading={true}
            columns={columns}
            dataSource={exchangeData}
            pagination={false}
          />
          <TablePagination
            currentPage={pagination.currentPage}
            total={pagination.total}
            pageSize={pagination.pageSize}
            onPageChange={handlePaginationChange}
          ></TablePagination>
        </div>
      </div>
      <ExchangeDetailDrawer
        resetDrawer={resetDrawer}
        selectedId={exchangeData?.find((item) => item._id === selectedId)}
        open={openDetailDrawer}
        onCloseDetailDrawer={handleOnCloseDetailDrawer}
        onRefresh={handleOnRefresh}
      ></ExchangeDetailDrawer>
    </>
  );
};

export default ExchangeManagement;
