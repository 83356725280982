import { IPoitAndGift } from '../locale';

const pointAndGift: IPoitAndGift = {
  config: {
    pointSetting: 'Point setting',
    other: 'OTHER',
    japan: 'JAPAN',
    voucherList: 'Voucher list management',
    voucherDetail: 'Voucher detail',
    point: 'Point',
    titlePlaceholder: 'Enter title',
    pointPlaceholder: 'Point',
    wheelSetting: 'Wheel setting',
    pointForSpin: 'Point for 1 spin',
    giftList: 'Gift list management',
    giftDetail: 'Gift detail',
    rate: 'Rate (%)',
    add: 'Add',
    confirm: {
      deleteVoucher: 'Delete Voucher',
      contentDeleteVoucher: 'Are you sure you want to Delete this Voucher?',
      update: 'Update Gift',
      contentUpdate: 'Are you sure you want to Update this Gift?',
      deleteGift: 'Delete Gift',
      contentDeleteGift: 'Are you sure you want to Delete this Gift?',
      contentUpdateSpin: 'Are you sure you want to Update Point for 1 Spin?',
    },
    toastr: {
      deleteVoucherSuccessful: 'Delete Voucher successfully',
      updateSuccessful: 'Update Gift successfully',
      maxVoucherWarning: 'Maximum {{count}} Gifts already!',
      createSuccessful: 'Add new Voucher successfully',
      deleteGiftSuccessful: 'Delete Gift successfully',
      totalWheelWarning: 'Total wheel gift rates must be 100%',
      updateSpinSuccessful: 'Update Spin Point successfully',
    },
  },
};

export default pointAndGift;
