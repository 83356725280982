import i18n from '@/services/i18n/i18n.config';

export const switchLanguage = (lang: string) => {
  i18n.changeLanguage(lang);
};

export const translate = (key: string, args?: any) => {
  return i18n.t(key, { ...args }, { returnDetails: true });
};

export const translateChartLegend = (key: string, object: any) => {
  type ObjectKey = keyof typeof object;

  const dynamicKey = key as ObjectKey;

  return translate(object[dynamicKey]);
};
