import { ErrorMessage } from '@hookform/error-message';
import { Drawer, Input, Space } from 'antd';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import '@/styles/pages/ads.scss';

import { confirmModal } from '@/components/ShowConfirm';

import { useAppDispatch } from '@/stores';
import { setLoading } from '@/stores/features/auth.slice';

import { translate } from '@/helpers/functions/language.helper';
import { locale_keys } from '@/helpers/locales/locale.key';
import regex from '@/helpers/regex';
import apiService from '@/services/api/api.services';
import endpointConfig from '@/services/api/endpoint.config';

interface ChangePasswordDrawerProps {
  resetDrawer: boolean;
  open: boolean;
  onCloseDetailDrawer: () => void;
}

const ChangePasswordDrawer = ({
  resetDrawer,
  open,
  onCloseDetailDrawer,
}: ChangePasswordDrawerProps) => {
  const dispatch = useAppDispatch();

  const defaultValues = {
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  };

  const {
    handleSubmit,
    control,
    reset,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues,
  });

  const onSubmit = async (dataForm: any) => {
    try {
      await confirmModal({
        title: 'Change password',
        content: 'Are you sure you want to update new password?',
        okText: translate(locale_keys.filter.yes),
        cancelText: translate(locale_keys.filter.no),
        // icon: <InfoCircleFilled />,
      });

      doChangePassword(dataForm);
    } catch (e) {
      // User clicked Cancel or closed the modal
    }
  };

  const doChangePassword = async (dataForm: any) => {
    dispatch(setLoading(true));
    try {
      const reqData: any = {
        oldPassword: dataForm?.currentPassword,
        newPassword: dataForm?.newPassword,
      };

      const res = await apiService.patch(
        endpointConfig.meUpdatePassword,
        reqData
      );
      if (res?.data?.success) {
        onCloseDetailDrawer();
        toast.success('Update password successfully');
      }
    } finally {
      setTimeout(() => {
        dispatch(setLoading(false));
      }, 250);
    }
  };

  useEffect(() => {
    reset(defaultValues);
  }, [resetDrawer]);

  return (
    <>
      <Drawer
        title='Change password'
        width={540}
        placement='right'
        onClose={onCloseDetailDrawer}
        open={open}
        bodyStyle={{ paddingBottom: 80 }}
        extra={
          <Space>
            <button
              type='submit'
              form='update-form'
              className='action-btn action-btn--black'
            >
              Update
            </button>
          </Space>
        }
      >
        <form id='update-form' onSubmit={handleSubmit(onSubmit)}>
          <div className='d-flex justify-content-between mb-20'>
            <div style={{ width: '100%' }}>
              <label htmlFor='' className='form-label filter__form-label'>
                Current Password
              </label>
              <Controller
                control={control}
                name='currentPassword'
                defaultValue=''
                rules={{
                  required: 'This field is required',
                }}
                render={({ field, fieldState }) => (
                  <div className='d-flex flex-column mb-3'>
                    <Input.Password
                      {...field}
                      placeholder='Current Password'
                      size='large'
                      value={field.value}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                      }}
                      status={fieldState.error ? 'error' : ''}
                      autoComplete='new-password'
                    />
                    <ErrorMessage
                      errors={errors}
                      name='currentPassword'
                      render={({ message }) => (
                        <p className='text--error'>{message}</p>
                      )}
                    />
                  </div>
                )}
              />
            </div>
          </div>
          <div className='d-flex justify-content-between mb-20'>
            <div style={{ width: '100%' }}>
              <label htmlFor='' className='form-label filter__form-label'>
                New Password
              </label>
              <Controller
                control={control}
                name='newPassword'
                defaultValue=''
                rules={{
                  required: translate(locale_keys.formRule.required),
                  validate: {
                    length: (value) =>
                      (value.length >= 6 && value.length <= 30) ||
                      translate(locale_keys.formRule.passwordNotLong),
                    strongPassword: (value) =>
                      regex.password(value) ||
                      translate(locale_keys.formRule.passwordNotStrong),
                  },
                }}
                render={({ field, fieldState }) => (
                  <div className='d-flex flex-column mb-3'>
                    <Input.Password
                      {...field}
                      placeholder='New Password'
                      size='large'
                      value={field.value}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                      }}
                      status={fieldState.error ? 'error' : ''}
                      autoComplete='new-password'
                    />
                    <ErrorMessage
                      errors={errors}
                      name='newPassword'
                      render={({ message }) => (
                        <p className='text--error'>{message}</p>
                      )}
                    />
                  </div>
                )}
              />
            </div>
          </div>
          <div className='d-flex justify-content-between mb-20'>
            <div style={{ width: '100%' }}>
              <label htmlFor='' className='form-label filter__form-label'>
                Confirm Password
              </label>
              <Controller
                control={control}
                name='confirmPassword'
                defaultValue=''
                rules={{
                  required: translate(locale_keys.formRule.required),
                  validate: (value) =>
                    value === getValues('newPassword') ||
                    translate(locale_keys.formRule.passwordNotMatch),
                }}
                render={({ field, fieldState }) => (
                  <div className='d-flex flex-column mb-3'>
                    <Input.Password
                      {...field}
                      placeholder='Confirm Password'
                      size='large'
                      value={field.value}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                      }}
                      status={fieldState.error ? 'error' : ''}
                      autoComplete='new-password'
                    />
                    <ErrorMessage
                      errors={errors}
                      name='confirmPassword'
                      render={({ message }) => (
                        <p className='text--error'>{message}</p>
                      )}
                    />
                  </div>
                )}
              />
            </div>
          </div>
        </form>
      </Drawer>
    </>
  );
};

export default ChangePasswordDrawer;
