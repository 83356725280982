export const USER_STATUS = [
  {
    label: 'ALL',
    key: 'ALL',
    value: '',
  },
  {
    label: 'ACTIVE',
    key: 'ACTIVE',
    value: 'ACTIVE',
  },
  {
    label: 'INACTIVE',
    key: 'INACTIVE',
    value: 'INACTIVE',
  },
  {
    label: 'BLOCK',
    key: 'BLOCK',
    value: 'BLOCK',
  },
];

export const USER_ROLE = [
  {
    label: 'ALL',
    key: 'ALL',
    value: '',
  },
  {
    label: 'USER_PAID',
    key: 'USER_PAID',
    value: 'USER_PAID',
  },
  {
    label: 'USER_FREE',
    key: 'USER_FREE',
    value: 'USER_FREE',
  },
];

export const GENDER = [
  {
    label: 'MALE',
    key: 'MALE',
    value: 'MALE',
  },
  {
    label: 'FEMALE',
    key: 'FEMALE',
    value: 'FEMALE',
  },
  {
    label: 'OTHER',
    key: 'OTHER',
    value: 'OTHER',
  },
];

export type USER_STATUS_TYPE = 'ACTIVE' | 'INACTIVE' | 'BLOCK' | '';
