import {
  CloseCircleTwoTone,
  StopOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import { ErrorMessage } from '@hookform/error-message';
import {
  Button,
  DatePicker,
  Drawer,
  Image,
  Input,
  Select,
  SelectProps,
  Space,
  Upload,
  UploadFile,
} from 'antd';
import ImgCrop from 'antd-img-crop';
import axios from 'axios';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import '@/styles/pages/ads.scss';

import { confirmModal } from '@/components/ShowConfirm';

import { useAppDispatch } from '@/stores';
import { setLoading } from '@/stores/features/auth.slice';

import {
  IMAGE_ALLOWED_EXTENSION,
  LOCATION_TYPE_WITHOUT_ALL,
  VIDEO_ALLOWED_EXTENSION,
} from '@/helpers/constants/common.constant';
import {
  E_FOLDER_MEDIA,
  E_TYPE_MEDIA,
} from '@/helpers/constants/voting.constant';
import { validateEndDate } from '@/helpers/functions/datetime.helper';
import {
  validateNonNegativeNumber,
  validateRequired,
  validURL,
} from '@/helpers/functions/form-validation.helper';
import {
  translate,
  translateChartLegend,
} from '@/helpers/functions/language.helper';
import { locale_keys } from '@/helpers/locales/locale.key';
import apiService from '@/services/api/api.services';
import endpointConfig from '@/services/api/endpoint.config';

interface AdsDetailDrawerProps {
  resetDrawer: boolean;
  selectedId: string;
  open: boolean;
  onCloseDetailDrawer: () => void;
  onRefresh: () => void;
}

const MEGABYTES = 1024 * 1024;

const AdsDetailDrawer = ({
  resetDrawer,
  selectedId,
  open,
  onCloseDetailDrawer,
  onRefresh,
}: AdsDetailDrawerProps) => {
  const dispatch = useAppDispatch();
  const optionsTypeDefault: SelectProps['options'] = LOCATION_TYPE_WITHOUT_ALL;
  const [optionsType, setOptionsType] = useState(optionsTypeDefault);
  const { RangePicker } = DatePicker;

  const [verticalImage, setVerticalImage] = useState<any>(null);
  const [fileVerticalList, setFileVerticalList] = useState<UploadFile[]>([]);

  const [horizontalImage, setHorizontalImage] = useState<any>(null);
  const [fileHorizontalList, setFileHorizontalList] = useState<UploadFile[]>(
    []
  );

  const [video, setVideo] = useState<any>(null);
  const [fileVideoList, setFileVideoList] = useState<UploadFile[]>([]);

  const location = useLocation();
  const [maxUpload, setMaxUpload] = useState<number>(0);
  useEffect(() => {
    setOptionsType((preOptions: any) =>
      preOptions.map((option: any) => ({
        ...option,
        label: translateChartLegend(option.key, locale_keys.location),
      }))
    );
    (async () => {
      try {
        const { data } = await apiService.get(endpointConfig.settings);
        setMaxUpload(data?.data?.polls?.configurations?.point?.maxUpload);
      } finally {
        setTimeout(() => {
          dispatch(setLoading(false));
        }, 250);
      }
    })();
  }, [location]);

  const defaultValues = {
    url: '',
    timeStart: null,
    timeEnd: null,
    timeRange: null,
    location: LOCATION_TYPE_WITHOUT_ALL[0].value,
    description: '',
    price: '',
    verticalField: '',
    horizontalField: '',
    videoField: '',
    timeDisplay: '',
  };

  const {
    handleSubmit,
    register,
    control,
    reset,
    setValue,
    formState: { errors },
    trigger,
  } = useForm({
    defaultValues,
  });

  const getDetailAds = async (selectedId: string) => {
    dispatch(setLoading(true));

    try {
      const { data } = await apiService.get(
        `${endpointConfig.advertisement}/${selectedId}`
      );
      setVerticalImage(data?.data?.verticalUrl);
      setHorizontalImage(data?.data?.horizontalUrl);
      setVideo(data?.data?.videoUrl);
      reset({
        ...data?.data,
        verticalField: data?.data?.verticalUrl,
        horizontalField: data?.data?.horizontalUrl,
        videoField: data?.data?.videoUrl,
        timeRange: [data?.data?.timeStart, data?.data?.timeEnd],
      });
    } finally {
      setTimeout(() => {
        dispatch(setLoading(false));
      }, 250);
    }
  };

  const onSubmit = async (dataForm: any) => {
    try {
      const reqData: any = {
        url: dataForm.url,
        timeStart: new Date(dataForm?.timeRange[0]).toISOString(),
        timeEnd: new Date(dataForm?.timeRange[1]).toISOString(),
        location: dataForm.location,
        description: dataForm.description,
        price: Number(dataForm.price),
        videoUrl: dataForm.videoField,
        verticalUrl: dataForm.verticalField,
        horizontalUrl: dataForm.horizontalField,
        timeDisplay: Number(dataForm.timeDisplay),
      };

      if (selectedId !== '') {
        const { data } = await apiService.patch(
          endpointConfig.advertisement + '/' + selectedId,
          reqData
        );
        if (data?.success) {
          toast.success(
            translate(locale_keys.advertisement.detail.toastr.updateSuccessful)
          );
          onCloseDetailDrawer();
          reset(defaultValues);
          onRefresh();
        }
      } else {
        const { data } = await apiService.post(
          endpointConfig.advertisement,
          reqData
        );
        if (data?.success) {
          toast.success(
            translate(locale_keys.advertisement.detail.toastr.createSuccessful)
          );
          onCloseDetailDrawer();
          reset(defaultValues);
          onRefresh();
        }
      }
    } catch (error) {
      dispatch(setLoading(false));
    }
  };

  const validateFileUpload = () => {
    if (!verticalImage && !horizontalImage && !video) {
      return translate(locale_keys.advertisement.detail.required.upload);
    } else {
      return undefined;
    }
  };

  const onDeleteAds = async () => {
    try {
      await confirmModal({
        title: translate(locale_keys.advertisement.detail.confirm.delete),
        content: translate(
          locale_keys.advertisement.detail.confirm.contentDelete
        ),
        okText: translate(locale_keys.filter.yes),
        cancelText: translate(locale_keys.filter.no),
        // icon: <InfoCircleFilled />,
      });

      doDelete();
    } catch (e) {
      // User clicked Cancel or closed the modal
    }
  };

  const doDelete = async () => {
    dispatch(setLoading(true));

    try {
      const res = await apiService.delete(
        `${endpointConfig.advertisement}/${selectedId}`
      );
      if (res?.data?.success) {
        onCloseDetailDrawer();
        toast.success(
          translate(locale_keys.advertisement.detail.toastr.deleteSuccessful)
        );
        onRefresh();
      }
    } finally {
      setTimeout(() => {
        dispatch(setLoading(false));
      }, 250);
    }
  };

  useEffect(() => {
    setFileHorizontalList([]);
    setHorizontalImage(null);
    setFileVerticalList([]);
    setVerticalImage(null);
    setFileVideoList([]);
    setVideo(null);

    if (selectedId !== '') {
      getDetailAds(selectedId);
    } else {
      reset(defaultValues);
    }
  }, [resetDrawer]);

  return (
    <>
      <Drawer
        title={
          selectedId !== ''
            ? translate(locale_keys.advertisement.detail.title)
            : translate(locale_keys.advertisement.detail.listCreateTitle)
        }
        width={720}
        placement='right'
        onClose={onCloseDetailDrawer}
        open={open}
        bodyStyle={{ paddingBottom: 80 }}
        extra={
          <Space>
            <button
              type='submit'
              form='create-form'
              className='action-btn action-btn--black'
            >
              {selectedId === ''
                ? translate(locale_keys.filter.create)
                : translate(locale_keys.filter.update)}
            </button>

            {selectedId !== '' ? (
              <Button
                type='dashed'
                danger
                icon={<StopOutlined />}
                className='d-flex align-items-center'
                onClick={onDeleteAds}
              >
                {translate(locale_keys.filter.delete)}
              </Button>
            ) : null}
          </Space>
        }
      >
        <form id='create-form' onSubmit={handleSubmit(onSubmit)}>
          <div className='d-flex justify-content-between mb-20'>
            <div style={{ width: '100%' }}>
              <label
                htmlFor='description'
                className='form-label filter__form-label'
              >
                {translate(locale_keys.advertisement.description)}
              </label>
              <div>
                <Controller
                  name='description'
                  control={control}
                  defaultValue=''
                  rules={{ validate: validateRequired }}
                  render={({ field }) => (
                    <Input
                      size='large'
                      value={field.value}
                      placeholder={translate(
                        locale_keys.advertisement.detail.descriptionPlaceholder
                      )}
                      className={classNames([''], {
                        ['--error']: errors['description'],
                      })}
                      maxLength={256}
                      {...register('description')}
                      onChange={(e: any) => field.onChange(e.target.value)}
                    />
                  )}
                />
                <ErrorMessage
                  errors={errors}
                  name='description'
                  render={({ message }) => (
                    <p className='text--error'>{message}</p>
                  )}
                />
              </div>
            </div>
          </div>
          <div className='d-flex justify-content-between mb-20'>
            <div style={{ width: '68%' }}>
              <label
                htmlFor='timeEnd'
                className='form-label filter__form-label'
              >
                {translate(locale_keys.advertisement.url)}
              </label>
              <div>
                <Controller
                  name='url'
                  control={control}
                  defaultValue=''
                  rules={{
                    validate: {
                      required: validateRequired,
                      url: validURL,
                    },
                  }}
                  render={({ field }) => (
                    <Input
                      size='large'
                      value={field.value}
                      placeholder={translate(
                        locale_keys.advertisement.detail.urlPlaceholder
                      )}
                      className={classNames([''], {
                        ['--error']: errors['url'],
                      })}
                      maxLength={256}
                      {...register('url')}
                      onChange={(e: any) => field.onChange(e.target.value)}
                    />
                  )}
                />
                <ErrorMessage
                  errors={errors}
                  name='url'
                  render={({ message }) => (
                    <p className='text--error'>{message}</p>
                  )}
                />
              </div>
            </div>
            <div style={{ width: '30%' }}>
              <label htmlFor='type' className='form-label filter__form-label'>
                {translate(locale_keys.advertisement.location)}
              </label>
              <div>
                <Controller
                  name='location'
                  control={control}
                  defaultValue={LOCATION_TYPE_WITHOUT_ALL[0].value}
                  render={({ field }) => (
                    <Select
                      size='large'
                      defaultValue={field.value}
                      value={field.value}
                      onChange={(value) => field.onChange(value)}
                      style={{ width: '100%' }}
                      options={optionsType}
                    />
                  )}
                />
              </div>
            </div>
          </div>
          <div className='d-flex justify-content-between mb-20'>
            <div style={{ width: '68%' }}>
              <label
                htmlFor='timeEnd'
                className='form-label filter__form-label'
              >
                {translate(locale_keys.advertisement.detail.startEndDate)}
              </label>
              <div>
                <Controller
                  name='timeRange'
                  control={control}
                  rules={{
                    validate: {
                      required: validateRequired,
                      validateEndDate: (value: any) =>
                        validateEndDate(value[1], value[0]),
                    },
                  }}
                  render={({ field }) => (
                    <RangePicker
                      placeholder={[
                        translate(
                          locale_keys.advertisement.detail.startPlaceholder
                        ),
                        translate(
                          locale_keys.advertisement.detail.endPlaceholder
                        ),
                      ]}
                      size='large'
                      style={{ width: '100%' }}
                      format='YYYY-MM-DD'
                      {...register('timeEnd')}
                      className={classNames([''], {
                        ['--error']: errors['timeRange'],
                      })}
                      onChange={(dates: any, dateStrings: any) => {
                        if (dateStrings[0] === '') {
                          field.onChange(null);
                        } else {
                          field.onChange(dateStrings);
                        }
                      }}
                      value={
                        field.value
                          ? [dayjs(field.value[0]), dayjs(field.value[1])]
                          : null
                      }
                      disabled={[false, false]}
                      // disabledDate={disabledDate}
                      // disabledTime={disabledRangeTime}
                    />
                  )}
                />
                <ErrorMessage
                  errors={errors}
                  name='timeRange'
                  render={({ message }) => (
                    <p className='text--error'>{message}</p>
                  )}
                />
              </div>
            </div>

            <div style={{ width: '30%' }}>
              <label htmlFor='type' className='form-label filter__form-label'>
                {translate(locale_keys.advertisement.detail.timeDisplay)}
              </label>
              <div>
                <Controller
                  name='timeDisplay'
                  control={control}
                  rules={{ validate: validateNonNegativeNumber }}
                  render={({ field }) => (
                    <Input
                      size='large'
                      value={field.value}
                      placeholder={translate(
                        locale_keys.advertisement.detail.timeDisplayPlaceholder
                      )}
                      className={classNames([''], {
                        ['--error']: errors['timeDisplay'],
                      })}
                      {...register('timeDisplay', {
                        required: translate(
                          locale_keys.advertisement.detail.required.input
                        ),
                      })}
                      onChange={(e: any) => field.onChange(e.target.value)}
                    />
                  )}
                />
                <ErrorMessage
                  errors={errors}
                  name='timeDisplay'
                  render={({ message }) => (
                    <p className='text--error'>{message}</p>
                  )}
                />
              </div>
            </div>
          </div>

          <div className='d-flex mb-20'>
            <div className='mr-30'>
              <label htmlFor='type' className='form-label filter__form-label'>
                {translate(locale_keys.advertisement.detail.verticalBanner)}
              </label>
              <div>
                <Controller
                  name='verticalField'
                  control={control}
                  render={({ field }) => (
                    <ImgCrop zoomSlider aspect={440 / 440}>
                      <Upload
                        accept='.png,.jpg,.jpeg'
                        showUploadList={false}
                        beforeUpload={async (file: any) => {
                          const fileExtension = file.name
                            .split('.')
                            .pop()
                            .toLowerCase();
                          const allowedExtensions = IMAGE_ALLOWED_EXTENSION;

                          if (!allowedExtensions.includes(fileExtension)) {
                            toast.warning(
                              translate(
                                locale_keys.voteManagement.imageDefault.toastr
                                  .uploadTypeWarning
                              )
                            );
                            return false; // Prevent the file from being uploaded
                          }

                          if (file?.size > maxUpload * MEGABYTES) {
                            toast.warning(
                              `${translate(
                                locale_keys.voteManagement.imageDefault.toastr
                                  .uploadSizeWarning
                              )} ${maxUpload} MB.`
                            );
                            return false; // Prevent the file from being uploaded
                          }

                          dispatch(setLoading(true));

                          const response = await apiService.post(
                            endpointConfig.pollPresignedUploadUrl,
                            {
                              type: E_TYPE_MEDIA._IMAGE,
                              extension: file.name.split('.').pop(),
                              folder: E_FOLDER_MEDIA._ADS,
                            }
                          );

                          const { url, key } = response.data.data;

                          const { status } = await axios.put(url, file, {
                            headers: {
                              'Content-Type': file.type,
                            },
                          });
                          dispatch(setLoading(false));

                          if (status === 200) {
                            setFileVerticalList([...fileVerticalList, file]);
                            setVerticalImage(key);
                            field.onChange(key);
                            setTimeout(() => {
                              trigger('horizontalField');
                            }, 100);
                          }

                          return false;
                        }}
                        onRemove={(file) => {
                          const index = fileVerticalList.indexOf(file);
                          const newFileList = fileVerticalList.slice();
                          newFileList.splice(index, 1);
                          setFileVerticalList(newFileList);
                          setVerticalImage(null);
                          field.onChange('');
                          setValue('verticalField', '');
                          setTimeout(() => {
                            trigger('horizontalField');
                          }, 100);
                        }}
                      >
                        {!verticalImage && (
                          <Button icon={<UploadOutlined />}>
                            {translate(
                              locale_keys.advertisement.detail.selectImage
                            )}
                          </Button>
                        )}
                      </Upload>
                    </ImgCrop>
                  )}
                />

                {verticalImage && (
                  <div
                    className='ads__banner ads__banner-vertical'
                    style={{ position: 'relative' }}
                  >
                    <Image
                      src={process.env.REACT_APP_PUBLIC_S3_URL + verticalImage}
                    />
                    <CloseCircleTwoTone
                      style={{
                        position: 'absolute',
                        right: '-5px',
                        top: '-5px',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        setFileVerticalList([]);
                        setVerticalImage(null);
                        setValue('verticalField', '');
                        setTimeout(() => {
                          trigger('horizontalField');
                        }, 100);
                      }}
                    />
                  </div>
                )}
              </div>
            </div>

            <div>
              <label htmlFor='type' className='form-label filter__form-label'>
                {translate(locale_keys.advertisement.detail.uploadVideo)}
              </label>
              <div>
                <Controller
                  name='videoField'
                  control={control}
                  render={({ field }) => (
                    <Upload
                      accept='video/*'
                      showUploadList={false}
                      beforeUpload={async (file: any) => {
                        const fileExtension = file.name
                          .split('.')
                          .pop()
                          .toLowerCase();
                        const allowedExtensions = VIDEO_ALLOWED_EXTENSION;

                        if (!allowedExtensions.includes(fileExtension)) {
                          toast.warning(
                            translate(
                              locale_keys.advertisement.detail.toastr
                                .typeVideoWarning
                            )
                          );
                          return false;
                        }

                        if (file?.size > maxUpload * MEGABYTES) {
                          toast.warning(
                            `${translate(
                              locale_keys.voteManagement.imageDefault.toastr
                                .uploadSizeWarning
                            )} ${maxUpload} MB.`
                          );
                          return false; // Prevent the file from being uploaded
                        }

                        // check file size < 20MB
                        if (file.size / 1024 / 1024 > 20) {
                          toast.error(
                            translate(
                              locale_keys.advertisement.detail.toastr
                                .minSizeVideo
                            )
                          );
                          return false;
                        }
                        dispatch(setLoading(true));

                        const response = await apiService.post(
                          endpointConfig.pollPresignedUploadUrl,
                          {
                            type: E_TYPE_MEDIA._VIDEO,
                            extension: file.name.split('.').pop(),
                            folder: E_FOLDER_MEDIA._ADS,
                          }
                        );

                        const { url, key } = response.data.data;

                        const { status } = await axios.put(url, file, {
                          headers: {
                            'Content-Type': file.type,
                          },
                        });
                        dispatch(setLoading(false));

                        if (status === 200) {
                          setFileVideoList([...fileVideoList, file]);
                          setVideo(key);
                          field.onChange(key);
                          setTimeout(() => {
                            trigger('horizontalField');
                          }, 100);
                        }

                        return false;
                      }}
                      onRemove={(file) => {
                        const index = fileVideoList.indexOf(file);
                        const newFileList = fileVideoList.slice();
                        newFileList.splice(index, 1);
                        setFileVideoList(newFileList);
                        setVideo(null);
                        field.onChange('');
                        setTimeout(() => {
                          trigger('horizontalField');
                        }, 100);
                      }}
                    >
                      {!video && (
                        <Button icon={<UploadOutlined />}>
                          {translate(
                            locale_keys.advertisement.detail.selectVideo
                          )}
                        </Button>
                      )}
                    </Upload>
                  )}
                />

                {video && (
                  <div
                    className='ads__banner ads__banner-video'
                    style={{ position: 'relative' }}
                  >
                    <video
                      src={process.env.REACT_APP_PUBLIC_S3_URL + video}
                      controls
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                      }}
                    />
                    <CloseCircleTwoTone
                      style={{
                        position: 'absolute',
                        right: '-5px',
                        top: '-5px',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        setFileVideoList([]);
                        setVideo(null);
                        setValue('videoField', '');
                        setTimeout(() => {
                          trigger('horizontalField');
                        }, 100);
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className='d-flex justify-content-between mb-20'>
            <div>
              <label htmlFor='type' className='form-label filter__form-label'>
                {translate(locale_keys.advertisement.detail.horizontalBanner)}
              </label>
              <div>
                <Controller
                  name='horizontalField'
                  control={control}
                  rules={{
                    validate: () => validateFileUpload(),
                  }}
                  render={({ field }) => (
                    <ImgCrop aspect={1300 / 200}>
                      <Upload
                        accept='.png,.jpg,.jpeg'
                        showUploadList={false}
                        beforeUpload={async (file: any) => {
                          const fileExtension = file.name
                            .split('.')
                            .pop()
                            .toLowerCase();
                          const allowedExtensions = IMAGE_ALLOWED_EXTENSION;

                          if (!allowedExtensions.includes(fileExtension)) {
                            toast.warning(
                              translate(
                                locale_keys.voteManagement.imageDefault.toastr
                                  .uploadTypeWarning
                              )
                            );

                            return false;
                          }

                          if (file?.size > maxUpload * MEGABYTES) {
                            toast.warning(
                              `${translate(
                                locale_keys.voteManagement.imageDefault.toastr
                                  .uploadSizeWarning
                              )} ${maxUpload} MB.`
                            );
                            return false; // Prevent the file from being uploaded
                          }

                          dispatch(setLoading(true));

                          const response = await apiService.post(
                            endpointConfig.pollPresignedUploadUrl,
                            {
                              type: E_TYPE_MEDIA._IMAGE,
                              extension: file.name.split('.').pop(),
                              folder: E_FOLDER_MEDIA._ADS,
                            }
                          );

                          const { url, key } = response.data.data;

                          const { status } = await axios.put(url, file, {
                            headers: {
                              'Content-Type': file.type,
                            },
                          });
                          dispatch(setLoading(false));

                          if (status === 200) {
                            setFileHorizontalList([
                              ...fileHorizontalList,
                              file,
                            ]);
                            setHorizontalImage(key);
                            field.onChange(key);
                            setTimeout(() => {
                              trigger('horizontalField');
                            }, 100);
                          }

                          return false;
                        }}
                        onRemove={(file) => {
                          const index = fileHorizontalList.indexOf(file);
                          const newFileList = fileHorizontalList.slice();
                          newFileList.splice(index, 1);
                          setFileHorizontalList(newFileList);
                          setHorizontalImage(null);
                          field.onChange('');
                          setTimeout(() => {
                            trigger('horizontalField');
                          }, 100);
                        }}
                        className={classNames([''], {
                          ['--error']: errors['horizontalField'],
                        })}
                      >
                        {!horizontalImage && (
                          <Button icon={<UploadOutlined />}>
                            {translate(
                              locale_keys.advertisement.detail.selectImage
                            )}
                          </Button>
                        )}
                      </Upload>
                    </ImgCrop>
                  )}
                />
                <ErrorMessage
                  errors={errors}
                  name='horizontalField'
                  render={({ message }) => (
                    <p className='text--error'>{message}</p>
                  )}
                />

                {horizontalImage && (
                  <div
                    className='ads__banner ads__banner-horizontal'
                    style={{ position: 'relative' }}
                  >
                    <Image
                      src={
                        process.env.REACT_APP_PUBLIC_S3_URL + horizontalImage
                      }
                    />
                    <CloseCircleTwoTone
                      style={{
                        position: 'absolute',
                        right: '-5px',
                        top: '-5px',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        setFileHorizontalList([]);
                        setHorizontalImage(null);
                        setValue('horizontalField', '');
                        setTimeout(() => {
                          trigger('horizontalField');
                        }, 100);
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </form>
      </Drawer>
    </>
  );
};

export default AdsDetailDrawer;
