import { ErrorMessage } from '@hookform/error-message';
import { Drawer, Space } from 'antd';
import classNames from 'classnames';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { confirmModal } from '@/components/ShowConfirm';

import { useAppDispatch } from '@/stores';
import { setLoading } from '@/stores/features/auth.slice';

import iconEyes from '@/assets/images/png/icon-eyes.png';
import { translate } from '@/helpers/functions/language.helper';
import { locale_keys } from '@/helpers/locales/locale.key';
import regex from '@/helpers/regex';
import apiService from '@/services/api/api.services';
import endpointConfig from '@/services/api/endpoint.config';

interface CreateDrawerProps {
  open: boolean;
  onCloseCreateDrawer: () => void;
  onRefresh: () => void;
}

const CreateDrawer = ({
  open,
  onCloseCreateDrawer,
  onRefresh,
}: CreateDrawerProps) => {
  const [passwordShown, setPasswordShown] = useState(false);

  const dispatch = useAppDispatch();

  const defaultValues = {
    username: '',
    fullName: '',
    password: '',
    email: '',
    role: 'ADMIN',
  };

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues,
  });

  const onSubmit = async (data: any) => {
    try {
      await confirmModal({
        title: translate(locale_keys.confirm.createAdminTitle),
        content: translate(locale_keys.confirm.createAdminDesc),
        okText: translate(locale_keys.filter.create),
        cancelText: translate(locale_keys.filter.cancel),
        // icon: <InfoCircleFilled />,
      });
      doCreateNewAccount(data);
    } catch (e) {
      // User clicked Cancel or closed the modal
    }
  };

  const doCreateNewAccount = async (reqData: any) => {
    dispatch(setLoading(true));

    try {
      const { data } = await apiService.post(
        endpointConfig.actionAccountAdmin,
        reqData
      );

      if (data?.success) {
        onCloseCreateDrawer();
        toast.success(translate(locale_keys.user.toast.createAdminSuccess));
        reset(defaultValues);
        onRefresh();
      }
    } finally {
      setTimeout(() => {
        dispatch(setLoading(false));
      }, 250);
    }
  };

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  return (
    <>
      <Drawer
        title={translate(locale_keys.user.modal.createAdminTitle)}
        width={720}
        onClose={onCloseCreateDrawer}
        open={open}
        bodyStyle={{ paddingBottom: 80 }}
        extra={
          <Space>
            <button
              type='submit'
              form='create-form'
              className='action-btn action-btn--black'
            >
              {translate(locale_keys.filter.submit)}
            </button>
          </Space>
        }
      >
        <form id='create-form' onSubmit={handleSubmit(onSubmit)}>
          <div className='d-flex justify-content-between mb-20'>
            <div style={{ width: '48%' }}>
              <label htmlFor='username'>
                {translate(locale_keys.user.username)}
              </label>
              <input
                type='username'
                id='username'
                placeholder={translate(locale_keys.user.username)}
                className={classNames(['login__form-input'], {
                  ['--error']: errors['username'],
                })}
                maxLength={80}
                {...register('username', {
                  required: translate(locale_keys.required.username),
                  validate: {
                    length: (value) =>
                      (value.length >= 6 && value.length <= 30) ||
                      translate(locale_keys.formRule.userNameNotLong),
                    isUsername: (value) =>
                      regex.username(value) ||
                      translate(locale_keys.formRule.username),
                  },
                })}
              />
              <ErrorMessage
                errors={errors}
                name='username'
                render={({ message }) => (
                  <p className='text--error'>{message}</p>
                )}
              />
            </div>
            <div style={{ width: '48%' }}>
              <label htmlFor='password'>
                {translate(locale_keys.user.defaultPassword)}
              </label>
              <div className='login__form-group-input'>
                <input
                  type={passwordShown ? 'text' : 'password'}
                  id='password'
                  placeholder='*****************'
                  className={classNames(['login__form-input'], {
                    ['--error']: errors['password'],
                  })}
                  maxLength={80}
                  {...register('password', {
                    required: translate(locale_keys.required.defaultPwd),
                    validate: {
                      length: (value) =>
                        (value.length >= 6 && value.length <= 30) ||
                        translate(locale_keys.formRule.passwordNotLong),
                      strongPassword: (value) =>
                        regex.password(value) ||
                        translate(locale_keys.formRule.passwordNotStrong),
                    },
                  })}
                />
                <img src={iconEyes} alt='icon-eyes' onClick={togglePassword} />
              </div>
              <ErrorMessage
                errors={errors}
                name='password'
                render={({ message }) => (
                  <p className='text--error'>{message}</p>
                )}
              />
            </div>
          </div>
          <div className='d-flex justify-content-between mb-20'>
            <div style={{ width: '48%' }}>
              <label htmlFor='fullName'>
                {translate(locale_keys.user.fullName)}
              </label>
              <input
                type='fullName'
                id='fullName'
                placeholder={translate(locale_keys.user.fullName)}
                className={classNames(['login__form-input'], {
                  ['--error']: errors['fullName'],
                })}
                maxLength={80}
                {...register('fullName', {
                  required: translate(locale_keys.required.fullName),
                  validate: (value) =>
                    regex.fullname(value) ||
                    translate(locale_keys.formRule.fullname),
                  onChange(event) {
                    event.target.value = event.target.value.trimStart();

                    return event;
                  },
                })}
              />
              <ErrorMessage
                errors={errors}
                name='fullName'
                render={({ message }) => (
                  <p className='text--error'>{message}</p>
                )}
              />
            </div>
            <div style={{ width: '48%' }}>
              <label htmlFor='email'>{translate(locale_keys.user.email)}</label>
              <input
                type='email'
                id='email'
                placeholder={translate(locale_keys.user.email)}
                className={classNames(['login__form-input'], {
                  ['--error']: errors['email'],
                })}
                maxLength={80}
                {...register('email', {
                  required: translate(locale_keys.required.email),
                  validate: (value) =>
                    regex.email(value) || translate(locale_keys.formRule.email),
                })}
              />
              <ErrorMessage
                errors={errors}
                name='email'
                render={({ message }) => (
                  <p className='text--error'>{message}</p>
                )}
              />
            </div>
          </div>

          <div className='d-flex justify-content-between mb-20'>
            <div style={{ width: '48%' }}>
              <label htmlFor='role'>{translate(locale_keys.user.role)}:</label>
              <span className='ml-20 fw-600'>
                {translate(locale_keys.user.ADMIN)}
              </span>
            </div>
          </div>
        </form>
      </Drawer>
    </>
  );
};

export default CreateDrawer;
