import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import {
  E_LANGUAGE,
  E_LOCAL_STORAGE,
} from '@/helpers/constants/common.constant';
import { getLocalStorage } from '@/helpers/localStorage';
import en from '@/locales/en';
import jp from '@/locales/jp';

const lng =
  (getLocalStorage(E_LOCAL_STORAGE._LANGUAGE) as string) || E_LANGUAGE._EN;

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng,
    resources: {
      en: en as any,
      ja: jp as any,
    },
    fallbackLng: 'en',
    debug: process.env.NODE_ENV === 'development',
    interpolation: {
      escapeValue: false,
    },
  })
  .catch((err) => {
    console.log('i18n initialization failed', err);
  });

export default i18n;
