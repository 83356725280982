import { IVoteManagement } from '../locale';
const voteManagement: IVoteManagement = {
  pollList: {
    author: '投稿者',
    category: 'カテゴリ',
    listUser: '投稿一覧',
    recommend: '推薦投稿',
    status: 'ステータス',
    title: 'タイトル',
    type: 'タイプ',
    viewDetail: '詳細を見る',
    listEmail: 'メール一覧',
    voteType: {
      ALL: '全て',
      // RECOMMEND_BY_ADMIN: '推薦投稿',
      // TOP_VOTE: 'トップ投票',
      // TOP_LIKE: 'トップいいね',
      // TOP_COMMENT: 'トップコメント',
      // PRIVATE: 'CSVの投稿',
      // CLOSED: '投票終了',
      PUBLIC: '公開',
      PRIVATE: '非公開',
    },
    process: '処理状況',
    processStatus: {
      PENDING: '待機中',
      PROCESSING: '処理中',
      COMPLETED: '完了',
      FAILED: '失敗',
    },
    detail: {
      title: '投稿詳細',
      recommend: 'この投稿を推薦する',
      blockPoll: 'この投稿をブロックする',
      activePoll: '投稿をアクティブする',
      published: '投稿日',
      like: 'いいね',
      comment: 'コメント',
      removeRecommend: 'この投票の推薦を取り消す',
      rejectPoll: 'この投票を拒否する',
      owner: 'オーナー - {{count}} フォロワー',
      question: '質問',
      vote: '投票',
      peopleChoice: '票',
      confirm: {
        block: 'この投稿をブロックする',
        contentBlock: 'この投稿をブロックしてよろしいでしょうか。',
        reject: '投票拒否を実施',
        contentReject: 'この投票を拒否したいですか',
        active: '投票アクティブを実施',
        contentActive: 'この投稿をアクティブしてよろしいでしょうか。',
        recommend: '投稿推薦',
        contentRecommend: 'この投稿を推薦してよろしいでしょうか。',
        removeRecommend: '推薦取り消し',
        removeContentRecommend: 'この投稿推薦を取り消してよろしいでしょうか。',
      },
      toastr: {
        activeSuccessful: '投票のアクティブが成功しました',
        recommentSuccessful: '投票の推薦が成功しました',
        removeRecommentSuccessful: '投票推薦の取り消すが成功しました',
        blockSuccessful: '投票のブロックが成功しました',
      },
      sampleVideo: '見本動画',
      practiceVideo: '練習動画',
      aiScore: 'AIの採点',
      view: '見る',
    },
  },
  pollConfiguration: {
    freeUser: '無料ユーザー',
    paidUser: '有料ユーザー',
    mediaUploadSize: 'アップロードサイズ制限',
    mediaUploadSizeDes: 'メディアの最大サイズ設定',
    pointCreate: '投稿する際に取得するポイント数',
    pointCreateDes: 'ユーザーの投稿する際に取得するポイント数を設定します',
    pointReceive: '投票してもらった時に取得するポイント数',
    pointReceiveDes:
      '自分の投稿が投票されたときに取得するポイント数を設定します。',
    pointVoteOther: '他のユーザーの投稿に投票する際に取得するポイント数',
    pointVoteOtherDes:
      '他のユーザーの投稿に投票する際に取得するポイント数を設定します。',
    save: '保存',
    maxSize: '動画の最大サイズ (MB)',
    refresh: 'リフレッシュ',
    confirm: {
      update: '投稿設定',
      contentUpdate: '入力した内容を保存してよろしいでしょうか？',
    },
    toastr: {
      updateSuccessful: '投稿の設定が更新されました。',
    },
  },
  banWord: {
    title: '禁止ワード追加',
    createPlaceholder: '禁止ワードを入力してください',
    banWordList: '禁止ワードの一覧',
    createBanWordSuccessful: '新しい禁止ワードが追加されました。',
    removeBanWordSuccessful: '禁止ワードが削除されました。',
  },
  category: {
    name: 'カテゴリー',
    addNewSub: '小カテゴリーを追加する',
    categoryList: 'カテゴリーの一覧',
    englishName: '英語のカテゴリー',
    englishNameDes: '英語で新しいカテゴリーを入力してください',
    japanName: '日本語のカテゴリー',
    japanNameDes: '日本語で新しいカテゴリーを入力してください',
    title: 'カテゴリー作成',
    englishSub: '英語の小カテゴリー',
    japanSub: '日本語の小カテゴリー',
    japanTitleRequired: 'この項目は必須です。',
    toastr: {
      createSubSuccessful: '新しい小カテゴリーが作成されました。',
      createSuccessful: '新しいカテゴリーが作成されました。',
      deleteSuccessful: 'カテゴリーが削除されました。',
      updateSuccessful: 'カテゴリーが変更されました。',
    },
    confirm: {
      create: 'カテゴリー作成',
      contentCreate: 'このカテゴリーを作成してよろしいでしょう？',
      contentDelete: 'このカテゴリーを削除してよろしいでしょう？',
      delete: 'カテゴリー削除',
      contentCreateSub: 'この小カテゴリーを作成してよろしいでしょう?',
      createSub: '小カテゴリー作成',
      update: 'カテゴリー変更',
      contentUpdate: 'このカテゴリーを変更してよろしいでしょう?',
    },
    englishCategory: '英語のカテゴリー',
    englishSubCategory: '英語の小カテゴリー',
    japanCategory: '日本語のカテゴリー',
    japanSubCategory: '日本語の小カテゴリー',
  },
  imageDefault: {
    title: '新しい画像',
    listImage: 'デフォルト画像',
    selectFile: '画像をアップロードします',
    uploading: 'アップロードしています。',
    startUpload: 'アップロード',
    toastr: {
      createSuccessful: '画像がアップロードされました。',
      deleteSuccessful: '画像が削除されました。',
      uploadSizeWarning: '画像のサイズが制限されています。',
      uploadTypeWarning:
        'ファイルは有効ではありません。 PNG、JPG、JPEGのファイルだけをアップロードできます。',
    },
    confirm: {
      contentDelete: 'このデフォルト画像を削除してよろしいでしょうか?',
      delete: 'デフォルト画像削除',
    },
  },
};

export default voteManagement;
