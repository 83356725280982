import '@/styles/components/_logo.scss';

import LogoImage from '@/assets/images/png/login-login.png';

const Logo = () => {
  return (
    <div className='logo'>
      <img src={LogoImage} alt='logo' />
    </div>
  );
};

export default Logo;
