import { useState } from 'react';

interface Props {
  keys: string[];
}

const useChartLegend = ({ keys }: Props) => {
  const defaultOpacity = keys.reduce((prev: any, curr) => {
    prev[curr] = 1;
    return prev;
  }, {});

  const defaultHideOpacity = keys.reduce((prev: any, curr) => {
    prev[curr] = 0.3;
    return prev;
  }, {});

  const [opacity, setOpacity] = useState(defaultOpacity);

  const onActive = (dataKey: string) => {
    setOpacity({ ...defaultHideOpacity, [dataKey]: 1 });
  };

  const onReset = () => {
    setOpacity(defaultOpacity);
  };

  return [opacity, onActive, onReset];
};

export default useChartLegend;
