import { IStatus } from '../locale';

const status: IStatus = {
  ALL: '全て',
  ACTIVE: 'アクティブ',
  PENDING: '未承認',
  BLOCKED: 'ブロック中',
  REJECTED: '拒否済み',
  BLOCK: 'ブロック中',
  INACTIVE: 'インアクティブ',
  CANCELED: 'キャンセルされました',
  INCOMPLETE: '未完了',
  IN_PROGRESS: '進行中',
  COMPLETED: '完了',
  CANCEL: 'キャンセル',
  PAID: '支払い済み',
  UNPAID: '未払い',
  PAST_DUE: '期限を過ぎている',
  INCOMPLETE_EXPIRED: '支払いが未完成',
};

export default status;
