import React from 'react';
import { Navigate } from 'react-router-dom';

import PrivateLayout from '@/layouts/PrivateLayout';
import AdminAccount from '@/pages/admin-account';
import AdvertisementList from '@/pages/advertisement/advertisement-list';
import AdvertisementRequest from '@/pages/advertisement/advertisement-request';
import Announcement from '@/pages/announcement';
import Dashboard from '@/pages/dashboard';
import Inquiry from '@/pages/inquiry';
import PackagesManagement from '@/pages/payment-management/package';
import FunctionsManagement from '@/pages/payment-management/white-list-function';
import ExchangeManagement from '@/pages/point-management/exchange-management';
import GiftConfiguration from '@/pages/point-management/gift-configuration';
import ReportManagement from '@/pages/report';
import SaleManagement from '@/pages/sale-management';
import SiteInformation from '@/pages/site-information';
import UserAccount from '@/pages/user-account';
import DefaultImage from '@/pages/voting-management/default-image';
import CategoryManagement from '@/pages/voting-management/manage-category';
import PollConfiguration from '@/pages/voting-management/poll-configuration';
import PollList from '@/pages/voting-management/poll-list';
import WordsBan from '@/pages/voting-management/words-ban';
import PostList from '@/pages/posts';

const AuthenticationRoutes = {
  path: '/',
  exact: true,
  element: <PrivateLayout />,
  children: [
    {
      path: 'dashboard',
      element: <Dashboard />,
    },
    {
      path: 'user-account',
      element: <UserAccount />,
    },
    {
      path: 'admin-account',
      element: <AdminAccount />,
    },
    {
      path: 'posts',
      element: <PostList />,
    },
    {
      path: '/voting/poll-list',
      element: <PollList />,
    },
    {
      path: '/voting/poll-configuration',
      element: <PollConfiguration />,
    },
    {
      path: '/voting/word-ban',
      element: <WordsBan />,
    },
    {
      path: '/voting/category',
      element: <CategoryManagement />,
    },
    {
      path: '/voting/image',
      element: <DefaultImage />,
    },
    {
      path: '/advertisement-list',
      element: <AdvertisementList />,
    },
    {
      path: '/advertisement-request',
      element: <AdvertisementRequest />,
    },
    {
      path: 'announcement',
      element: <Announcement />,
    },
    {
      path: 'site',
      element: <SiteInformation />,
    },
    {
      path: '/payment/price',
      element: <PackagesManagement />,
    },
    {
      path: '/payment/list',
      element: <SaleManagement />,
    },
    {
      path: '/feedback/inquiry',
      element: <Inquiry />,
    },
    {
      path: '/feedback/report',
      element: <ReportManagement />,
    },
    {
      path: 'sales',
      element: <SaleManagement />,
    },
    {
      path: 'point/configuration',
      element: <GiftConfiguration />,
    },
    {
      path: 'point/exchange',
      element: <ExchangeManagement />,
    },
    {
      path: '/',
      element: <Navigate to='dashboard' replace />,
    },
  ],
};

export default AuthenticationRoutes;
