import { IDashboard } from '../locale';

const dashboard: IDashboard = {
  user: 'ユーザー',
  purchase: '購入品',
  counter: {
    totalUser: '合計ユーザー数',
    totalPost: '合計投稿数',
    totalBalanceJapan: '合計金額 (日本)',
    totalBalanceOther: '合計金額 (日本国外)',
    totalPollJapan: '総投票数 (日本)',
    totalPollOther: '総投票数 (日本国外)',
  },
  postDetail: {
    title: '投稿統計',
    post: '投稿',
  },
  socialUser: {
    title: 'SNSのユーザー数',
    facebook: 'Facebook',
    google: 'Google',
    line: 'Line',
    twitter: 'Twitter',
  },
  planPurchase: {
    title: 'プラン購入の統計',
    monthly: '月額',
    yearly: '年額',
    pro: 'プロ',
    basic: 'ベーシック',
    advanced: 'アドヴァンス',
  },
  userAndPayment: {
    title: '無料ユーザー及び有料ユーザー',
    anonymous: '匿名ユーザー',
    free: '無料ユーザー',
    paid: '有料ユーザー',
  },
};

export default dashboard;
