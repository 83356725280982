import { IInvoiceStatus } from '../locale';

const invoiceStatus: IInvoiceStatus = {
  PAID: 'PAID',
  DRAFT: 'DRAFT',
  OPEN: 'OPEN',
  UNCOLLECTIBLE: 'UNCOLLECTIBLE',
  VOID: 'VOID',
};
export default invoiceStatus;
