import React from 'react';
import { useNavigate } from 'react-router-dom';

import { useCheckAuth } from '@/hooks/useCheckAuth';

import Loading from '@/components/Loading';
import LoadingOverlay from '@/components/LoadingOverlay';

import { useAppSelector } from '@/stores';

import { E_PATHS } from '@/helpers/constants/common.constant';

import Content from './content';
import Header from './header';
import Menu from './menu';
import { LayoutProps } from './PublicLayout';

const PrivateLayout: React.FC<LayoutProps> = () => {
  const { isAuth } = useCheckAuth();
  const navigate = useNavigate();
  const { loading } = useAppSelector((state) => state.auth);

  React.useEffect(() => {
    if (!isAuth) {
      const timeoutId = setTimeout(() => {
        navigate(E_PATHS._LOGIN);
      }, 0);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [isAuth]);

  return (
    <React.Fragment>
      <LoadingOverlay isLoading={loading} />
      <Loading />
      <div className='main-container d-flex'>
        <div className='main-left'>
          <Menu></Menu>
        </div>
        <div className='main-right d-flex flex-column'>
          <div className='main-right__header'>
            <Header></Header>
          </div>
          <div className='main-right__content'>
            <Content></Content>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PrivateLayout;
