import { ILocale } from '@/locales/locale.d';

export const locale_keys: ILocale['translation'] = {
  common: {
    title: 'common.title',
  },
  auth: {
    button: {
      login: 'auth.button.login',
      register: 'auth.button.register',
      forgotPassword: 'auth.button.forgotPassword',
      confirmOtp: 'auth.button.confirmOtp',
      resetPassword: 'auth.button.resetPassword',
    },
    placeholder: {
      email: 'auth.placeholder.email',
      password: 'auth.placeholder.password',
      confirmPassword: 'auth.placeholder.confirmPassword',
      otp: 'auth.placeholder.otp',
      fullname: 'auth.placeholder.fullname',
      username: 'auth.placeholder.username',
    },
    text: {
      login: 'auth.text.login',
      logout: 'auth.text.logout',
      register: 'auth.text.register',
      forgotPassword: 'auth.text.forgotPassword',
      resendOtp: 'auth.text.resendOtp',
      haveAccount: 'auth.text.haveAccount',
      notHaveAccount: 'auth.text.notHaveAccount',
      countDownOTP: 'auth.text.countDownOTP',
      notSeeOTP: 'auth.text.notSeeOTP',
    },
    title: {
      login: 'auth.title.login',
      register: 'auth.title.register',
      forgotPassword: 'auth.title.forgotPassword',
    },
    description: {
      confirmOtp: 'auth.description.confirmOtp',
      forgotPassword: 'auth.description.forgotPassword',
      login: 'auth.description.login',
      register: 'auth.description.register',
      resetPassword: 'auth.description.resetPassword',
    },
    welcomTitle: {
      confirmOtp: 'auth.welcomTitle.confirmOtp',
      forgotPassword: 'auth.welcomTitle.forgotPassword',
      login: 'auth.welcomTitle.login',
      register: 'auth.welcomTitle.register',
      resetPassword: 'auth.welcomTitle.resetPassword',
    },
    toast: {
      loginFailed: 'auth.toast.loginFailed',
      resetPwdSuccessful: 'auth.toast.resetPwdSuccessful',
      resendOTPSuccessful: 'auth.toast.resendOTPSuccessful',
    },
  },
  formRule: {
    required: 'formRule.required',
    passwordNotMatch: 'formRule.passwordNotMatch',
    number: 'formRule.number',
    email: 'formRule.email',
    passwordNotStrong: 'formRule.passwordNotStrong',
    username: 'formRule.username',
    fullname: 'formRule.fullname',
    passwordNotLong: 'formRule.passwordNotLong',
    userNameNotLong: 'formRule.userNameNotLong',
  },
  menu: {
    dashboard: 'menu.dashboard',
    userAccount: 'menu.userAccount',
    adminAccount: 'menu.adminAccount',
    payment: 'menu.payment',
    package: 'menu.package',
    function: 'menu.function',
    voting: 'menu.voting',
    posts: 'menu.posts',
    pollList: 'menu.pollList',
    pollConfig: 'menu.pollConfig',
    banWords: 'menu.banWords',
    pollCategory: 'menu.pollCategory',
    pollImage: 'menu.pollImage',
    advertisement: 'menu.advertisement',
    advertisementList: 'menu.advertisementList',
    advertisementRequest: 'menu.advertisementRequest',
    sales: 'menu.sales',
    site: 'menu.site',
    announcements: 'menu.announcements',
    inquiry: 'menu.inquiry',
    points: 'menu.points',
    pointConfigure: 'menu.pointConfigure',
    pointExchange: 'menu.pointExchange',
    report: 'menu.report',
    feedback: 'menu.feedback',
    dashboardHeading: 'menu.dashboardHeading',
    userAccountHeading: 'menu.userAccountHeading',
    adminAccountHeading: 'menu.adminAccountHeading',
    postListHeading: 'menu.postListHeading',
    pollListHeading: 'menu.pollListHeading',
    pollConfigHeading: 'menu.pollConfigHeading',
    pollCategoryHeading: 'menu.pollCategoryHeading',
    list: 'menu.list',
    request: 'menu.request',
  },
  filter: {
    filterBy: 'filter.filterBy',
    keyword: 'filter.keyword',
    keywordPlaceholder: 'filter.keywordPlaceholder',
    role: 'filter.role',
    apply: 'filter.apply',
    clear: 'filter.clear',
    create: 'filter.create',
    status: 'filter.status',
    submit: 'filter.submit',
    cancel: 'filter.cancel',
    delete: 'filter.delete',
    block: 'filter.block',
    active: 'filter.active',
    yes: 'filter.yes',
    no: 'filter.no',
    update: 'filter.update',
    userPlaceholder: 'filter.userPlaceholder',
    refresh: 'filter.refresh',
    save: 'filter.save',
  },
  user: {
    listUser: 'user.listUser',
    listAdmin: 'user.listAdmin',
    username: 'user.username',
    email: 'user.email',
    fullName: 'user.fullName',
    role: 'user.role',
    createAt: 'user.createAt',
    updateAt: 'user.updateAt',
    action: 'user.action',
    emailVerified: 'user.emailVerified',
    status: 'user.status',
    gender: 'user.gender',
    description: 'user.description',
    viewProfile: 'user.viewProfile',
    password: 'user.password',
    defaultPassword: 'user.defaultPassword',
    birthday: 'user.birthday',
    point: 'user.point',
    totalPoll: 'user.totalPoll',
    totalVote: 'user.totalVote',
    totalShare: 'user.totalShare',
    totalComment: 'user.totalComment',
    totalLike: 'user.totalLike',
    lastLoginIp: 'user.lastLoginIp',
    totalFollower: 'user.totalFollower',
    totalFollowing: 'user.totalFollowing',
    USER_FREE: 'user.USER_FREE',
    USER_PAID: 'user.USER_PAID',
    activeAccount: 'user.activeAccount',
    blockAccount: 'user.blockAccount',
    FEMALE: 'user.FEMALE',
    MALE: 'user.MALE',
    OTHER: 'user.OTHER',
    ADMIN: 'user.ADMIN',
    ALL: 'user.ALL',
    SUPER_ADMIN: 'user.SUPER_ADMIN',
    ANONYMOUS: 'user.ANONYMOUS',
    modal: {
      createAdminTitle: 'user.modal.createAdminTitle',
      detailAdminTitle: 'user.modal.detailAdminTitle',
      detailUserTitle: 'user.modal.detailUserTitle',
      personalInfor: 'user.modal.personalInfor',
      activityInfor: 'user.modal.activityInfor',
    },
    toast: {
      createAdminSuccess: 'user.toast.createAdminSuccess',
      deleteAdminSuccess: 'user.toast.deleteAdminSuccess',
      blockUserSuccess: 'user.toast.blockUserSuccess',
      activeUserSuccess: 'user.toast.activeUserSuccess',
    },
  },
  confirm: {
    createAdminTitle: 'confirm.createAdminTitle',
    deleteAdminTitle: 'confirm.deleteAdminTitle',
    blockUserTitle: 'confirm.blockUserTitle',
    activeUserTitle: 'confirm.activeUserTitle',
    createAdminDesc: 'confirm.createAdminDesc',
    deleteAdminDesc: 'confirm.deleteAdminDesc',
    blockUserDesc: 'confirm.blockUserDesc',
    activeUserDesc: 'confirm.activeUserDesc',
  },
  heading: {
    login: 'heading.login',
    forgotPwd: 'heading.forgotPwd',
    confirmOTP: 'heading.confirmOTP',
    resetPwd: 'heading.resetPwd',
    dashboard: 'heading.dashboard',
    adminAccount: 'heading.adminAccount',
    userAccount: 'heading.userAccount',
  },
  required: {
    username: 'required.username',
    password: 'required.password',
    defaultPwd: 'required.defaultPwd',
    fullName: 'required.fullName',
    email: 'required.email',
  },
  report: {
    listReport: 'report.listReport',
    type: 'report.type',
    reporter: 'report.reporter',
    reporterEmail: 'report.reporterEmail',
    keywordPlaceholder: 'report.keywordPlaceholder',
    reportType: {
      ALL: 'report.reportType.ALL',
      SEXUAL_CONTENT: 'report.reportType.SEXUAL_CONTENT',
      VIOLENT_CONTENT: 'report.reportType.VIOLENT_CONTENT',
      HATEFUL_CONTENT: 'report.reportType.HATEFUL_CONTENT',
      HARASSMENT_OR_BULLYING: 'report.reportType.HARASSMENT_OR_BULLYING',
      HARMFUL_ACTS: 'report.reportType.HARMFUL_ACTS',
      MISINFORMATION: 'report.reportType.MISINFORMATION',
      CHILD_ABUSE: 'report.reportType.CHILD_ABUSE',
      PROMOTES_TERRORISM: 'report.reportType.PROMOTES_TERRORISM',
      SPAM_OR_MISLEADING: 'report.reportType.SPAM_OR_MISLEADING',
    },
    detail: {
      title: 'report.detail.title',
      reporterInfo: 'report.detail.reporterInfo',
      block: 'report.detail.block',
      pollInfo: 'report.detail.pollInfo',
      blockPoll: 'report.detail.blockPoll',
      deleteReport: 'report.detail.deleteReport',
      reason: 'report.detail.reason',
      activePoll: 'report.detail.activePoll',
      confirm: {
        block: 'report.detail.confirm.block',
        contentBlock: 'report.detail.confirm.contentBlock',
        active: 'report.detail.confirm.active',
        contentActive: 'report.detail.confirm.contentActive',
        delete: 'report.detail.confirm.delete',
        contentDelete: 'report.detail.confirm.contentDelete',
      },
      toastr: {
        deleteSuccessful: 'report.detail.toastr.deleteSuccessful',
        successful: 'report.detail.toastr.successful',
      },
    },
  },
  sale: {
    listSale: 'sale.listSale',
    id: 'sale.id',
    plan: 'sale.plan',
    type: 'sale.type',
    amount: 'sale.amount',
    status: 'sale.status',
    userRequest: 'sale.userRequest',
    created: 'sale.created',
  },
  exchange: {
    listExchange: 'exchange.listExchange',
    gift: 'exchange.gift',
    type: 'exchange.type',
    description: 'exchange.description',
    status: 'exchange.status',
    created: 'exchange.created',
    statusType: {
      SUCCESS: 'exchange.statusType.SUCCESS',
      PENDING: 'exchange.statusType.PENDING',
      REJECTED: 'exchange.statusType.REJECTED',
    },
    giftType: 'exchange.giftType',
    user: 'exchange.user',
    detail: {
      title: 'exchange.detail.title',
      moveToInprogress: 'exchange.detail.moveToInprogress',
      markSuccess: 'exchange.detail.markSuccess',
      giftInfo: 'exchange.detail.giftInfo',
      fullName: 'exchange.detail.fullName',
      confirm: {
        update: 'exchange.detail.confirm.update',
        contentUpdate: 'exchange.detail.confirm.contentUpdate',
      },
      toastr: {
        updateSuccessful: 'exchange.detail.toastr.updateSuccessful',
      },
    },
  },
  dashboard: {
    user: 'dashboard.user',
    purchase: 'dashboard.purchase',
    counter: {
      totalUser: 'dashboard.counter.totalUser',
      totalPost: 'dashboard.counter.totalPost',
      totalBalanceJapan: 'dashboard.counter.totalBalanceJapan',
      totalBalanceOther: 'dashboard.counter.totalBalanceOther',
      totalPollJapan: 'dashboard.counter.totalPollJapan',
      totalPollOther: 'dashboard.counter.totalPollOther',
    },
    postDetail: {
      title: 'dashboard.postDetail.title',
      post: 'dashboard.postDetail.post',
    },
    socialUser: {
      title: 'dashboard.socialUser.title',
      facebook: 'dashboard.socialUser.facebook',
      google: 'dashboard.socialUser.google',
      line: 'dashboard.socialUser.line',
      twitter: 'dashboard.socialUser.twitter',
    },
    planPurchase: {
      title: 'dashboard.planPurchase.title',
      advanced: 'dashboard.planPurchase.advanced',
      basic: 'dashboard.planPurchase.basic',
      monthly: 'dashboard.planPurchase.monthly',
      pro: 'dashboard.planPurchase.pro',
      yearly: 'dashboard.planPurchase.yearly',
    },
    userAndPayment: {
      title: 'dashboard.userAndPayment.title',
      anonymous: 'dashboard.userAndPayment.anonymous',
      free: 'dashboard.userAndPayment.free',
      paid: 'dashboard.userAndPayment.paid',
    },
  },
  voteManagement: {
    pollList: {
      listUser: 'voteManagement.pollList.listUser',
      title: 'voteManagement.pollList.title',
      author: 'voteManagement.pollList.author',
      category: 'voteManagement.pollList.category',
      recommend: 'voteManagement.pollList.recommend',
      status: 'voteManagement.pollList.status',
      type: 'voteManagement.pollList.type',
      viewDetail: 'voteManagement.pollList.viewDetail',
      listEmail: 'voteManagement.pollList.listEmail',
      voteType: {
        ALL: 'voteManagement.pollList.voteType.ALL',
        // RECOMMEND_BY_ADMIN:
        //   'voteManagement.pollList.voteType.RECOMMEND_BY_ADMIN',
        // TOP_VOTE: 'voteManagement.pollList.voteType.TOP_VOTE',
        // TOP_LIKE: 'voteManagement.pollList.voteType.TOP_LIKE',
        // TOP_COMMENT: 'voteManagement.pollList.voteType.TOP_COMMENT',
        // PRIVATE: 'voteManagement.pollList.voteType.PRIVATE',
        // CLOSED: 'voteManagement.pollList.voteType.CLOSED',
        PUBLIC: 'voteManagement.pollList.voteType.PUBLIC',
        PRIVATE: 'voteManagement.pollList.voteType.PRIVATE',
      },
      process: 'voteManagement.pollList.process',
      processStatus: {
        PENDING: 'voteManagement.pollList.processStatus.PENDING',
        PROCESSING: 'voteManagement.pollList.processStatus.PROCESSING',
        COMPLETED: 'voteManagement.pollList.processStatus.COMPLETED',
        FAILED: 'voteManagement.pollList.processStatus.FAILED',
      },
      detail: {
        title: 'voteManagement.pollList.detail.title',
        recommend: 'voteManagement.pollList.detail.recommend',
        blockPoll: 'voteManagement.pollList.detail.blockPoll',
        published: 'voteManagement.pollList.detail.published',
        like: 'voteManagement.pollList.detail.like',
        comment: 'voteManagement.pollList.detail.comment',
        removeRecommend: 'voteManagement.pollList.detail.removeRecommend',
        activePoll: 'voteManagement.pollList.detail.activePoll',
        rejectPoll: 'voteManagement.pollList.detail.rejectPoll',
        owner: 'voteManagement.pollList.detail.rejectPoll',
        question: 'voteManagement.pollList.detail.question',
        vote: 'voteManagement.pollList.detail.vote',
        peopleChoice: 'voteManagement.pollList.detail.peopleChoice',
        confirm: {
          block: 'voteManagement.pollList.detail.confirm.block',
          contentBlock: 'voteManagement.pollList.detail.confirm.contentBlock',
          reject: 'voteManagement.pollList.detail.confirm.reject',
          contentReject: 'voteManagement.pollList.confirm.contentReject',
          active: 'voteManagement.pollList.detail.confirm.active',
          contentActive: 'voteManagement.pollList.detail.confirm.contentActive',
          recommend: 'voteManagement.pollList.detail.confirm.recommend',
          contentRecommend:
            'voteManagement.pollList.detail.confirm.contentRecommend',
          removeRecommend:
            'voteManagement.pollList.detail.confirm.removeRecommend',
          removeContentRecommend:
            'voteManagement.pollList.detail.confirm.removeContentRecommend',
        },
        toastr: {
          activeSuccessful:
            'voteManagement.pollList.detail.toastr.activeSuccessful',
          recommentSuccessful:
            'voteManagement.pollList.detail.toastr.recommentSuccessful',
          removeRecommentSuccessful:
            'voteManagement.pollList.detail.toastr.removeRecommentSuccessful',
          blockSuccessful:
            'voteManagement.pollList.detail.toastr.blockSuccessful',
        },
        sampleVideo: 'voteManagement.pollList.detail.sampleVideo',
        practiceVideo: 'voteManagement.pollList.detail.practiceVideo',
        aiScore: 'voteManagement.pollList.detail.aiScore',
        view: 'voteManagement.pollList.detail.view',
      },
    },
    pollConfiguration: {
      freeUser: 'voteManagement.pollConfiguration.freeUser',
      paidUser: 'voteManagement.pollConfiguration.paidUser',
      mediaUploadSize: 'voteManagement.pollConfiguration.mediaUploadSize',
      mediaUploadSizeDes: 'voteManagement.pollConfiguration.mediaUploadSizeDes',
      pointCreate: 'voteManagement.pollConfiguration.pointCreate',
      pointCreateDes: 'voteManagement.pollConfiguration.pointCreateDes',
      pointReceive: 'voteManagement.pollConfiguration.pointReceive',
      pointReceiveDes: 'voteManagement.pollConfiguration.pointReceiveDes',
      pointVoteOther: 'voteManagement.pollConfiguration.pointVoteOther',
      pointVoteOtherDes: 'voteManagement.pollConfiguration.pointVoteOtherDes',
      maxSize: 'voteManagement.pollConfiguration.maxSize',
      refresh: 'voteManagement.pollConfiguration.refresh',
      save: 'voteManagement.pollConfiguration.save',
      confirm: {
        update: 'voteManagement.pollConfiguration.confirm.update',
        contentUpdate: 'voteManagement.pollConfiguration.confirm.contentUpdate',
      },
      toastr: {
        updateSuccessful:
          'voteManagement.pollConfiguration.toastr.updateSuccessful',
      },
    },
    banWord: {
      title: 'voteManagement.banWord.title',
      createPlaceholder: 'voteManagement.banWord.createPlaceholder',
      banWordList: 'voteManagement.banWord.banWordList',
      createBanWordSuccessful: 'voteManagement.banWord.createBanWordSuccessful',
      removeBanWordSuccessful: 'voteManagement.banWord.removeBanWordSuccessful',
    },
    category: {
      name: 'voteManagement.category.name',
      addNewSub: 'voteManagement.category.addNewSub',
      categoryList: 'voteManagement.category.categoryList',
      englishName: 'voteManagement.category.englishName',
      englishNameDes: 'voteManagement.category.englishNameDes',
      japanName: 'voteManagement.category.japanName',
      japanNameDes: 'voteManagement.category.japanNameDes',
      title: 'voteManagement.category.title',
      englishSub: 'voteManagement.category.englishSub',
      japanSub: 'voteManagement.category.japanSub',
      japanTitleRequired: 'voteManagement.category.japanTitleRequired',
      toastr: {
        updateSuccessful: 'voteManagement.category.toastr.updateSuccessful',
        deleteSuccessful: 'voteManagement.category.toastr.deleteSuccessful',
        createSubSuccessful:
          'voteManagement.category.toastr.createSubSuccessful',
        createSuccessful:
          'voteManagement.category.toastr.createCategorySuccessful',
      },
      confirm: {
        create: 'voteManagement.category.confirm.create',
        contentCreate: 'voteManagement.category.confirm.contentCreate',
        contentDelete: 'voteManagement.category.confirm.contentDelete',
        delete: 'voteManagement.category.confirm.delete',
        contentCreateSub: 'voteManagement.category.confirm.contentCreateSub',
        createSub: 'voteManagement.category.confirm.createSub',
        contentUpdate: 'voteManagement.category.confirm.contentUpdate',
        update: 'voteManagement.category.confirm.update',
      },
      englishCategory: 'voteManagement.category.englishCategory',
      englishSubCategory: 'voteManagement.category.englishSubCategory',
      japanCategory: 'voteManagement.category.japanCategory',
      japanSubCategory: 'voteManagement.category.japanSubCategory',
    },
    imageDefault: {
      title: 'voteManagement.imageDefault.title',
      listImage: 'voteManagement.imageDefault.listImage',
      selectFile: 'voteManagement.imageDefault.selectFile',
      uploading: 'voteManagement.imageDefault.uploading',
      startUpload: 'voteManagement.imageDefault.startUpload',
      confirm: {
        delete: 'voteManagement.imageDefault.confirm.delete',
        contentDelete: 'voteManagement.imageDefault.confirm.contentDelete',
      },
      toastr: {
        createSuccessful: 'voteManagement.imageDefault.toastr.createSuccessful',
        deleteSuccessful: 'voteManagement.imageDefault.toastr.deleteSuccessful',
        uploadSizeWarning:
          'voteManagement.imageDefault.toastr.uploadSizeWarning',
        uploadTypeWarning:
          'voteManagement.imageDefault.toastr.uploadTypeWarning',
      },
    },
  },
  advertisement: {
    description: 'advertisement.description',
    end: 'advertisement.end',
    listAds: 'advertisement.listAds',
    location: 'advertisement.location',
    start: 'advertisement.start',
    viewDetail: 'advertisement.viewDetail',
    keywordPlaceholder: 'advertisement.keywordPlaceholder',
    url: 'advertisement.url',
    detail: {
      title: 'advertisement.detail.title',
      listCreateTitle: 'advertisement.detail.listCreateTitle',
      requestTitle: 'advertisement.detail.requestTitle',
      descriptionPlaceholder: 'advertisement.detail.descriptionPlaceholder',
      urlPlaceholder: 'advertisement.detail.urlPlaceholder',
      startEndDate: 'advertisement.detail.startEndDate',
      startPlaceholder: 'advertisement.detail.startPlaceholder',
      endPlaceholder: 'advertisement.detail.endPlaceholder',
      timeDisplay: 'advertisement.detail.timeDisplay',
      timeDisplayPlaceholder: 'advertisement.detail.timeDisplayPlaceholder',
      verticalBanner: 'advertisement.detail.verticalBanner',
      uploadVideo: 'advertisement.detail.uploadVideo',
      horizontalBanner: 'advertisement.detail.horizontalBanner',
      selectImage: 'advertisement.detail.selectImage',
      selectVideo: 'advertisement.detail.selectVideo',
      download: 'advertisement.detail.download',
      toastr: {
        createSuccessful: 'advertisement.detail.toastr.createSuccessful',
        updateSuccessful: 'advertisement.detail.toastr.updateSuccessful',
        deleteSuccessful: 'advertisement.detail.toastr.deleteSuccessful',
        typeVideoWarning: 'advertisement.detail.toastr.typeVideoWarning',
        minSizeVideo: 'advertisement.detail.toastr.minSizeVideo',
      },
      confirm: {
        delete: 'advertisement.detail.confirm.delete',
        contentDelete: 'advertisement.detail.confirm.contentDelete',
      },
      required: {
        input: 'advertisement.detail.required.input',
        upload: 'advertisement.detail.required.input',
      },
    },
  },
  announcement: {
    listAn: 'announcement.listAn',
    createTitle: 'announcement.createTitle',
    detailTitle: 'announcement.detailTitle',
    titlePlaceholder: 'announcement.titlePlaceholder',
    titleFilterPlaceholder: 'announcement.titleFilterPlaceholder',
    minDescription: 'announcement.minDescription',
    maxDescription: 'announcement.maxDescription',
    minCharacter: 'announcement.minCharacter',
    maxCharacter: 'announcement.maxCharacter',
    toastr: {
      updateSuccessful: 'announcement.toastr.updateSuccessful',
      createSuccessful: 'announcement.toastr.createSuccessful',
      deleteSuccessful: 'announcement.toastr.deleteSuccessful',
    },
    confirm: {
      delete: 'announcement.confirm.delete',
      contentDelete: 'announcement.confirm.contentDelete',
    },
  },
  siteInformation: {
    about: 'siteInformation.about',
    privacyPolicy: 'siteInformation.privacyPolicy',
    termOfService: 'siteInformation.termOfService',
    descriptionMaxlength: 'siteInformation.descriptionMaxlength',
    english: 'siteInformation.english',
    updateAboutSuccessful: 'siteInformation.updateAboutSuccessful',
    updatePrivacySuccessful: 'siteInformation.updatePrivacySuccessful',
    updateTermSuccessful: 'siteInformation.updateTermSuccessful',
    japanese: 'siteInformation.japanese',
  },
  paymentManagement: {
    packageConfig: {
      basicTitle: 'paymentManagement.packageConfig.basicTitle',
      proTitle: 'paymentManagement.packageConfig.proTitle',
      advancedTitle: 'paymentManagement.packageConfig.advancedTitle',
      japan: 'paymentManagement.packageConfig.japan',
      monthPrice: 'paymentManagement.packageConfig.monthPrice',
      yearPrice: 'paymentManagement.packageConfig.yearPrice',
      otherNation: 'paymentManagement.packageConfig.otherNation',
      usd: 'paymentManagement.packageConfig.usd',
      yen: 'paymentManagement.packageConfig.yen',
      toastr: {
        updateSuccessful:
          'paymentManagement.packageConfig.toastr.updateSuccessful',
      },
    },
    campaignConfig: {
      title: 'paymentManagement.campaignConfig.title',
      period: 'paymentManagement.campaignConfig.period',
      history: 'paymentManagement.campaignConfig.history',
      titleHistory: 'paymentManagement.campaignConfig.titleHistory',
      dateStart: 'paymentManagement.campaignConfig.dateStart',
      dateEnd: 'paymentManagement.campaignConfig.dateEnd',
      no: 'paymentManagement.campaignConfig.no',
    },
    functionConfig: {
      listFunction: 'paymentManagement.functionConfig.listFunction',
      action: 'paymentManagement.functionConfig.action',
      anonymous: 'paymentManagement.functionConfig.anonymous',
      userFree: 'paymentManagement.functionConfig.userFree',
      paidUser: 'paymentManagement.functionConfig.paidUser',
      limitPost: 'paymentManagement.functionConfig.limitPost',
      uploadVideo: 'paymentManagement.functionConfig.uploadVideo',
      uploadMediaOption: 'paymentManagement.functionConfig.uploadMediaOption',
      addOption: 'paymentManagement.functionConfig.addOption',
      comment: 'paymentManagement.functionConfig.comment',
      uploadMediaComment: 'paymentManagement.functionConfig.uploadMediaComment',
      uploadCSV: 'paymentManagement.functionConfig.uploadCSV',
      limitEmail: 'paymentManagement.functionConfig.limitEmail',
      toastr: {
        updateSuccessful:
          'paymentManagement.functionConfig.toastr.updateSuccessful',
      },
    },
  },
  saleManagement: {
    listPayment: 'saleManagement.listPayment',
    paymentId: 'saleManagement.paymentId',
    amount: 'saleManagement.amount',
    username: 'saleManagement.username',
    plan: 'saleManagement.plan',
    type: 'saleManagement.type',
    dateOrder: 'saleManagement.dateOrder',
    periodEnd: 'saleManagement.periodEnd',
    orderStatus: 'saleManagement.orderStatus',
    totalBalance: 'saleManagement.totalBalance',
    detail: {
      planType: 'saleManagement.detail.planType',
      title: 'saleManagement.detail.title',
      cancelPlan: 'saleManagement.detail.cancelPlan',
      userInformation: 'saleManagement.detail.userInformation',
      userStatus: 'saleManagement.detail.userStatus',
      paymentInfo: 'saleManagement.detail.paymentInfo',
      toastr: {
        cancelSuccessful: 'saleManagement.detail.toastr.cancelSuccessful',
        error: 'saleManagement.detail.toastr.error',
      },
      confirm: {
        contentCancel: 'saleManagement.detail.confirm.contentCancel',
        text: 'saleManagement.detail.confirm.text',
      },
      userRequest: 'saleManagement.detail.userRequest',
      requestCancel: 'saleManagement.detail.requestCancel',
      currentCycleStart: 'saleManagement.detail.currentCycleStart',
      nextCycleCharge: 'saleManagement.detail.nextCycleCharge',
      invoiceNumber: 'saleManagement.detail.invoiceNumber',
      amountPaid: 'saleManagement.detail.amountPaid',
      chargeMethod: 'saleManagement.detail.chargeMethod',
      paymentMethod: 'saleManagement.detail.paymentMethod',
      lastDigits: 'saleManagement.detail.lastDigits',
      invoiceUrl: 'saleManagement.detail.invoiceUrl',
      link: 'saleManagement.detail.link',
      lastestInvoice: 'saleManagement.detail.lastestInvoice',
    },
  },
  feedbackManagement: {
    inquiry: {
      viewDetail: 'feedbackManagement.inquiry.viewDetail',
      listInquiry: 'feedbackManagement.inquiry.listInquiry',
      keywordFilterPlaceholder:
        'feedbackManagement.inquiry.keywordFilterPlaceholder',
      detail: {
        title: 'feedbackManagement.inquiry.detail.title',
        toastr: {
          deleteSuccessful:
            'feedbackManagement.inquiry.detail.toastr.deleteSuccessful',
        },
        confirm: {
          delete: 'feedbackManagement.inquiry.detail.confirm.delete',
          contentDelete:
            'feedbackManagement.inquiry.detail.confirm.contentDelete',
        },
      },
    },
  },
  pointAndGift: {
    config: {
      pointSetting: 'pointAndGift.config.pointSetting',
      other: 'pointAndGift.config.other',
      japan: 'pointAndGift.config.japan',
      voucherList: 'pointAndGift.config.voucherList',
      voucherDetail: 'pointAndGift.config.voucherDetail',
      point: 'pointAndGift.config.point',
      titlePlaceholder: 'pointAndGift.config.titlePlaceholder',
      pointPlaceholder: 'pointAndGift.config.pointPlaceholder',
      wheelSetting: 'pointAndGift.config.wheelSetting',
      pointForSpin: 'pointAndGift.config.pointForSpin',
      giftList: 'pointAndGift.config.giftList',
      giftDetail: 'pointAndGift.config.giftDetail',
      rate: 'pointAndGift.config.rate',
      add: 'pointAndGift.config.add',
      confirm: {
        deleteVoucher: 'pointAndGift.config.confirm.deleteVoucher',
        contentDeleteVoucher:
          'pointAndGift.config.confirm.contentDeleteVoucher',
        update: 'pointAndGift.config.confirm.update',
        contentUpdate: 'pointAndGift.config.confirm.contentUpdate',
        deleteGift: 'pointAndGift.config.confirm.deleteGift',
        contentDeleteGift: 'pointAndGift.config.confirm.contentDeleteGift',
        contentUpdateSpin: 'pointAndGift.config.confirm.contentUpdateSpin',
      },
      toastr: {
        deleteVoucherSuccessful:
          'pointAndGift.config.toastr.deleteVoucherSuccessful',
        deleteGiftSuccessful: 'pointAndGift.config.toastr.deleteGiftSuccessful',
        updateSuccessful: 'pointAndGift.config.toastr.updateSuccessful',
        maxVoucherWarning: 'pointAndGift.config.toastr.maxVoucherWarning',
        createSuccessful: 'pointAndGift.config.toastr.createSuccessful',
        totalWheelWarning: 'pointAndGift.config.toastr.totalWheelWarning',
        updateSpinSuccessful: 'pointAndGift.config.toastr.updateSpinSuccessful',
      },
    },
  },
  status: {
    ALL: 'status.ALL',
    ACTIVE: 'status.ACTIVE',
    PENDING: 'status.PENDING',
    BLOCKED: 'status.BLOCKED',
    REJECTED: 'status.REJECTED',
    BLOCK: 'status.BLOCK',
    INACTIVE: 'status.INACTIVE',
    CANCELED: 'status.CANCELED',
    INCOMPLETE: 'status.INCOMPLETE',
    IN_PROGRESS: 'status.IN_PROGRESS',
    COMPLETED: 'status.COMPLETED',
    CANCEL: 'status.CANCEL',
    PAID: 'status.PAID',
    UNPAID: 'status.UNPAID',
    PAST_DUE: 'status.PAST_DUE',
    INCOMPLETE_EXPIRED: 'status.INCOMPLETE_EXPIRED',
  },
  location: {
    ALL: 'location.ALL',
    JAPAN: 'location.JAPAN',
    OTHER: 'location.OTHER',
  },
  pollType: {
    PUBLIC: 'pollType.PUBLIC',
    PRIVATE: 'pollType.PRIVATE',
  },
  invoiceStatus: {
    PAID: 'invoiceStatus.PAID',
    DRAFT: 'invoiceStatus.DRAFT',
    OPEN: 'invoiceStatus.OPEN',
    UNCOLLECTIBLE: 'invoiceStatus.UNCOLLECTIBLE',
    VOID: 'invoiceStatus.VOID',
  },
  giftType: {
    ALL: 'giftType.ALL',
    VOUCHER: 'giftType.VOUCHER',
    WHEEL: 'giftType.WHEEL',
  },
  validation: {
    fieldRequired: 'validation.fieldRequired',
    nonNegativeNumber: 'validation.nonNegativeNumber',
    valueAsInteger: 'validation.valueAsInteger',
    invalidUrl: 'validation.invalidUrl',
    negativeNumber: 'validation.negativeNumber',
  },
};
