import { Button, Image } from 'antd';
import classNames from 'classnames';
import React from 'react';
import { IoMdRadioButtonOff } from 'react-icons/io';
import '@/styles/components/voting/live-view/survey.scss';

import VideoPlayer from '@/components/VideoPlayer';

import { E_TYPE_MEDIA } from '@/helpers/constants/voting.constant';
import { generateImgCanvasURLFromText } from '@/helpers/functions/canvas.helper';
import { translate } from '@/helpers/functions/language.helper';
import { locale_keys } from '@/helpers/locales/locale.key';

const Survey = ({ attachmentType, attachmentUrl, options }: any) => {
  options = React.useMemo(() => {
    return options.map((option: any) => {
      const sortedOptions = option.options.sort((a: any, b: any) => {
        return b.votes - a.votes;
      });

      return {
        ...option,
        options: sortedOptions,
      };
    });
  }, [options]);

  return (
    <div className='survey__options p-0 d-flex flex-column'>
      <div className='survey__options-media d-flex align-items-center justify-content-center'>
        {attachmentType === E_TYPE_MEDIA._IMAGE ? (
          <Image
            src={process.env.REACT_APP_PUBLIC_S3_URL + attachmentUrl}
            height={'100%'}
            width={'100%'}
            alt=''
          />
        ) : (
          <VideoPlayer
            url={process.env.REACT_APP_PUBLIC_S3_URL + attachmentUrl}
            view={true}
            height={'100%'}
            width={'100%'}
          />
        )}
      </div>

      <div className='survey__options-content d-flex flex-column'>
        {options.map((option: any, index: number) => (
          <div
            className='survey__options-content-item d-flex flex-row'
            key={index}
          >
            <div className='survey__options-content-item-question d-flex flex-column w-full'>
              <div className='survey__options-content-item-question-title'>
                <span>
                  {' '}
                  {translate(
                    locale_keys.voteManagement.pollList.detail.question
                  )}
                  {index + 1}
                </span>
                <span> - </span>
                <span>{option.title}</span>
              </div>
              <div className='survey__options-content-item-question-option d-flex flex-column align-items-start'>
                {option.options.map((op: any, indexOp: number) => (
                  <div
                    className={classNames([
                      'survey__options-content-item-question-option-item d-flex flex-row align-items-start justify-content-between w-full',
                      `color-${indexOp + 1}`,
                    ])}
                    key={indexOp}
                  >
                    {option.options.filter((o: any) => o.attachmentUrl).length >
                      0 && (
                      <div className='survey__options-content-item-image'>
                        {op.attachmentType === E_TYPE_MEDIA._IMAGE ||
                        !op.attachmentType ? (
                          <Image
                            src={
                              op.attachmentUrl
                                ? process.env.REACT_APP_PUBLIC_S3_URL +
                                  op.attachmentUrl
                                : generateImgCanvasURLFromText(
                                    op.title,
                                    210,
                                    128,
                                    '#212121'
                                  )
                            }
                            height={128}
                            width={210}
                            alt=''
                          />
                        ) : (
                          <VideoPlayer
                            url={
                              process.env.REACT_APP_PUBLIC_S3_URL +
                              op.attachmentUrl
                            }
                            view={true}
                            height={128}
                            width={210}
                          />
                        )}
                      </div>
                    )}
                    {option.options.filter((o: any) => o.attachmentUrl).length >
                    0 ? (
                      <React.Fragment>
                        <div className='d-flex flex-column justify-content-between w-full gap-3 py-2'>
                          <span className='survey__options-content-item-question-option-title'>
                            {op.title}
                          </span>
                          <div className='d-flex flex-row justify-content-between align-items-center w-full'>
                            <span className='number'>{indexOp + 1}</span>
                            <span>
                              {op?.votes}{' '}
                              {translate(
                                locale_keys.voteManagement.pollList.detail
                                  .peopleChoice
                              )}{' '}
                              (0%)
                            </span>
                            <Button>
                              {<IoMdRadioButtonOff size={24} />}
                              <span>
                                {translate(
                                  locale_keys.voteManagement.pollList.detail
                                    .vote
                                )}
                              </span>
                            </Button>
                          </div>
                        </div>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <span className='number'>{indexOp + 1}</span>
                        <div className='d-flex flex-row justify-content-between w-full gap-3 option'>
                          <div className='d-flex flex-row justify-content-between align-items-center w-full'>
                            <span>{op.title}</span>
                            <span>
                              {op?.votes}{' '}
                              {translate(
                                locale_keys.voteManagement.pollList.detail
                                  .peopleChoice
                              )}{' '}
                              (0%)
                            </span>
                          </div>
                        </div>

                        <Button>
                          {<IoMdRadioButtonOff size={24} />}

                          <span>
                            {translate(
                              locale_keys.voteManagement.pollList.detail.vote
                            )}
                          </span>
                        </Button>
                      </React.Fragment>
                    )}
                  </div>
                ))}
              </div>

              {/* {option.allowUserAddOptions && (
                <div className='d-flex flex-row w-full gap-2 align-items-center justify-content-between'>
                  <strong
                    style={{
                      padding: '12px',
                      background: '#f4f4f2',
                      borderRadius: '8px',
                      height: '48px',
                      width: '100%',
                    }}
                  >
                    Write your option
                  </strong>
                  <span
                    style={{
                      padding: '12px',
                      background: '#f4f4f2',
                      borderRadius: '8px',
                      height: '48px',
                      width: '48px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      color: 'rgba(0, 0, 0, 0.6)',
                    }}
                  >
                    <BiChevronDown size={48} />
                  </span>
                  <Button
                    disabled
                    className='text-uppercase'
                    style={{
                      height: '48px',
                      whiteSpace: 'nowrap',
                      padding: '12px',
                      width: '108px',
                      background: 'rgba(0, 0, 0, 0.4)',
                      border: 'none',
                      color: '#fff',
                    }}
                  >
                    <BiPlus /> Add
                  </Button>
                </div>
              )} */}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Survey;
