import { IFilter } from '../locale';

const filter: IFilter = {
  filterBy: 'Filter By',
  keyword: 'Keyword',
  keywordPlaceholder: 'Full name, Username or Email',
  role: 'Role',
  apply: 'Search',
  clear: 'Clear',
  create: 'Create',
  status: 'Status',
  submit: 'Submit',
  cancel: 'Cancel',
  delete: 'Delete',
  block: 'Block',
  active: 'Active',
  yes: 'Yes',
  no: 'No',
  update: 'Update',
  userPlaceholder: 'Username or email',
  refresh: 'Refresh',
  save: 'Save',
};

export default filter;
