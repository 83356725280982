import { IConfirm } from '../locale';

const confirm: IConfirm = {
  createAdminTitle: 'Create new admin account?',
  deleteAdminTitle: 'Delete Admin account?',
  blockUserTitle: 'Block User account?',
  activeUserTitle: 'Active User account?',
  createAdminDesc: 'Are you sure you want to create new admin account?',
  deleteAdminDesc: 'Are you sure you want to delete this admin account?',
  blockUserDesc: 'Are you sure you want to block this user account?',
  activeUserDesc: 'Are you sure you want to active this user account?',
};

export default confirm;
