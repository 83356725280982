import { IAdvertisement } from '../locale';

const advertisement: IAdvertisement = {
  listAds: '広告一覧',
  description: '内容',
  location: 'ロケーション',
  start: '開始',
  end: '終了',
  viewDetail: '詳細',
  keywordPlaceholder: 'キーワード',
  url: 'URL',
  detail: {
    title: '広告詳細',
    listCreateTitle: '広告作成',
    requestTitle: '広告リクエストの詳細情報',
    descriptionPlaceholder: '広告内容を入力してください',
    urlPlaceholder: '広告のURL',
    startEndDate: '開始日 - 終了日',
    startPlaceholder: '開始日',
    endPlaceholder: '終了日',
    timeDisplay: '表示時間 / 一回 (秒)',
    timeDisplayPlaceholder: '表示時間',
    verticalBanner: '縦型画像',
    uploadVideo: '動画',
    horizontalBanner: '横型画像',
    selectImage: '画像アップロード',
    selectVideo: '動画アップロード',
    download: 'ダウンロード',
    toastr: {
      createSuccessful: '広告が作成されました。',
      updateSuccessful: '広告が更新されました。',
      deleteSuccessful: '広告が削除されました。',
      typeVideoWarning:
        'アップロードしたファイルのフォーマットは有効ではありません。',
      minSizeVideo: '動画は20MB以内の動画をアップロードしてください。',
    },
    confirm: {
      delete: 'リクエスト削除',
      contentDelete: 'この広告のリクエストを削除してよろしいでしょうか。',
    },
    required: {
      input: 'この項目は必須です。',
      upload: '画像か動画をアップロードするのが必須です。',
    },
  },
};
export default advertisement;
