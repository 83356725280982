import { IFeedbackManagement } from '../locale';

const feedbackManagement: IFeedbackManagement = {
  inquiry: {
    viewDetail: '詳細',
    listInquiry: '問い合わせのリスト',
    keywordFilterPlaceholder: 'タイトルとメールで検索',
    detail: {
      title: '問い合わせの詳細',
      toastr: {
        deleteSuccessful: '問い合わせの削除が成功しました',
      },
      confirm: {
        delete: '問い合わせの削除',
        contentDelete: 'この問い合わせを本当に削除しますか？',
      },
    },
  },
};

export default feedbackManagement;
