import { Col, Row } from 'antd';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import '@/styles/pages/gift-configure.scss';

import useDocumentTitle from '@/hooks/useDocumentTitle';

import { translate } from '@/helpers/functions/language.helper';
import { locale_keys } from '@/helpers/locales/locale.key';

import GiftSetting from './gift-setting';
import WheelSetting from './wheel-setting';

const GiftConfiguration = () => {
  const location = useLocation();
  useDocumentTitle(translate(locale_keys.menu.pointConfigure));
  useEffect(() => {
    useDocumentTitle;
  }, [location]);
  return (
    <div className='package'>
      <Row gutter={24}>
        <Col span={12}>
          <GiftSetting />
        </Col>
        <Col span={12}>
          <WheelSetting />
        </Col>
      </Row>
    </div>
  );
};

export default GiftConfiguration;
