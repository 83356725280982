import { ErrorMessage } from '@hookform/error-message';
import { Divider, Input } from 'antd';
import classNames from 'classnames';
import { Controller } from 'react-hook-form';

import {
  validateNegativeNumber,
  validateNonNegativeNumber,
} from '@/helpers/functions/form-validation.helper';
import { translate } from '@/helpers/functions/language.helper';
import { locale_keys } from '@/helpers/locales/locale.key';

interface PlansDetailProps {
  keyName: string;
  control: any;
  register: any;
  errors: any;
}

const PlansDetail = ({
  keyName,
  control,
  register,
  errors,
}: PlansDetailProps) => {
  return (
    <div id='basic-form'>
      <Divider orientation='left'>
        {translate(locale_keys.paymentManagement.packageConfig.japan)}
      </Divider>
      <div className='d-flex justify-content-between'>
        <div style={{ width: '45%' }}>
          <label htmlFor='type' className='form-label filter__form-label'>
            {translate(locale_keys.paymentManagement.packageConfig.monthPrice)}
            {translate(locale_keys.paymentManagement.packageConfig.yen)}
          </label>
          <div>
            <Controller
              name={`${keyName}.monthly.priceJp`}
              control={control}
              rules={{ validate: validateNonNegativeNumber }}
              render={({ field }) => (
                <Input
                  size='large'
                  value={field.value}
                  placeholder='JPY'
                  className={classNames([''], {
                    ['--error']: errors[`${keyName}.monthly.priceJp`],
                  })}
                  {...register(`${keyName}.monthly.priceJp`, {
                    required: translate(
                      locale_keys.advertisement.detail.required.input
                    ),
                    setValueAs: (v: string) => parseInt(v) || '',
                  })}
                  onChange={(e: any) => field.onChange(e.target.value)}
                />
              )}
            />
            <ErrorMessage
              errors={errors}
              name={`${keyName}.monthly.priceJp`}
              render={({ message }) => <p className='text--error'>{message}</p>}
            />
          </div>
        </div>
        <div style={{ width: '45%' }}>
          <label htmlFor='type' className='form-label filter__form-label'>
            {translate(locale_keys.paymentManagement.packageConfig.yearPrice)}
            {translate(locale_keys.paymentManagement.packageConfig.yen)}
          </label>
          <div>
            <Controller
              name={`${keyName}.yearly.priceJp`}
              control={control}
              rules={{ validate: validateNonNegativeNumber }}
              render={({ field }) => (
                <Input
                  size='large'
                  value={field.value}
                  placeholder='JPY'
                  className={classNames([''], {
                    ['--error']: errors[`${keyName}.yearly.priceJp`],
                  })}
                  {...register(`${keyName}.yearly.priceJp`, {
                    required: translate(
                      locale_keys.advertisement.detail.required.input
                    ),
                    setValueAs: (v: string) => parseInt(v) || '',
                  })}
                  onChange={(e: any) => field.onChange(e.target.value)}
                />
              )}
            />
            <ErrorMessage
              errors={errors}
              name={`${keyName}.yearly.priceJp`}
              render={({ message }) => <p className='text--error'>{message}</p>}
            />
          </div>
        </div>
      </div>

      <Divider className='mt-30' orientation='left'>
        {translate(locale_keys.paymentManagement.packageConfig.otherNation)}
      </Divider>

      <div className='d-flex justify-content-between mt-30'>
        <div style={{ width: '45%' }}>
          <label htmlFor='type' className='form-label filter__form-label'>
            {translate(locale_keys.paymentManagement.packageConfig.monthPrice)}
            {translate(locale_keys.paymentManagement.packageConfig.usd)}
          </label>
          <div>
            <Controller
              name={`${keyName}.monthly.priceEn`}
              control={control}
              rules={{ validate: validateNegativeNumber }}
              render={({ field }) => (
                <Input
                  size='large'
                  type='number'
                  value={field.value}
                  placeholder='USD'
                  className={classNames([''], {
                    ['--error']: errors[`${keyName}.monthly.priceEn`],
                  })}
                  {...register(`${keyName}.monthly.priceEn`, {
                    required: translate(
                      locale_keys.advertisement.detail.required.input
                    ),
                    setValueAs: (v: string) => parseFloat(v) || '',
                  })}
                  onChange={(e: any) =>
                    field.onChange(parseFloat(e.target.value || 0)?.toFixed(2))
                  }
                />
              )}
            />
            <ErrorMessage
              errors={errors}
              name={`${keyName}.monthly.priceEn`}
              render={({ message }) => <p className='text--error'>{message}</p>}
            />
          </div>
        </div>
        <div style={{ width: '45%' }}>
          <label htmlFor='type' className='form-label filter__form-label'>
            {translate(locale_keys.paymentManagement.packageConfig.yearPrice)}
            {translate(locale_keys.paymentManagement.packageConfig.usd)}
          </label>
          <div>
            <Controller
              name={`${keyName}.yearly.priceEn`}
              control={control}
              rules={{ validate: validateNegativeNumber }}
              render={({ field }) => (
                <Input
                  type='number'
                  size='large'
                  value={field.value}
                  placeholder='USD'
                  className={classNames([''], {
                    ['--error']: errors[`${keyName}.yearly.priceEn`],
                  })}
                  {...register(`${keyName}.yearly.priceEn`, {
                    required: translate(
                      locale_keys.advertisement.detail.required.input
                    ),
                    setValueAs: (v: string) => parseFloat(v) || '',
                  })}
                  onChange={(e: any) =>
                    field.onChange(parseFloat(e.target.value || 0)?.toFixed(2))
                  }
                />
              )}
            />
            <ErrorMessage
              errors={errors}
              name={`${keyName}.yearly.priceEn`}
              render={({ message }) => <p className='text--error'>{message}</p>}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlansDetail;
