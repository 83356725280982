import { IDashboard } from '../locale';

const dashboard: IDashboard = {
  user: 'users',
  purchase: 'purchases',
  counter: {
    totalUser: 'Total Users',
    totalPost: 'Total Posts',
    totalBalanceJapan: 'Total Balance (Japan)',
    totalBalanceOther: 'Total Balance (Other)',
    totalPollJapan: 'Total Polls (Japan)',
    totalPollOther: 'Total Polls (Other)',
  },
  postDetail: {
    title: 'Posts detail',
    post: 'posts',
  },
  socialUser: {
    title: 'Social Users',
    facebook: 'Facebook',
    google: 'Google',
    line: 'Line',
    twitter: 'Twitter',
  },
  planPurchase: {
    title: 'Plans purchase detail',
    monthly: 'Monthly',
    pro: 'Pro',
    basic: 'Basic',
    advanced: 'Advanced',
    yearly: 'Yearly',
  },
  userAndPayment: {
    title: 'Free users vs Payment users',
    anonymous: 'Anonymous',
    free: 'Free',
    paid: 'Paid',
  },
};

export default dashboard;
