import { StopOutlined } from '@ant-design/icons';
import { ErrorMessage } from '@hookform/error-message';
import { Button, Drawer, Input, Select, SelectProps, Space } from 'antd';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import '@/styles/pages/ads.scss';

import { confirmModal } from '@/components/ShowConfirm';
import TextEditor from '@/components/TextEditor';

import { useAppDispatch } from '@/stores';
import { setLoading } from '@/stores/features/auth.slice';

import { LOCATION_TYPE_WITHOUT_ALL } from '@/helpers/constants/common.constant';
import { filterMarkdownText } from '@/helpers/functions/form-validation.helper';
import {
  translate,
  translateChartLegend,
} from '@/helpers/functions/language.helper';
import { locale_keys } from '@/helpers/locales/locale.key';
import apiService from '@/services/api/api.services';
import endpointConfig from '@/services/api/endpoint.config';

interface AnnouncementDetailDrawerProps {
  resetDrawer: boolean;
  selectedId: string;
  open: boolean;
  onCloseDetailDrawer: () => void;
  onRefresh: () => void;
}

const AnnouncementDetailDrawer = ({
  resetDrawer,
  selectedId,
  open,
  onCloseDetailDrawer,
  onRefresh,
}: AnnouncementDetailDrawerProps) => {
  const dispatch = useAppDispatch();

  const optionsLocationDefault: SelectProps['options'] =
    LOCATION_TYPE_WITHOUT_ALL;
  const [optionsLocation, setOptionsLocation] = useState(
    optionsLocationDefault
  );

  const defaultValues = {
    title: '',
    description: '',
    location: LOCATION_TYPE_WITHOUT_ALL[0].value,
  };

  const {
    handleSubmit,
    register,
    control,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues,
  });

  const getDetailAnnouncement = async (selectedId: string) => {
    dispatch(setLoading(true));

    try {
      const { data } = await apiService.get(
        `${endpointConfig.announcements}/${selectedId}`
      );
      reset({
        ...data?.data,
        title: data?.data?.title,
        description: data?.data?.description,
        location: data?.data?.location,
      });
    } finally {
      setTimeout(() => {
        dispatch(setLoading(false));
      }, 250);
    }
  };

  const onSubmit = async (dataForm: any) => {
    dispatch(setLoading(true));

    try {
      const reqData: any = {
        title: dataForm.title,
        description: dataForm.description,
        location: dataForm.location,
      };

      if (selectedId !== '') {
        const { data } = await apiService.patch(
          endpointConfig.announcements + '/' + selectedId,
          reqData
        );
        if (data?.success) {
          toast.success(
            translate(locale_keys.announcement.toastr.updateSuccessful)
          );
          onCloseDetailDrawer();
          reset(defaultValues);
          onRefresh();
        }
      } else {
        const { data } = await apiService.post(
          endpointConfig.announcements,
          reqData
        );

        if (data?.success) {
          toast.success(
            translate(locale_keys.announcement.toastr.createSuccessful)
          );
          onCloseDetailDrawer();
          reset(defaultValues);
          onRefresh();
        }
      }
    } catch (error) {
      dispatch(setLoading(false));
    } finally {
      setTimeout(() => {
        dispatch(setLoading(false));
      }, 250);
    }
  };

  const onDeleteNotice = async () => {
    try {
      await confirmModal({
        title: translate(locale_keys.announcement.confirm.delete),
        content: translate(locale_keys.announcement.confirm.contentDelete),
        okText: translate(locale_keys.filter.yes),
        cancelText: translate(locale_keys.filter.no),
        // icon: <InfoCircleFilled />,
      });

      doDelete();
    } catch (e) {
      // User clicked Cancel or closed the modal
    }
  };

  const doDelete = async () => {
    dispatch(setLoading(true));

    try {
      const res = await apiService.delete(
        `${endpointConfig.announcements}/${selectedId}`
      );
      if (res?.data?.success) {
        onCloseDetailDrawer();
        toast.success(
          translate(locale_keys.announcement.toastr.deleteSuccessful)
        );
        onRefresh();
      }
    } finally {
      setTimeout(() => {
        dispatch(setLoading(false));
      }, 250);
    }
  };

  const location = useLocation();

  useEffect(() => {
    if (selectedId !== '') {
      getDetailAnnouncement(selectedId);
    } else {
      reset(defaultValues);
    }

    setOptionsLocation((preOptions: any) =>
      preOptions.map((option: any) => ({
        ...option,
        label: translateChartLegend(option.key, locale_keys.location),
      }))
    );
  }, [resetDrawer, location]);

  return (
    <>
      <Drawer
        title={
          selectedId !== ''
            ? translate(locale_keys.announcement.detailTitle)
            : translate(locale_keys.announcement.createTitle)
        }
        width={720}
        placement='right'
        onClose={onCloseDetailDrawer}
        open={open}
        bodyStyle={{ paddingBottom: 80 }}
        extra={
          <Space>
            <button
              type='submit'
              form='create-form'
              className='action-btn action-btn--black'
            >
              {selectedId === ''
                ? translate(locale_keys.filter.create)
                : translate(locale_keys.filter.update)}
            </button>

            {selectedId !== '' ? (
              <Button
                type='dashed'
                danger
                icon={<StopOutlined />}
                className='d-flex align-items-center'
                onClick={onDeleteNotice}
              >
                {translate(locale_keys.filter.delete)}
              </Button>
            ) : null}
          </Space>
        }
      >
        <form id='create-form' onSubmit={handleSubmit(onSubmit)}>
          <div className='d-flex justify-content-between mb-20'>
            <div style={{ width: '68%' }}>
              <label
                htmlFor='timeEnd'
                className='form-label filter__form-label'
              >
                {translate(locale_keys.common.title)}
              </label>
              <div>
                <Controller
                  name='title'
                  control={control}
                  defaultValue=''
                  rules={{
                    required: translate(
                      locale_keys.advertisement.detail.required.input
                    ),
                    minLength: {
                      value: 5,
                      message: translate(locale_keys.announcement.minCharacter),
                    },
                    maxLength: {
                      value: 100,
                      message: translate(locale_keys.announcement.maxCharacter),
                    },
                  }}
                  render={({ field }) => (
                    <Input
                      size='large'
                      value={field.value}
                      placeholder={translate(
                        locale_keys.announcement.titlePlaceholder
                      )}
                      className={classNames([''], {
                        ['--error']: errors['title'],
                      })}
                      maxLength={100}
                      {...register('title')}
                      onChange={(e: any) => field.onChange(e.target.value)}
                    />
                  )}
                />
                <ErrorMessage
                  errors={errors}
                  name='title'
                  render={({ message }) => (
                    <p className='text--error'>{message}</p>
                  )}
                />
              </div>
            </div>
            <div style={{ width: '30%' }}>
              <label htmlFor='type' className='form-label filter__form-label'>
                {translate(locale_keys.advertisement.location)}
              </label>
              <div>
                <Controller
                  name='location'
                  control={control}
                  defaultValue={LOCATION_TYPE_WITHOUT_ALL[0].value}
                  render={({ field }) => (
                    <Select
                      disabled={selectedId !== '' ? true : false}
                      size='large'
                      defaultValue={field.value}
                      value={field.value}
                      onChange={(value) => field.onChange(value)}
                      style={{ width: '100%' }}
                      options={optionsLocation}
                    />
                  )}
                />
              </div>
            </div>
          </div>
          <div className='d-flex justify-content-between mb-20'>
            <div style={{ width: '100%' }}>
              <label
                htmlFor='description'
                className='form-label filter__form-label'
              >
                {translate(locale_keys.advertisement.description)}
              </label>
              <div>
                <Controller
                  name='description'
                  control={control}
                  defaultValue=''
                  rules={{
                    required: translate(
                      locale_keys.advertisement.detail.required.input
                    ),
                    validate: (value) => {
                      const filterText = filterMarkdownText(value);
                      if (filterText?.length < 30)
                        return translate(
                          locale_keys.announcement.minDescription
                        );
                      if (filterText?.length > 4800)
                        return translate(
                          locale_keys.announcement.maxDescription
                        );
                      return true;
                    },
                  }}
                  render={({ field }) => (
                    <TextEditor
                      value={field.value}
                      onChange={(value: any) => {
                        field.onChange(value);
                      }}
                    />
                  )}
                />
                <ErrorMessage
                  errors={errors}
                  name='description'
                  render={({ message }) => (
                    <p className='text--error'>{message}</p>
                  )}
                />
              </div>
            </div>
          </div>
        </form>
      </Drawer>
    </>
  );
};

export default AnnouncementDetailDrawer;
