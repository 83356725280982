import dayjs from 'dayjs';

const DEFAULT_DATE_FORMAT = 'DD-MM-YYYY, HH:mm';

export const toUpperCaseFirstLetter = (str: string) => {
  if (!str || str === '') {
    return '';
  }
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const toHalfWidth = (value: any) => {
  let ascii = '';
  if (value) {
    for (let i = 0, l = value.length; i < l; i++) {
      let c = value[i].charCodeAt(0);

      if (c >= 0xff00 && c <= 0xffef) {
        c = 0xff & (c + 0x20);
      }

      ascii += String.fromCharCode(c);
    }
  }

  return ascii;
};

export const getValueForInputNumber = (value: any) => {
  if (!value || value === '') {
    return 0;
  }
  return !isNaN(Number(value)) ? Number(value) : Number(toHalfWidth(value));
};

export const formatDateFromTimeStamp = (
  timestamp?: number,
  dateFormat = DEFAULT_DATE_FORMAT
) => {
  if (timestamp) {
    return dayjs.unix(timestamp).format(dateFormat);
  }
  return '';
};

export const formatPrice = (amount?: number, currency?: string) => {
  if (!currency || (!amount && amount !== 0)) return '';

  if (currency === 'jpy') {
    return amount + '¥';
  }

  if (['usd', 'hkd'].includes(currency)) {
    return `${amount / 100}$`;
  }

  return amount + currency;
};

export const snaketoTitleCase = (s = '') =>
  s.replace(/^_*(.)|_+(.)/g, (s, c, d) =>
    c ? c.toUpperCase() : ' ' + d.toUpperCase()
  );
