import { ISaleManagement } from '../locale';

const saleManagement: ISaleManagement = {
  listPayment: 'Payment List',
  paymentId: 'Payment ID',
  amount: 'Amount',
  username: 'Username',
  plan: 'Plan',
  type: 'Type',
  dateOrder: 'Date order',
  periodEnd: 'Period end',
  orderStatus: 'Order status',
  totalBalance: 'Total balance:',
  detail: {
    userRequest: 'User Request',
    requestCancel: 'REQUEST CANCEL PLAN',
    planType: 'Plan type',
    cancelPlan: 'Cancel Plan',
    confirm: {
      contentCancel: 'Cancel Plan of User ',
      text: 'This will cancel current subscription and convert user to',
    },
    paymentInfo: 'Payment information',
    title: 'Payment Detail',
    toastr: {
      cancelSuccessful: 'Cancel User Plan successfully',
      error: 'Can not cancel user plan!',
    },
    userInformation: 'User information',
    userStatus: 'User status',
    currentCycleStart: 'Current Cycle Start',
    nextCycleCharge: 'Next Cycle Charge',
    invoiceNumber: 'Invoice number',
    amountPaid: 'Amount Paid',
    chargeMethod: 'Charge method',
    paymentMethod: 'Payment method',
    lastDigits: 'Last 4 digits',
    invoiceUrl: 'Invoice URL',
    link: 'Link',
    lastestInvoice: 'Latest Invoice',
  },
};

export default saleManagement;
