import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import {
  translate,
  translateChartLegend,
} from '@/helpers/functions/language.helper';
import { locale_keys } from '@/helpers/locales/locale.key';

interface BarChartProps {
  data: any[];
}

const defaultStyleCharts = [
  {
    key: 'anonymous',
    title: translate(locale_keys.dashboard.userAndPayment.anonymous),
    color: '#4198d7',
  },
  {
    key: 'free',
    title: translate(locale_keys.dashboard.userAndPayment.free),
    color: '#FFBB28',
  },
  {
    key: 'paid',
    title: translate(locale_keys.dashboard.userAndPayment.paid),
    color: '#7a61ba',
  },
];

const StackBarChart = ({ data }: BarChartProps) => {
  const [styleCharts, setStyleCharts] = useState(defaultStyleCharts);
  const location = useLocation();

  useEffect(() => {
    setStyleCharts((preStyles) =>
      preStyles.map((chart: any) => {
        if (chart.key === 'anonymous') {
          return {
            ...chart,
            title: translate(locale_keys.dashboard.userAndPayment.anonymous),
          };
        } else if (chart.key === 'free') {
          return {
            ...chart,
            title: translate(locale_keys.dashboard.userAndPayment.free),
          };
        } else {
          return {
            ...chart,
            title: translate(locale_keys.dashboard.userAndPayment.paid),
          };
        }
      })
    );
  }, [location]);

  const renderLegend = ({ payload }: any) => {
    return (
      <div className='d-flex justify-content-center'>
        <div>
          {payload.map((line: any) => (
            <span className='mx-2' key={`item-${line?.dataKey}`}>
              <span
                className='line-square'
                style={{ backgroundColor: line.color }}
              />
              {translateChartLegend(
                line.value,
                locale_keys.dashboard.userAndPayment
              )}
            </span>
          ))}
        </div>
      </div>
    );
  };

  const CustomizedAxisTick = (props: any) => {
    const { x, y, payload } = props;

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={12}
          y={5}
          dy={16}
          textAnchor='end'
          fill='rgba(0, 0, 0, 0.4)'
          className='gray-text'
          fontSize={16}
        >
          {payload.value}
        </text>
      </g>
    );
  };

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      return (
        <div className='custom-tooltip'>
          <p style={{ fontWeight: 600, fontSize: '18px', marginBottom: '5px' }}>
            {label}
          </p>
          {payload?.map((line: any) => (
            <p
              className='label mb-2'
              key={line?.dataKey}
              style={{ color: line.color }}
            >
              {`${
                styleCharts.find((item) => item.key === line.dataKey)?.title
              } : ${line.payload[line.dataKey]} ${translate(
                locale_keys.dashboard.user
              )}`}
            </p>
          ))}
        </div>
      );
    }

    return null;
  };

  return (
    <>
      <ResponsiveContainer width='100%' height='100%'>
        <BarChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 30,
          }}
          barSize={20}
        >
          <CartesianGrid vertical={false} />
          <XAxis
            dataKey='title'
            tick={<CustomizedAxisTick />}
            tickLine={false}
            padding={{ left: 20, right: 20 }}
          />
          <YAxis
            tickLine={false}
            axisLine={false}
            style={{
              fontFamily: 'Roboto',
              fontSize: '16px',
            }}
          />
          <Tooltip content={<CustomTooltip />} />
          <Legend content={renderLegend} verticalAlign='top' height={50} />

          {styleCharts.map((item) => (
            <Bar
              key={item.title}
              dataKey={item.key}
              stackId='a'
              fill={item.color}
            />
          ))}
        </BarChart>
      </ResponsiveContainer>
    </>
  );
};

export default StackBarChart;
