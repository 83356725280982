import { Carousel, Col, Row } from 'antd';

import IconUp from '@/assets/images/png/icon-up.png';
import { translate } from '@/helpers/functions/language.helper';
import { locale_keys } from '@/helpers/locales/locale.key';

interface CountersProps {
  data: {
    poll: {
      JAPAN: {
        total: number;
        difference: number;
      };
      OTHER: {
        total: number;
        difference: number;
      };
    };
    user: {
      total: number;
      difference: number;
    };
    payment: {
      jp: number;
      other: number;
    };
  };
}

const Counters = ({ data }: CountersProps) => {
  return (
    <>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className='counters'>
        <Col span={6}>
          <div className='dashboard_firstline_item'>
            <div className='dashboard_firstline_item_title'>
              {translate(locale_keys.dashboard.counter.totalUser)}
            </div>
            <div className='d-flex justify-content-between align-items-center'>
              <div className='dashboard_firstline_item_value'>
                {data?.user?.total}
              </div>
              <div className='dashboard_firstline_item_dif'>
                + {data?.user?.difference}%
                <img src={IconUp} alt='up' />
              </div>
            </div>
          </div>
        </Col>
        <Col span={6}>
          <div className='dashboard_firstline_item'>
            <Carousel dots={{ className: 'carousel_dot' }} autoplay>
              <div>
                <div className='dashboard_firstline_item_title'>
                  {translate(locale_keys.dashboard.counter.totalPollJapan)}
                </div>
                <div className='d-flex justify-content-between align-items-center'>
                  <div className='dashboard_firstline_item_value'>
                    {data?.poll?.JAPAN?.total}
                  </div>
                  <div className='dashboard_firstline_item_dif'>
                    + {data?.poll?.JAPAN?.difference}%
                    <img src={IconUp} alt='up' />
                  </div>
                </div>
              </div>
              <div>
                <div className='dashboard_firstline_item_title'>
                  {translate(locale_keys.dashboard.counter.totalPollOther)}
                </div>
                <div className='d-flex justify-content-between align-items-center'>
                  <div className='dashboard_firstline_item_value'>
                    {data?.poll?.OTHER?.total}
                  </div>
                  <div className='dashboard_firstline_item_dif'>
                    + {data?.poll?.OTHER?.difference}%
                    <img src={IconUp} alt='up' />
                  </div>
                </div>
              </div>
            </Carousel>
          </div>
        </Col>
        <Col span={6}>
          <div className='dashboard_firstline_item'>
            <div className='dashboard_firstline_item_title'>
              {translate(locale_keys.dashboard.counter.totalBalanceJapan)}
            </div>
            <div className='d-flex justify-content-between align-items-center'>
              <div className='dashboard_firstline_item_value'>
                {data?.payment?.jp} ¥
              </div>
            </div>
          </div>
        </Col>
        <Col span={6}>
          <div className='dashboard_firstline_item'>
            <div className='dashboard_firstline_item_title'>
              {translate(locale_keys.dashboard.counter.totalBalanceOther)}
            </div>
            <div className='d-flex justify-content-between align-items-center'>
              <div className='dashboard_firstline_item_value'>
                {data?.payment?.other} $
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Counters;
