import { Carousel } from 'antd';
import { useState } from 'react';
import {
  Cell,
  Legend,
  Pie,
  PieChart as PieChartComponent,
  ResponsiveContainer,
  Sector,
  Tooltip,
} from 'recharts';

import { translate } from '@/helpers/functions/language.helper';
import { locale_keys } from '@/helpers/locales/locale.key';
interface PieChartProps {
  data: any;
}

const PieChart = ({ data }: PieChartProps) => {
  const isSmallWindow = window.innerWidth <= 1500;

  const typeData = [
    {
      name: translate(locale_keys.dashboard.planPurchase.monthly),
      value: data['MONTHLY'],
    },
    {
      name: translate(locale_keys.dashboard.planPurchase.yearly),
      value: data['YEARLY'],
    },
  ];
  const typePlanData = [
    {
      name: translate(locale_keys.dashboard.planPurchase.basic),
      value: data['BASIC'],
    },
    {
      name: translate(locale_keys.dashboard.planPurchase.pro),
      value: data['PRO'],
    },
    {
      name: translate(locale_keys.dashboard.planPurchase.advanced),
      value: data['ADVANCED'],
    },
  ];
  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

  const [activeIndex, setActiveIndex] = useState(0);

  const onPieEnter = (_: any, index: number) => {
    setActiveIndex(index);
  };

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
  }: any) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill='white'
        fontSize='20px'
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline='central'
      >
        {percent > 0 && `${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  return (
    <>
      <Carousel
        dots={{ className: 'carousel_dot' }}
        style={{ width: '100%', height: '100%' }}
        autoplay
      >
        <ResponsiveContainer width={'100%'} height={350}>
          <PieChartComponent width={500} height={350}>
            <Pie
              dataKey='value'
              data={typePlanData}
              cx={isSmallWindow ? '50%' : '55%'}
              cy={isSmallWindow ? '50%' : '50%'}
              innerRadius={isSmallWindow ? 65 : 70}
              outerRadius={isSmallWindow ? 100 : 120}
              fill='#8884d8'
              activeIndex={activeIndex}
              activeShape={renderActiveShape}
              onMouseEnter={onPieEnter}
            >
              {typePlanData?.map((_, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
            <Legend
              align={isSmallWindow ? 'center' : 'right'}
              verticalAlign={isSmallWindow ? 'top' : 'middle'}
              layout={isSmallWindow ? 'horizontal' : 'vertical'}
              iconType={'square'}
            />
          </PieChartComponent>
        </ResponsiveContainer>
        <ResponsiveContainer width={'100%'} height={350}>
          <PieChartComponent width={500} height={350}>
            <Pie
              dataKey='value'
              data={typeData}
              cx='58%'
              cy='50%'
              outerRadius={120}
              labelLine={false}
              label={renderCustomizedLabel}
            >
              {typeData?.map((_, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
            <Legend
              align='right'
              verticalAlign='middle'
              layout='vertical'
              iconType={'square'}
            />
            <Tooltip content={<CustomTooltip />} />
          </PieChartComponent>
        </ResponsiveContainer>
      </Carousel>
    </>
  );
};

const renderActiveShape = (props: any) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    name,
    value,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';
  return (
    <g>
      <text
        x={cx}
        y={cy - 12}
        dy={8}
        textAnchor='middle'
        fill={fill}
        fontSize='20px'
      >
        {payload.name}
      </text>
      <text
        x={cx}
        y={cy + 10}
        dy={8}
        textAnchor='middle'
        fill={fill}
        fontSize='16px'
      >
        {value} {translate(locale_keys.dashboard.purchase)}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill='none'
      />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke='none' />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor={textAnchor}
        fill='#333'
        fontSize={'16px'}
      >{`${name}`}</text>
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        dy={18}
        textAnchor={textAnchor}
        fill='#999'
        fontSize={'16px'}
      >
        {`(${(percent * 100).toFixed(2)}%)`}
      </text>
    </g>
  );
};

const CustomTooltip = ({ active, payload }: any) => {
  if (active && payload && payload.length) {
    return (
      <div className='custom-tooltip'>
        <p
          className='label mb-2'
          key={payload[0]?.name}
          style={{ color: payload[0]?.payload[0]?.stroke }}
        >
          {`${payload[0]?.name} : ${payload[0]?.value}`}
        </p>
      </div>
    );
  }

  return null;
};

export default PieChart;
