import { ISale } from '../locale';

const sale: ISale = {
  listSale: '支払いリスト',
  id: '支払いID',
  plan: 'プラン',
  type: 'タイプ',
  amount: '金額',
  status: '決済状況',
  userRequest: 'ユーザーリクエスト',
  created: '日付順',
};

export default sale;
