/* eslint-disable @typescript-eslint/ban-ts-comment */
import classNames from 'classnames';
import React from 'react';
import { useForm } from 'react-hook-form';
import { FiX } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import '@/styles/pages/forgot-password.scss';

import useDocumentTitle from '@/hooks/useDocumentTitle';

import Logo from '@/components/Logo';

import { useAppDispatch, useAppSelector } from '@/stores';
import { forgotPassword, setLoading } from '@/stores/features/auth.slice';

import { E_PATHS } from '@/helpers/constants/common.constant';
import { translate } from '@/helpers/functions/language.helper';
import { locale_keys } from '@/helpers/locales/locale.key';
import apiService from '@/services/api/api.services';
import endpointConfig from '@/services/api/endpoint.config';

const ConfirmOTP = () => {
  useDocumentTitle(translate(locale_keys.heading.confirmOTP));
  const [countDownTime, setCountDownTime] = React.useState(120);

  const { email } = useAppSelector((state) => state.auth.forgotPassword);
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const fields = Array.from({ length: 6 }, (_, i) => i);

  const dispatch = useAppDispatch();

  const onForgotPassword = (data: any) => {
    dispatch(setLoading(true));
    const otp = Object.values(data).join('');

    apiService
      .post(endpointConfig.verifyEmailToken + '?isAdmin=true', {
        otp,
        email,
      })
      .then(() => {
        dispatch(forgotPassword({ email, otp }));

        navigate(E_PATHS._FORGOT_PASSWORD_RESET);
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };

  React.useEffect(() => {
    const interval = setInterval(() => {
      setCountDownTime((prev) => prev - 1);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const renderCountDownTime = React.useMemo(() => {
    if (countDownTime > 0) {
      const minutes = Math.floor(countDownTime / 60);
      const seconds = countDownTime % 60;

      return `${minutes < 10 ? '0' + minutes : minutes}:${
        seconds < 10 ? '0' + seconds : seconds
      }`;
    }
    return '00:00';
  }, [countDownTime]);

  const msgError = React.useMemo(() => {
    if (Object.keys(errors).length > 0) {
      const msg = Object.values(errors).map((item: any) => {
        return item.message;
      });

      return msg[0];
    }

    return '';
  }, [errors]);

  const onResendOtp = () => {
    dispatch(setLoading(true));
    apiService
      .post(endpointConfig.validEmailToken + '?isAdmin=true', {
        email,
      })
      .then(() => {
        setCountDownTime(120);
        toast.success(translate(locale_keys.auth.toast.resendOTPSuccessful));
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };

  function onHandlePaste(e: any) {
    e.preventDefault();

    const clipboardData = e.clipboardData.getData('text/plain');
    const numbers = clipboardData.trim().split('');

    if (numbers.length === 6) {
      numbers.forEach((item: any, index: any) => {
        setValue(`otp-${index}`, item);
      });
    }
  }

  const backToLoginPage = () => {
    navigate(E_PATHS._LOGIN);
  };

  React.useEffect(() => {
    if (!email) {
      navigate(E_PATHS._FORGOT_PASSWORD);
    }
  }, [email, navigate]);

  return (
    <div className='forgot-password'>
      <div className='forgot-password__background forgot-password__background--secondary p-5'>
        <Logo />
      </div>

      <div className='forgot-password__quit' onClick={backToLoginPage}>
        <FiX height={72} width={72} />
      </div>

      <div className='forgot-password__container'>
        <form onSubmit={handleSubmit(onForgotPassword)} onPaste={onHandlePaste}>
          {/* form */}
          <h3 className='forgot-password__welcome'>
            {translate(locale_keys.auth.welcomTitle.confirmOtp)}
          </h3>
          <div className='forgot-password__form'>
            <label htmlFor='otp' className='text-center'>
              {translate(locale_keys.auth.text.countDownOTP)} -{' '}
              <strong>{renderCountDownTime}</strong>
            </label>

            <div className='forgot-password__otp'>
              {fields.map((item, index) => {
                return (
                  <input
                    key={index}
                    type='text'
                    id={`otp-${index}`}
                    placeholder='0'
                    maxLength={1}
                    className={classNames(
                      ['forgot-password__form-input app-input'],
                      {
                        ['--error']: errors[`otp-${index}`],
                      }
                    )}
                    {...register(`otp-${index}`, {
                      required: translate(locale_keys.formRule.required),

                      validate: (value) =>
                        !isNaN(value) || translate(locale_keys.formRule.number),
                      onChange(event) {
                        // auto focus to next input
                        if (
                          event.target.value &&
                          event.target.value.length === 1 &&
                          index < 5
                        ) {
                          const nextInput = document.getElementById(
                            `otp-${index + 1}`
                          );
                          if (nextInput) {
                            nextInput.focus();
                          }
                        }

                        // auto focus to previous input
                        if (!event.target.value) {
                          const previousInput = document.getElementById(
                            `otp-${index - 1}`
                          );
                          if (previousInput) {
                            previousInput.focus();
                          }
                        }

                        return event.target.value;
                      },
                    })}
                  />
                );
              })}
            </div>

            {msgError && <p className='text--error'>{msgError}</p>}

            <button
              type='submit'
              className='login__form-btn app-btn--black-21 mt-30'
            >
              {translate(locale_keys.auth.button.confirmOtp)}
            </button>

            <div className='forgot-password__already-user'>
              <p>
                {translate(locale_keys.auth.text.notSeeOTP)}
                <span className='mr-1 cursor-pointer' onClick={onResendOtp}>
                  {translate(locale_keys.auth.text.resendOtp)}
                </span>
              </p>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ConfirmOTP;
