import { IFormRule } from '../locale';

const formRules: IFormRule = {
  required: 'この項目は必須です。',
  passwordNotMatch: 'パスワードが一致しません。',
  number: 'この項目は数字で入力してください。',
  passwordNotStrong:
    'パスワードは少なくとも1つの大文字、1つの小文字、1つの数字、1つの特殊文字を含む必要があり、6文字以上30文字以下で入力してください。',
  passwordNotLong: 'パスワードは6文字以上30文字以下で入力してください。',
  email: 'メールアドレスは無効です。',
  username:
    'ユーザー名は、英数字、ピリオド（.）、アンダースコア（_）で入力してください。',
  userNameNotLong: 'ユーザー名は6文字以上30文字以下で入力してください。',
  fullname: 'フールネームは50文字以下で入力してください。',
};

export default formRules;
