export const REPORT_TYPES = {
  ALL: 'ALL',
  SEXUAL_CONTENT: 'SEXUAL_CONTENT',
  VIOLENT_CONTENT: 'VIOLENT_CONTENT',
  HATEFUL_CONTENT: 'HATEFUL_CONTENT',
  HARASSMENT_OR_BULLYING: 'HARASSMENT_OR_BULLYING',
  HARMFUL_ACTS: 'HARMFUL_ACTS',
  MISINFORMATION: 'MISINFORMATION',
  CHILD_ABUSE: 'CHILD_ABUSE',
  PROMOTES_TERRORISM: 'PROMOTES_TERRORISM',
  SPAM_OR_MISLEADING: 'SPAM_OR_MISLEADING',
};

export type REPORT_TYPES_KEY = keyof typeof REPORT_TYPES;
