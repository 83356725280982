import { ISaleManagement } from '../locale';

const saleManagement: ISaleManagement = {
  listPayment: '支払い一覧',
  paymentId: '支払いID',
  amount: '金額',
  username: 'ユーザー名',
  plan: 'プラン',
  type: 'タイプ',
  dateOrder: '支払い日',
  periodEnd: 'プランの終了日',
  orderStatus: '状況',
  totalBalance: '合計金額:',
  detail: {
    userRequest: 'ユーザの依頼',
    requestCancel: '依頼キャンセルの計画',
    planType: '計画の種類',
    cancelPlan: '計画のキャンセル',
    confirm: {
      contentCancel: 'このユーザーのプランをキャンセルする',
      text: '',
    },
    paymentInfo: 'お支払い情報',
    title: 'お支払いの詳細',
    toastr: {
      cancelSuccessful: 'ユーザ計画のキャンセルが成功しました',
      error: 'ユーザーの購入したプランをキャンセルできません',
    },
    userInformation: 'ユーザ情報',
    userStatus: 'ユーザのステータス',
    currentCycleStart: 'プラン開始日',
    nextCycleCharge: 'プラン更新日',
    invoiceNumber: '請求書番号',
    amountPaid: '決済金額',
    chargeMethod: '自動請求',
    paymentMethod: '支払い方法',
    lastDigits: '最後の４桁',
    invoiceUrl: '請求書のURL',
    link: 'リンク',
    lastestInvoice: '最新請求書',
  },
};

export default saleManagement;
