import { CloseOutlined } from '@ant-design/icons';
import { ErrorMessage } from '@hookform/error-message';
import { Button, Input } from 'antd';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import '@/styles/pages/word-ban.scss';

import useDocumentTitle from '@/hooks/useDocumentTitle';

import { useAppDispatch } from '@/stores';
import { setLoading } from '@/stores/features/auth.slice';

import { validateCategory } from '@/helpers/functions/form-validation.helper';
import { translate } from '@/helpers/functions/language.helper';
import { locale_keys } from '@/helpers/locales/locale.key';
import { SettingPollModel } from '@/helpers/types/voting.types';
import apiService from '@/services/api/api.services';
import endpointConfig from '@/services/api/endpoint.config';

const WordsBan = () => {
  useDocumentTitle('Voting Management');
  const dispatch = useAppDispatch();
  const [settings, setSetting] = useState<SettingPollModel>();
  const location = useLocation();

  const [defaultLocation, setDefaultLocation] = useState(location);

  const defaultValues = {
    word: '',
  };

  const {
    handleSubmit,
    register,
    control,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues,
  });

  const onSubmit = async (dataForm: any) => {
    dispatch(setLoading(true));

    try {
      const reqData = {
        polls: {
          configurations: {
            ...settings?.configurations,
          },
          blacklistWords: [...(settings?.blacklistWords || []), dataForm?.word],
        },
      };
      const { data } = await apiService.patch(endpointConfig.settings, reqData);

      if (data?.success) {
        toast.success(
          translate(locale_keys.voteManagement.banWord.createBanWordSuccessful)
        );
        reset(defaultValues);
        getListSettings();
      }
    } finally {
      setTimeout(() => {
        dispatch(setLoading(false));
      }, 250);
    }
  };

  const getListSettings = async () => {
    dispatch(setLoading(true));

    try {
      const { data } = await apiService.get(endpointConfig.settings);
      setSetting(data?.data?.polls);
    } finally {
      setTimeout(() => {
        dispatch(setLoading(false));
      }, 250);
    }
  };

  const onRemoveBanWord = async (indexToRemove: number) => {
    try {
      const newBanWords = settings?.blacklistWords.filter(
        (_, index) => index !== indexToRemove
      );
      const reqData = {
        polls: {
          configurations: {
            ...settings?.configurations,
          },
          blacklistWords: newBanWords,
        },
      };
      const { data } = await apiService.patch(endpointConfig.settings, reqData);

      if (data?.success) {
        toast.success(
          translate(locale_keys.voteManagement.banWord.removeBanWordSuccessful)
        );
        reset(defaultValues);
        getListSettings();
      }
    } finally {
      setTimeout(() => {
        dispatch(setLoading(false));
      }, 250);
    }
  };

  useEffect(() => {
    getListSettings();
    if (location !== defaultLocation) {
      reset({ word: defaultValues.word });
      setDefaultLocation(location);
    }
  }, [location]);

  return (
    <div className='d-flex mh-100'>
      <form
        style={{ width: '35%', marginRight: '30px' }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className='filter'>
          <div className='filter__title'>
            {translate(locale_keys.voteManagement.banWord.title)}
          </div>
          <div className='filter__form'>
            <div className='mr-20'>
              <div>
                <Controller
                  name='word'
                  control={control}
                  defaultValue=''
                  rules={{
                    validate: validateCategory,
                  }}
                  render={({ field }) => (
                    <Input
                      size='large'
                      value={field.value}
                      placeholder={translate(
                        locale_keys.voteManagement.banWord.createPlaceholder
                      )}
                      className={classNames(['login__form-input'], {
                        ['--error']: errors['word'],
                      })}
                      maxLength={80}
                      {...register('word')}
                      onChange={(e: any) => field.onChange(e.target.value)}
                    />
                  )}
                />
                <ErrorMessage
                  errors={errors}
                  name='word'
                  render={({ message }) => (
                    <p className='text--error'>{message}</p>
                  )}
                />
              </div>
            </div>

            <div className='d-flex align-items-end mt-20'>
              <Button className='form-btn form-btn--black' htmlType='submit'>
                {translate(locale_keys.filter.create)}
              </Button>
            </div>
          </div>
        </div>
      </form>

      <div className='category d-flex flex-column'>
        <div className='category__heading'>
          {translate(locale_keys.voteManagement.banWord.banWordList)}
        </div>
        <div className='ban__group d-flex flex-wrap'>
          {settings &&
          settings?.blacklistWords &&
          settings?.blacklistWords.length > 0
            ? settings?.blacklistWords.map((item, index) => (
                <div
                  key={index}
                  className='ban__group-item d-flex align-items-center'
                >
                  <div className='ban__group-item__title'>{item}</div>
                  <CloseOutlined
                    className='ban__group-item__icon'
                    onClick={() => onRemoveBanWord(index)}
                  />
                </div>
              ))
            : null}
        </div>
      </div>
    </div>
  );
};

export default WordsBan;
