import { IRequired } from '../locale';

const required: IRequired = {
  username: 'Username is required',
  password: 'Password is required',
  defaultPwd: 'Default password is required',
  fullName: 'Full name is required',
  email: 'Email is required',
};

export default required;
