import PropTypes from 'prop-types';
import '@/styles/components/_loading-overlay.scss';

const LoadingOverlay = ({
  isLoading,
  text,
}: {
  isLoading?: boolean;
  text?: string;
}) => {
  return (
    <div className={`loading-overlay ${isLoading ? 'visible' : 'hidden'}`}>
      <span className='spinner-grow text-primary' role='status'>
        <span className='visually-hidden'>Loading...</span>
      </span>
      <div className='loading-overlay-text'>{text}</div>
    </div>
  );
};

LoadingOverlay.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  text: PropTypes.string,
};

export default LoadingOverlay;
