interface DescriptionItemProps {
  title: string;
  content: React.ReactNode;
  style?: any;
}

export const DescriptionItem = ({
  title,
  content,
  style,
}: DescriptionItemProps) => (
  <div className='site-description-item-profile-wrapper d-flex'>
    <p className='site-description-item-profile-p-label'>{title}:</p>
    <span
      style={{
        // whiteSpace: 'nowrap',
        // overflow: 'hidden',
        textOverflow: 'ellipsis',
        width: '180px',
        wordBreak: 'break-word',
        ...style,
      }}
    >
      {content}
    </span>
  </div>
);
