const regex = {
  email(text: string) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(text.toLowerCase());
  },
  password(text: string) {
    // password must be contain 1 uppercase, 1 lowercase, 1 number, 1 special character and at least 6 characters long and not more than 30 characters
    const re =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$#!%*?&])[A-Za-z\d@$#!%*?&]{6,30}$/;
    return re.test(text);
  },
  username(text: string) {
    // username at least 6 characters long and not more than 30 characters, not contain special character except underscore and dot, and not end with dot or underscore
    const re = /^[a-zA-Z0-9._]{6,30}(?<![_.])$/;
    return re.test(text);
  },
  fullname(text: string) {
    const re =
      /^([\p{L}\s]{2,50}|[ぁ-んァ-ヶー一-龠々]{2,50}|[a-zA-Z\s]{2,50})$/u;
    return re.test(text);
  },
};

export default regex;
