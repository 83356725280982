import { IAnnouncement } from '../locale';

const announcement: IAnnouncement = {
  listAn: 'List Announcements',
  createTitle: 'Create Announcement',
  detailTitle: 'Detail Announcement',
  titleFilterPlaceholder: 'Search by title',
  titlePlaceholder: 'Title of notice',
  minDescription: 'Description must have a minimum of 30 characters',
  maxDescription: 'Description must not exceed 4800 characters',
  maxCharacter: 'Title must not exceed 100 characters',
  minCharacter: 'Title must have a minimum of 5 characters',
  toastr: {
    updateSuccessful: 'Update announcement successfully',
    createSuccessful: 'Create new announcement successfully',
    deleteSuccessful: 'Delete announcement successfully',
  },
  confirm: {
    delete: 'Delete Announcement',
    contentDelete: 'Are you sure you want to delete this notice?',
  },
};

export default announcement;
