import { PlusCircleOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React, { useEffect, useState } from 'react';

import { translate } from '@/helpers/functions/language.helper';
import { locale_keys } from '@/helpers/locales/locale.key';
import { CategoryModel } from '@/helpers/types/voting.types';

import CategoryItem from './category-item';

export const CategoryGroup = ({
  categories,
}: {
  categories: CategoryModel[] | undefined;
}) => {
  const [refresh, setRefresh] = useState(false);

  const onAddNewSubCategory = (parentCategory: string) => {
    const subItem: CategoryModel = {
      _id: '',
      titleJp: '',
      titleEn: '',
      status: false,
      created_at: '',
      updated_at: '',
      newItem: true,
    };

    const _category = categories?.find((item) => item?._id === parentCategory);

    if (_category) {
      if (_category?.subCategories) {
        _category.subCategories.push(subItem);
      } else {
        _category.subCategories = [subItem];
      }
      setRefresh(!refresh);
    }
  };

  const handleRemoveSubCategory = (parentId: any) => {
    const _category = categories?.find((item) => item?._id === parentId);
    if (_category) {
      _category?.subCategories?.pop();
      setRefresh(!refresh);
    }
  };

  useEffect(() => {
    setRefresh(!refresh);
  }, [categories]);

  return (
    <div className='category__group'>
      {categories &&
        categories.length > 0 &&
        categories.map((item: CategoryModel) => (
          <React.Fragment key={item._id}>
            <CategoryItem category={item} isParent={true}></CategoryItem>

            {item?.subCategories && item.subCategories.length > 0
              ? item.subCategories.map((subItem: CategoryModel) => (
                  <CategoryItem
                    key={subItem._id}
                    category={subItem}
                    onRemoveSubCategory={handleRemoveSubCategory}
                    parentId={item._id}
                  ></CategoryItem>
                ))
              : null}
            {(item?.subCategories &&
              item.subCategories.length > 0 &&
              !item.subCategories[item.subCategories.length - 1]['newItem']) ||
            !item?.subCategories ||
            item?.subCategories?.length === 0 ? (
              <div className='d-flex justify-content-center mt-15'>
                <Button
                  className='d-flex align-items-center'
                  type='dashed'
                  icon={<PlusCircleOutlined />}
                  onClick={() => onAddNewSubCategory(item._id)}
                >
                  {translate(locale_keys.voteManagement.category.addNewSub)}
                </Button>
              </div>
            ) : null}
          </React.Fragment>
        ))}
    </div>
  );
};

export default CategoryGroup;
