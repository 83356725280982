'use client';

import { E_LOCAL_STORAGE } from '@/helpers/constants/common.constant';

export function useLocalStorage() {
  const IS_SERVER = typeof window === 'undefined';

  const getItem = (key: string) => {
    if (!IS_SERVER) {
      return localStorage.getItem(key);
    }
  };

  const setItem = (key: string, value: any) => {
    if (!IS_SERVER) {
      localStorage.setItem(key, value);
    }
  };

  const removeItem = (key: string) => {
    if (!IS_SERVER) {
      localStorage.removeItem(key);
    }
  };

  const clear = () => {
    if (!IS_SERVER) {
      localStorage.clear();
    }
  };

  const getPersistRoot = () => {
    if (!IS_SERVER) {
      const persistRoot = localStorage.getItem(
        'persist:' + E_LOCAL_STORAGE._PERSIST_ROOT
      );

      try {
        return JSON.parse(persistRoot || '');
      } catch (error) {
        return null;
      }
    }
  };

  return {
    getItem,
    setItem,
    removeItem,
    clear,
    getPersistRoot,
  };
}
