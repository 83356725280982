import { IHeading } from '../locale';

const heading: IHeading = {
  login: 'ログイン',
  forgotPwd: 'パスワードを忘れた場合',
  confirmOTP: 'OTP確認',
  resetPwd: 'パスワード再設定',
  dashboard: 'ダッシュボード',
  adminAccount: 'アドミン管理',
  userAccount: 'ユーザー管理',
};

export default heading;
