import { IExchange } from '../locale';

const exchange: IExchange = {
  listExchange: '交換の一覧',
  gift: 'ギフト',
  description: '内容',
  type: 'タイプ',
  status: 'ステータス',
  statusType: {
    SUCCESS: '完了',
    PENDING: '確認中',
    REJECTED: 'キャンセル済み',
  },
  created: '作成',
  user: 'ユーザー',
  giftType: 'ギフトの種類',
  detail: {
    title: 'お支払の詳細',
    moveToInprogress: '進行中に移動する',
    markSuccess: '成功のマーク',
    giftInfo: 'ギフトの情報',
    fullName: 'フルネーム',
    confirm: {
      update: '交換ギフトのステータス更新',
      contentUpdate: 'ステータスを次のように更新してよろしいですか？',
    },
    toastr: {
      updateSuccessful: '交換ステータスの更新が成功しました',
    },
  },
};

export default exchange;
