import type { RangePickerProps } from 'antd/es/date-picker';
import dayjs from 'dayjs';

export const getDateFromDateString = (
  params: string,
  format?: string,
  includeTime?: boolean
) => {
  if (!params || params === '') {
    return '';
  }
  const date = new Date(params);

  if (format === 'dd-mm-yyyy') {
    return (
      str_pad(date.getDate()) +
      '-' +
      str_pad(date.getMonth() + 1) +
      '-' +
      date.getFullYear() +
      (includeTime
        ? ' ' + str_pad(date.getHours()) + ':' + str_pad(date.getMinutes())
        : '')
    );
  }

  if (format === 'yyyy-mm-dd') {
    return (
      date.getFullYear() +
      '-' +
      str_pad(date.getMonth() + 1) +
      '-' +
      str_pad(date.getDate()) +
      (includeTime
        ? ' ' + str_pad(date.getHours()) + ':' + str_pad(date.getMinutes())
        : '')
    );
  }

  if (format === 'mm-dd-yyyy') {
    return (
      str_pad(date.getMonth() + 1) +
      '-' +
      str_pad(date.getDate()) +
      '-' +
      date.getFullYear() +
      (includeTime
        ? ' ' + str_pad(date.getHours()) + ':' + str_pad(date.getMinutes())
        : '')
    );
  }

  return '';
};

export function str_pad(n: any) {
  return String('0' + n).slice(-2);
}

const range = (start: number, end: number) => {
  const result = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
};
// eslint-disable-next-line arrow-body-style
export const disabledDate: RangePickerProps['disabledDate'] = (current) => {
  // Can not select days before today
  return current && current < dayjs().startOf('day').subtract(0, 'day');
};

export const disabledRangeTime: RangePickerProps['disabledTime'] = (
  _,
  type
) => {
  const currentHour = dayjs().hour();
  const currentMinutes = dayjs().minute();

  if (type === 'start') {
    return {
      disabledHours: () => range(0, currentHour),
      disabledMinutes: () => range(0, currentMinutes + 10),
      disabledSeconds: () => [],
    };
  }

  return {
    disabledHours: () => [],
    disabledMinutes: () => [],
    disabledSeconds: () => [],
  };
};

export const validateEndDate = (endDate: any, startDate: any) => {
  if (!endDate || !startDate) {
    // If either date is not available, return true to skip validation
    return true;
  }

  // Compare the end date with the start date using dayjs
  return dayjs(endDate).isAfter(startDate);
};
