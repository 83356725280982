import { ISiteInformation } from '../locale';

const siteInformation: ISiteInformation = {
  about: 'About HILFE AI ',
  privacyPolicy: 'Privacy policy',
  termOfService: 'Terms of service',
  descriptionMaxlength: 'Description must not exceed 20000 characters',
  english: 'English',
  japanese: 'Japanese',
  updateAboutSuccessful: 'Update About pitchcasts successfully',
  updatePrivacySuccessful: 'Update Privacy policy successfully',
  updateTermSuccessful: 'Update Term of service successfully',
};
export default siteInformation;
