import { IMenu } from '../locale';

const menu: IMenu = {
  dashboard: 'ダッシュボード',
  userAccount: 'ユーザー管理',
  adminAccount: 'アドミン管理',
  payment: 'プラン管理',
  package: 'プラン設定',
  function: '決済履歴',
  voting: '投稿管理',
  posts: '投稿管理',
  pollList: '投稿一覧',
  pollConfig: '投稿・ポイント管理',
  banWords: '禁止ワード管理',
  pollCategory: 'カテゴリー管理',
  pollImage: 'デフォルト画像管理',
  advertisement: '広告管理',
  advertisementList: '広告リスト',
  advertisementRequest: '広告リクエスト',
  sales: '決済管理',
  site: '情報管理',
  announcements: 'お知らせ管理',
  inquiry: '問い合わせ管理',
  points: 'ポイント・ギフト管理',
  pointConfigure: 'ギフト設定',
  pointExchange: 'ギフト交換管理',
  report: 'レポート管理',
  feedback: 'フィードバック管理',
  dashboardHeading: 'ダッシュボード',
  userAccountHeading: 'ユーザー管理',
  adminAccountHeading: 'アドミン管理',
  postListHeading: '投稿管理',
  pollListHeading: '投稿管理',
  pollConfigHeading: 'ポイント数・投稿管理',
  pollCategoryHeading: 'カテゴリー管理',
  list: 'リスト',
  request: 'リクエスト',
};

export default menu;
