export const getLocalStorage = (key: string): string | object | null => {
  if (typeof window === 'undefined') {
    return null;
  }

  const item = localStorage.getItem(key);
  if (item) {
    try {
      return JSON.parse(item);
    } catch (e) {
      return item;
    }
  }
  return null;
};

export const setLocalStorage = (key: string, value: any): void => {
  if (typeof window === 'undefined') {
    return;
  }

  localStorage.setItem(key, JSON.stringify(value));
};

export const removeLocalStorage = (key: string): void => {
  if (typeof window === 'undefined') {
    return;
  }

  localStorage.removeItem(key);
};

export const clearLocalStorage = (): void => {
  if (typeof window === 'undefined') {
    return;
  }
  localStorage.clear();
};
