import { IExchange } from '../locale';

const exchange: IExchange = {
  listExchange: 'List Exchanges',
  gift: 'Gift',
  description: 'Description',
  type: 'Type',
  status: 'Status',
  statusType: {
    SUCCESS: 'Completed',
    PENDING: 'In Progress',
    REJECTED: 'Cancel',
  },
  created: 'Created',
  user: 'User',
  giftType: 'Gift Type',
  detail: {
    title: 'Detail Payment',
    moveToInprogress: 'Move to In Progress',
    markSuccess: 'Mark Success',
    giftInfo: 'Gift information',
    fullName: 'Full name',
    confirm: {
      update: 'Update exchange gift status',
      contentUpdate: 'Are you sure you want to update status to ',
    },
    toastr: {
      updateSuccessful: 'Update exchange status successfully',
    },
  },
};

export default exchange;
