import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { E_PATHS } from '@/helpers/constants/common.constant';
import { translate } from '@/helpers/functions/language.helper';
import { locale_keys } from '@/helpers/locales/locale.key';

import Language from './language';

const Header = () => {
  const location = useLocation();
  const [breadcrumb, setBreadcrumb] = useState<string>('');

  useEffect(() => {
    if (location.pathname === E_PATHS._DASHBOARD) {
      setBreadcrumb(translate(locale_keys.menu.dashboardHeading));
    } else if (location.pathname === E_PATHS._ADMIN_ACCOUNT) {
      setBreadcrumb(translate(locale_keys.menu.adminAccountHeading));
    } else if (location.pathname === E_PATHS._USER_ACCOUNT) {
      setBreadcrumb(translate(locale_keys.menu.userAccountHeading));
    } else if (location.pathname === E_PATHS._POSTS) {
      setBreadcrumb(translate(locale_keys.menu.postListHeading));
    } else if (location.pathname === E_PATHS._VOTINGL_LIST) {
      setBreadcrumb(
        `${translate(locale_keys.menu.voting)} / ${translate(
          locale_keys.menu.pollList
        )} `
      );
    } else if (location.pathname === E_PATHS._VOTING_CONFIG) {
      setBreadcrumb(
        `${translate(locale_keys.menu.voting)} / ${translate(
          locale_keys.menu.pollConfig
        )} `
      );
    } else if (location.pathname === E_PATHS._VOTING_BAN_WORD) {
      setBreadcrumb(
        `${translate(locale_keys.menu.voting)} / ${translate(
          locale_keys.menu.banWords
        )} `
      );
    } else if (location.pathname === E_PATHS._VOTING_CATEGORY) {
      setBreadcrumb(
        `${translate(locale_keys.menu.voting)} / ${translate(
          locale_keys.menu.pollCategory
        )} `
      );
    } else if (location.pathname === E_PATHS._VOTING_IMAGE) {
      setBreadcrumb(
        `${translate(locale_keys.menu.voting)} / ${translate(
          locale_keys.menu.pollImage
        )} `
      );
    } else if (location.pathname === E_PATHS._ADVERTISEMENT) {
      setBreadcrumb(translate(locale_keys.menu.advertisement));
    } else if (location.pathname === E_PATHS._ADVERTISEMENT_LIST) {
      setBreadcrumb(
        `${translate(locale_keys.menu.advertisement)} / ${translate(
          locale_keys.menu.list
        )} `
      );
    } else if (location.pathname === E_PATHS._ADVERTISEMENT_REQUEST) {
      setBreadcrumb(
        `${translate(locale_keys.menu.advertisement)} / ${translate(
          locale_keys.menu.request
        )} `
      );
    } else if (location.pathname === E_PATHS._ANNOUNCEMENT) {
      setBreadcrumb(translate(locale_keys.menu.announcements));
    } else if (location.pathname === E_PATHS._SITE) {
      setBreadcrumb(translate(locale_keys.menu.site));
    } else if (location.pathname === E_PATHS._SALES) {
      setBreadcrumb(translate(locale_keys.menu.sales));
    } else if (location.pathname === E_PATHS._POINT) {
      setBreadcrumb(translate(locale_keys.menu.points));
    } else if (location.pathname === E_PATHS._POINT_CONFIGURE) {
      setBreadcrumb(
        `${translate(locale_keys.menu.points)} / ${translate(
          locale_keys.menu.pointConfigure
        )} `
      );
    } else if (location.pathname === E_PATHS._POINT_EXCHANGE) {
      setBreadcrumb(
        `${translate(locale_keys.menu.points)} / ${translate(
          locale_keys.menu.pointExchange
        )} `
      );
    } else if (location.pathname === E_PATHS._INQUIRY) {
      setBreadcrumb(
        `${translate(locale_keys.menu.feedback)} / ${translate(
          locale_keys.menu.inquiry
        )} `
      );
    } else if (location.pathname === E_PATHS._REPORT) {
      setBreadcrumb(
        `${translate(locale_keys.menu.feedback)} / ${translate(
          locale_keys.menu.report
        )} `
      );
    } else if (location.pathname === E_PATHS._PAYMENT_PACKAGE) {
      setBreadcrumb(
        `${translate(locale_keys.menu.payment)} / ${translate(
          locale_keys.menu.package
        )} `
      );
    } else if (location.pathname === E_PATHS._PAYMENT_FUNCTION) {
      setBreadcrumb(
        `${translate(locale_keys.menu.payment)} / ${translate(
          locale_keys.menu.function
        )} `
      );
    }
  }, [location]);

  return (
    <div className='header d-flex justify-content-between align-items-center'>
      <div className='header__breadcrumb'>{breadcrumb}</div>
      <div className='header__language'>
        <Language></Language>
      </div>
    </div>
  );
};

export default Header;
