import type { MenuProps } from 'antd';
import { Dropdown } from 'antd';
import { useState } from 'react';
import { useNavigate } from 'react-router';

import ChangePasswordDrawer from '@/components/ChangePassword.drawer';

import { useAppDispatch } from '@/stores';
import { setAuth } from '@/stores/features/auth.slice';

import IconDefaultAvatar from '@/assets/images/png/icon-default-avatar.png';
import { E_PATHS } from '@/helpers/constants/common.constant';
import { translate } from '@/helpers/functions/language.helper';
import { locale_keys } from '@/helpers/locales/locale.key';

const MenuAccount = ({ currentUser }: { currentUser: any }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [resetDrawer, setResetDrawer] = useState<boolean>(false);
  const [openDetailDrawer, setOpenDetailDrawer] = useState(false);

  const logout = () => {
    dispatch(
      setAuth({
        access_token: '',
        refresh_token: '',
        user: '',
      })
    );
    localStorage.clear();
    sessionStorage.clear();

    setTimeout(() => {
      navigate(E_PATHS._LOGIN);
    }, 0);
  };

  const onOpenDetailDrawer = () => {
    setResetDrawer(!resetDrawer);
    setOpenDetailDrawer(true);
  };

  const handleOnCloseDetailDrawer = () => {
    setOpenDetailDrawer(false);
  };

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: <div onClick={onOpenDetailDrawer}>Change password</div>,
    },
    {
      key: '2',
      label: (
        <div onClick={logout}>{translate(locale_keys.auth.text.logout)}</div>
      ),
    },
  ];

  return (
    <>
      <Dropdown menu={{ items }} trigger={['click']} placement='topLeft'>
        <div className='menu__account d-flex align-items-center mt-auto'>
          <div className='d-flex'>
            <div className='menu__account-avatar'>
              <img src={IconDefaultAvatar} alt='' />
            </div>
            <div className='menu__account-infors'>
              <div>{currentUser?.username}</div>
              <div>{currentUser?.fullName}</div>
            </div>
          </div>
        </div>
      </Dropdown>
      <ChangePasswordDrawer
        resetDrawer={resetDrawer}
        open={openDetailDrawer}
        onCloseDetailDrawer={handleOnCloseDetailDrawer}
      ></ChangePasswordDrawer>
    </>
  );
};

export default MenuAccount;
